import React from 'react';
import {ModalCustom} from "../../../../common";
import {ChangeTableProps} from "./ChangeTable.props";
import style from "./ChangeTable.module.css";
import Tables from "../../Schemes/Tables/Tables";

const ChangeTableModal = ({restaurantData, onClose, opened, ...props}:ChangeTableProps) => {
    return (
        <ModalCustom
            opened={opened}
            onClose={onClose}
            size={586}
            title="Изменить стол"
            groupClassName={style.changeTableModalFooter}
            buttonTitle="Изменить"
            {...props}
        >
            <Tables onClose={onClose} restaurantData={restaurantData}/>
        </ModalCustom>
    );
};

export default ChangeTableModal;
