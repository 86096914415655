import React, {useState} from 'react';
import Logo from "../../assets/Logo/Logo";
import style from "./Header.module.css";
import {HeaderProps} from "./Header.props";
import {Logout, Ship, UserCircle} from "tabler-icons-react";
import {useNavigate} from "react-router-dom";
import {ButtonCustom} from "../../components";
import {Text} from "@mantine/core";

const HeaderContent = ({...props}: HeaderProps) => {
    const navigate = useNavigate();

    const [isOpen, setIsOpen] = useState<boolean>(false);

    const shipName = localStorage.getItem("shipName");
    const cruiseName = localStorage.getItem("cruiseName");

    const logout = () => {
        localStorage.clear();
        navigate(`/`);
        setIsOpen(false);
    };

    const changeCruises = () => {
        navigate(`/`);
        setIsOpen(false);
    };

    return (
        <header {...props}>
            <Logo width="152" height="24"/>
            <div className={style.shipName}>
                <Text>{cruiseName}</Text>
                <Text style={{lineHeight: 1, fontWeight: 200}}>({shipName})</Text>
            </div>
            <div className={style.headerUser}>
                <ButtonCustom appearance="ghost"
                              rightIcon={<UserCircle
                                  size={20}
                                  strokeWidth={2}
                                  color={'black'}
                              />}

                              onClick={() => setIsOpen(prev => !prev)}
                >
                    Администратор
                </ButtonCustom>
                {isOpen && (
                    <div className={style.dropdown}>
                        <ButtonCustom appearance="ghost"
                                      style={{justifyContent: "start"}}
                                      leftIcon={<Ship size={18}/>}
                                      onClick={changeCruises}
                        >
                            Сменить круиз
                        </ButtonCustom>
                        <ButtonCustom appearance="ghost"
                                      style={{justifyContent: "start"}}
                                      leftIcon={<Logout size={18}/>}
                                      onClick={logout}
                        >
                            Выйти
                        </ButtonCustom>
                    </div>
                )}
            </div>
        </header>
    );
};

export default HeaderContent;
