import { ITables } from "../../../../types/IRegistration";
import "../AddPassenger/AddPassenger.css"
import {clearNewRect} from "../../../../helpers/helpers";


export const getGuestRect = (table: ITables, guests: number, setActiveTable?: (active: string | null) => void, currentNumber?: number) => {

    const tableElement = document.getElementById(`Table_${table.number}`);
    const path = tableElement?.getElementsByTagName("rect")[0];
    const rect = tableElement?.getElementsByTagName("rect");
    const point = tableElement?.getElementsByClassName('Point')[0];

    let x = Number(point?.getAttribute("x"));
    const y = Number(point?.getAttribute("y"));

    if (path) {
        path.style.fill = table.isFull ? "#B6CBDE" : table.isCloseRegistration ? "rgba(5,32,57,0.4)" : "#052039"
    }

    if (rect) {
        if (table.numberOccupied !== 0) {
            rect[1].style.fill = "white"
        }

        if (table.numberOccupied === 0 && guests !== 0) {
            if(path){
                path.style.fill = "#00B9E7"
            }
            rect[1].style.fill = "#EE7605"
        }

        if (guests === 0 && table.numberOccupied === 0) {
            rect[1].style.fill = "none"
        }
    }

    if (tableElement) {

        tableElement.style.cursor = table.isFull ? "none" : "pointer"

        tableElement.onclick = () => {
            if (table.isFull) return
            if (setActiveTable) {
                setActiveTable(String(table.number));
            }

            const event = new CustomEvent('table', {
                detail: {
                    guid: table.guid || "",
                    number: table.number,
                    numberOccupied: table.numberOccupied || 0,
                    numberSeats: table.numberSeats || 8,
                },
            })

            document.dispatchEvent(event)

        };
    }

    const getFill = (index: number, rect: any) => {

        if (index < +table.numberOccupied) {
            rect.style.fill = "white"
        }

        if (currentNumber === table.number && (index + 1) === table.numberOccupied) {
            rect.style.fill = "#7fd9ff"
            rect.style.stroke = "#7fd9ff"
        }

        if (index >= +table.numberOccupied && index < Number(table.numberOccupied) + guests) {
            if(path){
                path.style.fill = "#00B9E7"
            }
            rect.style.fill = "#EE7605"
        }
    }

    const rects = document.getElementById(`Table_${table.number}`)?.getElementsByClassName("rectNew")
    if(rects){
        clearNewRect(rects, Number(table.numberSeats))
    }

    for (let i = 1; i < +table.numberSeats; i++) {
        const rect = document.createElementNS("http://www.w3.org/2000/svg", 'rect');
        if(x && y){
            rect.setAttribute("x", `${x ? x += 8 : 25}`);
            rect.setAttribute("y", `${y}`);
            rect.setAttribute("rx", "2.5");
            rect.classList.add("rectNew");
            getFill(i, rect)

            tableElement?.appendChild(rect);
        }
    }
};
