import React, {useEffect} from 'react';
import "./AddPassenger.css"
import parse from 'html-react-parser';
import {getGuestRect} from "../helpers/getGuestRect";
import {ITables} from "../../../../types/IRegistration";
import {clearNewRect, resetNotActivePaths} from "../../../../helpers/helpers";

export interface ISchemes{
    tables: ITables[];
    svg?: string | null;
    setActiveTable?: (activeTable: string | null) => void
    currentNumber?: number
    setTableNumber: (number: string | null) => void
}


const schemeRendering = ({
                             tables,
                             currentNumber,
                             setTableNumber
                         }: ISchemes) => {

    const seats = document.getElementsByClassName("Seats")
    resetNotActivePaths()

    const rects = document.getElementsByClassName("rectNew")
    clearNewRect(rects, rects.length)

    for (let i = 1; i < seats.length + 1; i++) {
        const tempTable = document.getElementById(`Table_${i}`)
        tempTable?.removeAttribute("style")

        const tempRect = tempTable?.getElementsByTagName("rect")[0]
        tempRect?.removeAttribute("style")
        tempRect?.removeAttribute("fill")

        const table = tables.find(t => t.number === i)

        if(table) {
            getGuestRect(table, 0 ,setTableNumber ,currentNumber)
        }

    }
};

export interface IAddPassengerSchemes {
    svg?: string | null;
    tables: any;
    currentNumber?: number;
    setTableNumber: (number: string | null) => void
}

const AddPassengerSchemes = ({ svg, tables, currentNumber, setTableNumber}:IAddPassengerSchemes ) => {

    useEffect(()=> {
        schemeRendering({tables: tables?.tables, currentNumber, setTableNumber});
    },[tables, svg]);

    return <>{svg ? parse(svg) : "Нет схемы"}</> ;
} ;



export default AddPassengerSchemes;