import {
  BaseQueryFn,
  createApi,
  FetchArgs,
  fetchBaseQuery,
  FetchBaseQueryError,
} from "@reduxjs/toolkit/query/react";
import {
  RequestData,
  RequestFilterType,
  RequestModel,
} from "../../types/IRequest";
import { BASE_API_URL } from "../../configs/api.config";

const baseQuery = fetchBaseQuery({
  baseUrl: BASE_API_URL,
  prepareHeaders: (headers) => {
    const token = localStorage.getItem("token");

    if (token) {
      headers.set("X-SOZVEZDIE-AUTH-TOKEN", `${token}`);
    }

    return headers;
  },
});

const baseQueryWithReauth: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  const result = await baseQuery(args, api, extraOptions);
  if (result.error && result.error.status === 401) {
    localStorage.clear();
    window.location.href = "/";
  }
  return result;
};

export const requestSlice = createApi({
  reducerPath: "request",
  baseQuery: baseQueryWithReauth,
  tagTypes: ["Request"],
  endpoints(builder) {
    return {
      fetchRequest: builder.query<
        RequestData,
        RequestModel<RequestFilterType> | string
      >({
        query: (body) => ({
          url: `/api/PassengerRequest/GetAllRequests`,
          method: "POST",
          body,
        }),
        providesTags: (result) =>
          result
            ? [
                ...result.models.map(
                  ({ guid }) => ({ type: "Request", guid } as const)
                ),
                { type: "Request", guid: "LIST" },
              ]
            : [{ type: "Request", guid: "LIST" }],
      }),
      getAllTypes: builder.query<{ id: string; title: string }[], null>({
        query: () => `/api/PassengerRequest/GetAllTypes`,
      }),

      getAllStatuses: builder.query<{ id: string; title: string }[], null>({
        query: () => ({
          url: `/api/PassengerRequest/GetAllStatuses`,
          method: "POST",
        }),
      }),
      getAvailableStatuses: builder.query({
        query: (body) => ({
          url: `api/PassengerRequest/GetAvailableStatuses`,
          method: "POST",
          body,
        }),
      }),
      getRequestById: builder.query({
        query: (guid) => `/api/PassengerRequest/GetRequestById/${guid}`,
        providesTags: ["Request"],
      }),
      declineRequest: builder.mutation<
        string,
        { requestId: string; reason: string }
      >({
        query: (body) => ({
          url: `/api/PassengerRequest/DeclineRequest`,
          method: "POST",
          body,
        }),
        invalidatesTags: ["Request"],
      }),

      updateRequestStatus: builder.mutation<
        string,
        { requestId: string; statusId: string | null }
      >({
        query: (body) => ({
          url: `/api/PassengerRequest/UpdateRequestStatus`,
          method: "POST",
          body,
        }),
        invalidatesTags: ["Request"],
      }),

      addComment: builder.mutation<
        string,
        { requestId: string; comment: string | null }
      >({
        query: (body) => ({
          url: `/api/PassengerRequest/AddCommentToRequest`,
          method: "POST",
          body,
        }),
        invalidatesTags: ["Request"],
      }),
      getNewRequestCount: builder.query<{ newRequestCount: number }, string>({
        query: (cruiseId) => ({
          url: `/api/PassengerRequest/GetNewRequestCount/${cruiseId}`,
          method: "POST",
        }),
      }),
      getUnreadMessageCount: builder.query<{ newMessageCount: number }, string>(
        {
          query: (cruiseId) => ({
            url: `/api/PassengerRequest/GetUnreadMessageCount/${cruiseId}`,
            method: "POST",
          }),
        }
      ),
    };
  },
});

export const {
  useFetchRequestQuery,
  useGetAllTypesQuery,
  useGetAllStatusesQuery,
  useDeclineRequestMutation,
  useUpdateRequestStatusMutation,
  useAddCommentMutation,
  useGetRequestByIdQuery,
  useGetAvailableStatusesQuery,
  useGetNewRequestCountQuery,
  useGetUnreadMessageCountQuery,
} = requestSlice;
