import React, { ChangeEvent, useEffect, useState } from "react";
import {
  ActionIcon,
  Autocomplete,
  Divider,
  Group,
  Image,
  Input,
  InputWrapper,
  ModalProps,
  Radio,
  RadioGroup,
  Select,
  Text,
  Textarea,
} from "@mantine/core";
import { ModalCustom } from "../../../../common";
import { ReactComponent as ArrowIcon } from "../../../../assets/svg/arrow.svg";
import { CalendarEvent, Trash } from "tabler-icons-react";
import { DatePicker } from "@mantine/dates";
import style from "./FlightScheduleModal.module.css";
import { ReactComponent as AddIcon } from "../../../../assets/svg/add.svg";
import { ButtonCustom } from "../../../../components";
import { formList, useForm } from "@mantine/form";
import dayjs from "dayjs";
import {
  useAddRouteMutation,
  useGetCitiesQuery,
  useUpdateRouteMutation,
} from "../../../../redux/apiSlice/_routScheduleSlice";
import { Excursion, ICityByID, RouteDyId } from "../../../../types/ISchedule";
import InputMask from "react-input-mask";
import { useGetAllExcursionsQuery } from "../../../../redux/apiSlice/_excursionSlice";

export interface IAddRouteModal extends ModalProps {
  data?: RouteDyId;
  excursionsData: Excursion[];
  title?: string;
  buttonTitle?: string;
  isEdit?: boolean;
}

const AddRouteModal = ({
  excursionsData,
  title = "Добавить маршрут",
  buttonTitle = "Добавить",
  data,
  opened,
  onClose,
  ...props
}: IAddRouteModal) => {
  const dateArray = data?.date?.split(".") || [];

  const exDate = `${dateArray[2]}.${dateArray[1]}.${dateArray[0]}`;

  const [size, setSize] = useState(
    excursionsData.length !== 0 ? excursionsData.length : 1
  );
  const [img, setImg] = useState("");
  const [date, setDate] = useState<Date | null>(
    data?.date ? new Date(exDate) : new Date()
  );
  const [arrivalTime, setArrivalTime] = useState<string>(
    data?.arrivalTime ? data.arrivalTime : ""
  );
  const [departureTime, setDepartureTime] = useState<string>(
    data?.departureTime ? data.departureTime : ""
  );

  const CRUISE_ID = localStorage.getItem("cruise");
  const [addRout] = useAddRouteMutation();
  const [updateRout] = useUpdateRouteMutation();
  const { data: city = [] } = useGetCitiesQuery(null);

  const cityData = city.map((city: ICityByID) => ({
    value: city.cityId,
    label: city.name,
    img: city.imageLink,
  })) || {
    value: "",
    label: "",
  };

  const excursions = {
    day: 0,
    name: "",
    timeTable: "",
    description: "",
    isIncluded: false,
    priceAdult: 0,
    priceChild: 0,
  };

  const list = excursionsData.length !== 0 ? excursionsData : [excursions];

  const form = useForm({
    initialValues: {
      cityId: Number(data?.city?.cityId || 0),
      cruiseId: Number(CRUISE_ID),
      excursions: formList([...list]),
    },
  });

  const { data: directoryExcursions = [], refetch } = useGetAllExcursionsQuery(
    form.getInputProps("cityId").value
  );

  const directoryExcursionsData = directoryExcursions.map((e: any) => ({
    value: String(e.name),
    label: e.name,
  }));

  const handleAddExcursions = () => {
    form.addListItem("excursions", excursions);
    setSize((prev) => (prev += 1));
  };

  const reset = () => {
    onClose();
    form.reset();
    setSize(1);
    setDate(new Date());
    setArrivalTime("");
    setDepartureTime("");
  };

  const handleAddRout = async (model: any) => {
    reset();
    if (data) {
      await updateRout({
        ...model,
        id: data?.id,
        dateDeparture: `${dayjs(date).format("YYYY-MM-DD")}T${departureTime}Z`,
        dateArrival: `${dayjs(date).format("YYYY-MM-DD")}T${arrivalTime}Z`,
        excursions: model.excursions.map((ex: any) => ({
          ...ex,
          isIncluded:
            typeof ex.isIncluded == "boolean"
              ? ex.isIncluded
              : ex.isIncluded === "включена в стоимость",
        })),
      });
    } else {
      await addRout({
        ...model,
        excursions: model.excursions.map((ex: any) => ({
          ...ex,
          isIncluded: ex.isIncluded === "включена в стоимость",
        })),
        dateDeparture: `${dayjs(date).format("YYYY-MM-DD")}T${departureTime}Z`,
        dateArrival: `${dayjs(date).format("YYYY-MM-DD")}T${arrivalTime}Z`,
      });
    }
  };

  useEffect(() => {
    setImg(city?.find((c: any) => c.cityId === form.values.cityId)?.imageLink);
  }, [form.values.cityId]);

  useEffect(() => {
    refetch();
  }, []);

  return (
    <ModalCustom
      overflow="inside"
      size={738}
      padding={24}
      opened={opened}
      onClose={reset}
      className={style.flightScheduleModal}
      title={title}
      {...props}
    >
      <form onSubmit={form.onSubmit(handleAddRout)}>
        <Group mb={20} direction="row">
          <Select
            className={style.flightScheduleModalSelectState}
            rightSection={<ArrowIcon />}
            styles={{ rightSection: { pointerEvents: "none" } }}
            searchable
            maxDropdownHeight={400}
            label="Город"
            placeholder={data ? data.city.name : "Выбрать город"}
            data={cityData}
            {...form.getInputProps("cityId")}
          />
        </Group>
        <Group direction="row">
          <DatePicker
            rightSection={
              <CalendarEvent size={24} strokeWidth={2} color={"#052039"} />
            }
            className={style.flightScheduleModalSelectDate}
            allowFreeInput
            inputFormat="DD.MM.YYYY"
            styles={{ rightSection: { pointerEvents: "none" } }}
            locale="ru"
            label="Дата"
            value={date}
            onChange={(e) => setDate(e)}
          />
          <InputWrapper
            className={style.flightScheduleModalSelectTime}
            label="Время прибытия"
          >
            <Input
              variant="filled"
              value={arrivalTime}
              placeholder="00:00"
              component={InputMask}
              mask="99:99"
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                setArrivalTime(e.currentTarget.value)
              }
            />
          </InputWrapper>
          <InputWrapper
            className={style.flightScheduleModalSelectTime}
            label="Время отправления"
          >
            <Input
              variant="filled"
              value={departureTime}
              placeholder="00:00"
              component={InputMask}
              mask="99:99"
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                setDepartureTime(e.currentTarget.value)
              }
            />
          </InputWrapper>
        </Group>
        {img && (
          <>
            <Text className={style.flightScheduleModalTitle}>Фото</Text>
            <div style={{ width: 200, height: 175 }}>
              <Image radius="md" height={175} src={img} />
            </div>
          </>
        )}
        {/*<UploadDropzone setImg={setRoutImg}/>*/}
        <Divider my="sm" style={{ marginTop: 32, marginBottom: 32 }} />
        <Group
          direction={"column"}
          spacing={0}
          className={style.flightScheduleModalExcursionWrap}
        >
          {[...new Array(size)].map((item, index) => (
            <Group className={style.flightScheduleModalExcursion} key={index}>
              <Group className={style.flightScheduleModalExcursionHeader}>
                <Text>Экскурсия</Text>
                <ActionIcon
                  onClick={() => {
                    form.removeListItem("excursions", index);
                    setSize((size) => (size -= 1));
                  }}
                >
                  <Trash size={24} strokeWidth={1} color="red" />
                </ActionIcon>
              </Group>
              <Group direction="row" noWrap spacing={24}>
                <InputWrapper
                  label="День"
                  className={style.flightScheduleModalExcursionDay}
                >
                  <Input
                    placeholder="0"
                    {...form.getListInputProps("excursions", index, "day")}
                  />
                </InputWrapper>
                <InputWrapper
                  label="Время экскурсии"
                  className={style.flightScheduleModalExcursionTime}
                >
                  <Input
                    placeholder="Введите время"
                    {...form.getListInputProps(
                      "excursions",
                      index,
                      "timeTable"
                    )}
                    component={InputMask}
                    mask="99:99 - 99:99"
                  />
                </InputWrapper>
              </Group>
              <Autocomplete
                label="Название экскурсии"
                className={style.flightScheduleModalExcursionTime}
                style={{ width: "100%" }}
                placeholder="Введите название"
                data={directoryExcursionsData}
                maxDropdownHeight={400}
                onItemSubmit={(item) => {
                  form.setListItem("excursions", index, {
                    description: directoryExcursions.find(
                      (e: any) => item.value == e.name
                    )!.description,
                    priceAdult: Number(
                      form.getListInputProps("excursions", index, "priceAdult")
                        .value
                    ),
                    priceChild: Number(
                      form.getListInputProps("excursions", index, "priceChild")
                        .value
                    ),
                    isIncluded: Boolean(
                      form.getListInputProps("excursions", index, "isIncluded")
                        .value
                    ),
                    timeTable: String(
                      form.getListInputProps("excursions", index, "timeTable")
                        .value
                    ),
                    day: Number(
                      form.getListInputProps("excursions", index, "day").value
                    ),
                    name: item.value,
                    id: directoryExcursions.find(
                      (e: any) => item.value == e.name
                    )!.excursionId,
                  });
                }}
                {...form.getListInputProps("excursions", index, "name")}
              />
              <Textarea
                maxRows={10}
                radius={"sm"}
                className={style.flightScheduleModalExcursionTextarea}
                {...form.getListInputProps("excursions", index, "description")}
                label="Описание экскурсии"
              />
              <RadioGroup
                {...form.getListInputProps("excursions", index, "isIncluded")}
                defaultValue={
                  form.getListInputProps("excursions", index, "isIncluded")
                    .value === "платно" ||
                  form.getListInputProps("excursions", index, "isIncluded")
                    .value === false
                    ? "платно"
                    : "включена в стоимость"
                }
              >
                <Radio
                  label="включена в стоимость"
                  value="включена в стоимость"
                />
                <Radio value="платно" label="платно" />
              </RadioGroup>
              {(form.getListInputProps("excursions", index, "isIncluded")
                .value === "платно" ||
                form.getListInputProps("excursions", index, "isIncluded")
                  .value === false) && (
                <Group
                  direction="row"
                  noWrap
                  spacing={24}
                  style={{ width: "100%" }}
                >
                  <InputWrapper label="Для взрослых" style={{ width: "40%" }}>
                    <Input
                      placeholder="0"
                      {...form.getListInputProps(
                        "excursions",
                        index,
                        "priceAdult"
                      )}
                    />
                  </InputWrapper>
                  <InputWrapper label="Для детей" style={{ width: "40%" }}>
                    <Input
                      placeholder="0"
                      {...form.getListInputProps(
                        "excursions",
                        index,
                        "priceChild"
                      )}
                    />
                  </InputWrapper>
                </Group>
              )}
            </Group>
          ))}
        </Group>
        <Group
          direction={"row"}
          position={"right"}
          className={style.flightScheduleModalBtnAddWrap}
        >
          <ButtonCustom
            width="lg"
            appearance="ghost"
            leftIcon={<AddIcon fill={"var(--darkBlue)"} />}
            onClick={handleAddExcursions}
          >
            Добавить экскурсию
          </ButtonCustom>
        </Group>
        <Group
          className={style.flightScheduleModalFooter}
          noWrap
          direction="row"
          position="right"
          style={{ bottom: 0 }}
        >
          <ButtonCustom width={"sm"} appearance="ghost" onClick={reset}>
            Отмена
          </ButtonCustom>
          <ButtonCustom
            width={"md"}
            appearance="secondary"
            type="submit"
            disabled={
              !form.getInputProps("cityId").value ||
              !date ||
              !arrivalTime ||
              !departureTime
            }
          >
            {buttonTitle}
          </ButtonCustom>
        </Group>
      </form>
    </ModalCustom>
  );
};

export default AddRouteModal;
