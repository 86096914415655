import React, {ReactNode} from 'react';
import style from './Stubs.module.css';
import { Badge, Loader } from "@mantine/core";
import {AlertOctagon} from "tabler-icons-react";

export interface IListStub  {
    children?: ReactNode,
    isLoading: boolean,
    isError?: boolean,
}
export const ListStub = ({isError = false, isLoading, children}:IListStub) =>  (
        <div className={style.listStub}>
            {isLoading
                ? <Loader variant="dots"/>
                : isError
                    ? <Badge color="red" p="xl" leftSection={<AlertOctagon/>}>
                        Ошибка
                    </Badge>
                    :<Badge color="gray" p="xl">
                    {children}
                </Badge>
            }
        </div>
    );