import React, {useState} from 'react';
import {RegistrationProps} from "./_Registration.props";
import {ReactComponent as AddIcon} from '../../assets/svg/add.svg';
import style from "./_Registration.module.css";
import {ActionIcon, Group, Tabs, Text} from "@mantine/core";
import RegistrationList from "./RegistrationList/RegistrationList";
import {ButtonCustom} from "../../components";
import AddTablesModal from "./RegistrationModal/AddTablesModal/AddTablesModal";
import {ReactComponent as ArrowIcon} from "../../assets/svg/arrow.svg";
import {ReactComponent as FilterIcon} from "../../assets/svg/filterIcon.svg";
import {ReactComponent as RefreshIcon} from "../../assets/svg/refreshIcon.svg";
import RegistrationFilters from "./RegistrationFilters/RegistrationFilters";
import {RegistrationFilterType} from "../../types/IRegistration";
import {
    useFetchRegistrationQuery,
    useGetRegistrationRestaurantQuery,
} from "../../redux/apiSlice/_registrationSlice";
import {RequestModel} from "../../types/IRequest";
import ChangeTableModal from "./RegistrationModal/ChangeTable/ChangeTableModal";
import AddGuestModal from "./RegistrationModal/AddGuestModal/AddGuestModal";
import {CSVLink} from "react-csv";

export const Registration = ({...props}: RegistrationProps): JSX.Element => {

    const [activeTab, setActiveTab] = useState<number>(0);
    const [activePage, setPage] = useState<number>(1);
    const [pageSize, setSetPageSize] = useState<number>(10);
    const [openModal, setOpenModal] = useState<boolean>(false);
    const [openGuestModal, setOpenGuestModal] = useState<boolean>(false);
    const [openChangeModal, setOpenChangeModal] = useState<boolean>(false);
    const [openFilters, setOpenFilters] = useState<boolean>(false);

    const CRUISE_ID = localStorage.getItem("cruise");

    const [filters, setFilters] = useState<RegistrationFilterType>({
        cruiseExternalId: CRUISE_ID,

    });

    const model: RequestModel<RegistrationFilterType> = {
        page: activePage,
        size: pageSize,
        filter: {
            ...filters,
            isCloseRegistration: activeTab !== 0
        }
    };

    const SHIP_ID = Number(localStorage.getItem("ship"));

    const {
        data: restaurantData = [],
        isLoading: restaurantIsLoading,
        isError: isRestaurantError
    } = useGetRegistrationRestaurantQuery(SHIP_ID);

    const restaurantWithoutBar = restaurantData.filter(m => !m.isBar)


    const {data = {models: [], total: 0}, isLoading, isError, refetch} = useFetchRegistrationQuery(model);

    const csvHeader = [
        {label: "Ресторан", key: "restaurant.name"},
        {label: "ФИО", key: "passengerFullName"},
        {label: "Номер каюты", key: "stateroom"},
        {label: "Номер столика", key: "tableNumber"},
        {label: "Кол-во человек", key: "numberPersons"},
    ];

    const crvReport = {
        filename: "Регистрация.csv",
        headers: csvHeader,
        data: data.models
    };

    return (
        <div {...props}>
            {openModal && (
                <AddTablesModal opened={openModal}
                                restaurantData={restaurantWithoutBar}
                                onClose={() => setOpenModal(false)}
                />
            )}
            {openGuestModal && (
                <AddGuestModal opened={openGuestModal}
                               onClose={() => setOpenGuestModal(false)}
                               restaurantData={restaurantWithoutBar}
                />
            )}
            {openChangeModal && (
                <ChangeTableModal opened={openChangeModal}
                                  restaurantData={restaurantWithoutBar}
                                  onClose={() => setOpenChangeModal(false)}
                />
            )}

            <RegistrationFilters setFilters={setFilters}
                                 setPage={setPage}
                                 opened={openFilters}
                                 onClose={() => setOpenFilters(false)}
                                 restaurant={restaurantData}
            />
            <header className={style.header}>
                <Group>
                    <Text weight={500} size="xl" className={style.headerTitle}>
                        Онлайн-регистрация в ресторане
                    </Text>
                    <ActionIcon size={25} onClick={() => refetch()}>
                        <RefreshIcon/>
                    </ActionIcon>
                </Group>
                <Group
                    className={style.registrationBtnWrap}
                    noWrap direction="row" position="right">
                    <Group noWrap direction="row" position="right">
                        <CSVLink {...crvReport} separator={";"}>
                            <ButtonCustom width="sm"
                                          rightIcon={<ArrowIcon/>}
                                          appearance="ghost"
                            >
                                Экспорт
                            </ButtonCustom>
                        </CSVLink>
                        <ButtonCustom width="sm"
                                      leftIcon={<FilterIcon/>}
                                      appearance="ghost"
                                      onClick={() => setOpenFilters(prev => !prev)}
                        >
                            Фильтр
                        </ButtonCustom>
                    </Group>
                    <ButtonCustom appearance="secondary"
                                  width="lg"
                                  onClick={() => setOpenChangeModal(true)}
                    >
                        Управление столами
                    </ButtonCustom>
                </Group>
            </header>
            <Tabs active={activeTab} onTabChange={setActiveTab}
                  styles={(theme) => ({
                      root: {
                          backgroundColor: theme.white,
                      },
                  })}
            >
                <Tabs.Tab label="Открытая регистрация" tabKey="open">
                    <RegistrationList restaurantData={restaurantData}
                                      setPage={setPage}
                                      activePage={activePage}
                                      pageSize={pageSize}
                                      isLoading={isLoading}
                                      data={data}
                    />
                </Tabs.Tab>
                <Tabs.Tab label="Закрытая регистрация" tabKey="close">
                    <RegistrationList restaurantData={restaurantData}
                                      setPage={setPage}
                                      activePage={activePage}
                                      pageSize={pageSize}
                                      isLoading={isLoading}
                                      data={data}
                    />
                </Tabs.Tab>
            </Tabs>
            <Group style={{width: "100%", margin: "10px 0"}} position="right">
                <ButtonCustom width="lg"
                              appearance="secondary"
                              leftIcon={<AddIcon fill="white"/>}
                              onClick={() => setOpenGuestModal(true)}
                >
                    Добавить гостя
                </ButtonCustom>
            </Group>
        </div>
    );
};
