import React, {useState} from 'react';
import {FlightScheduleProps} from "./FlightSchedule.props";
import style from "./FlightSchedule.module.css";
import {Table, Group} from "@mantine/core";
import {ButtonCustom} from "../../../components";
import {ReactComponent as AddIcon} from "../../../assets/svg/add.svg";
import AddRouteModal from "../RestaurantModals/FlightScheduleModals/AddRouteModal";
import {useGetRouteByCruiseIdQuery} from "../../../redux/apiSlice/_routScheduleSlice";
import {ListStub} from "../../../common";
import CityList from "./CityList";
import {City, RouteDay} from "../../../types/ISchedule";

const FlightSchedule = ({...props}: FlightScheduleProps) => {
    const [openedAddRoute, setOpenedAddRoute] = useState<boolean>(false);
    const CRUISE_ID = localStorage.getItem("cruise")
    const {data, isLoading} = useGetRouteByCruiseIdQuery(CRUISE_ID)

    return (
        <div {...props} className={style.restaurantsListWrapFlightSchedule}>
            {openedAddRoute && (
                <AddRouteModal opened={openedAddRoute}
                               excursionsData={data?.city?.excursions || []}
                               onClose={() => setOpenedAddRoute(false)}
                />
            )}
            <div className={style.flightSchedule}>
                {!data?.routeDays.length || isLoading
                    ? <ListStub isLoading={isLoading}>Нет рейсов </ListStub>
                    : <Table horizontalSpacing="md" verticalSpacing="md" className={style.applicationList}>
                        <thead className={style.flightScheduleTHead}>
                        <tr className={style.flightScheduleTHeadRow}>
                            <th>Дни</th>
                            <th>Город</th>
                            <th>Дата прибытия</th>
                            <th>Время стоянки</th>
                            <th/>
                        </tr>
                        </thead>
                        <tbody className={style.flightScheduleTbody}>
                        {data?.routeDays.map((route:RouteDay) => (
                            route?.cities.map((city: City, indexCities: number) => (
                                <CityList route={route}
                                          city={city}
                                          indexCities={indexCities}
                                          key={city.cityGuid}
                                />
                            ))))}
                        </tbody>
                    </Table>}
                <Group
                    className={style.flightScheduleBtnWrap}
                    position="right">
                    <ButtonCustom width="lg"
                                  appearance="secondary"
                                  onClick={() => setOpenedAddRoute(true)}
                                  leftIcon={<AddIcon fill={"var(--white)"}/>}
                    >
                        Добавить в маршрут
                    </ButtonCustom>
                </Group>
            </div>
        </div>
    );
};

export default FlightSchedule;
