import React, { useEffect, useState } from "react";
import { ModalCustom } from "../../../../common";
import { AddSaunaProps } from "./AddSauna.props";
import {
  Group,
  Input,
  InputWrapper,
  LoadingOverlay,
  Select,
} from "@mantine/core";
import style from "./AddSauna.module.css";
import {
  useGetPassengerFullNamesQuery,
  useGetPassengerReservationsQuery,
} from "../../../../redux/apiSlice/_registrationSlice";
import { REQUEST_DATE_TEMPLATE } from "../../../../constants/CONSTANT";
import { ReactComponent as ArrowIcon } from "../../../../assets/svg/arrow.svg";
import { useAddSaunaOrdersMutation } from "../../../../redux/apiSlice/_saunaSlice";
import dayjs from "dayjs";
import { ButtonCustom } from "../../../../components";

const AddSauna = ({
  clearRanges,
  setDate,
  date,
  ranges,
  onClose,
  refetchPeriods,
  ...props
}: AddSaunaProps) => {
  const [query, setQuery] = useState("");
  const [filterQuery, setFilterQuery] = useState<string | null>(null);

  const [fullName, setFullName] = useState<string | null>("");
  const [passengerNumber, setPassengerNumber] = useState<string>("");

  const CRUISE_ID = localStorage.getItem("cruise");

  const { data: passengerFullNames = [] } = useGetPassengerFullNamesQuery({
    cruiseId: Number(CRUISE_ID),
    searchPattern: filterQuery,
  });

  const { data: cabinNumber = [] } = useGetPassengerReservationsQuery({
    cruiseId: CRUISE_ID,
    fullName,
  });

  const passengerData = passengerFullNames.map((m: any) => m.fullName);

  const filteredPassengerData = passengerData.filter(
    (d: any, index: number) => {
      for (let i = 0; i < index; i++) {
        if (passengerData[i] == d) return false;
      }
      return true;
    }
  );

  const [addSaunaOrder, { isLoading }] = useAddSaunaOrdersMutation();

  const handleAddSaunaOrder = async () => {
    await addSaunaOrder({
      cruiseId: CRUISE_ID,
      date: dayjs(date).format(REQUEST_DATE_TEMPLATE),
      periods: ranges,
      passengerNumber,
      fullName,
      cabinNumber: cabinNumber[0]?.cabinNumber,
    });
    setDate(new Date());
    refetchPeriods();
    clearRanges();
    onClose();
  };

  useEffect(() => {
    setFilterQuery(query);
  }, [query]);

  return (
    <ModalCustom
      onClose={onClose}
      className={style.saunaEntryModal}
      size={492}
      overflow="inside"
      radius="md"
      title="Добавить запись"
      {...props}
    >
      <Group
        direction="column"
        spacing="md"
        grow
        noWrap
        style={{ position: "relative" }}
      >
        <LoadingOverlay visible={isLoading} />
        <Select
          rightSection={<ArrowIcon />}
          styles={{
            rightSection: { pointerEvents: "none" },
          }}
          value={fullName}
          onChange={(e) => setFullName(e)}
          searchable
          onSearchChange={setQuery}
          onKeyDown={(e) => {
            if (e.key == "Enter") {
              setFilterQuery(query);
            }
          }}
          onDropdownOpen={() => {
            if (!fullName && filterQuery) {
              setFilterQuery(null);
            }
          }}
          placeholder="ФИО"
          variant="filled"
          label="Введите ФИО пассажира"
          data={filterQuery ? filteredPassengerData : []}
        />
        <InputWrapper
          label="Номер каюты"
          className={style.saunaEntryModalInput}
        >
          <Input
            variant="filled"
            placeholder="_ _ _ _"
            value={cabinNumber[0]?.cabinNumber}
            disabled
          />
        </InputWrapper>
        <InputWrapper
          label="Кол-во человек"
          className={style.saunaEntryModalInput}
        >
          <Input
            variant="filled"
            placeholder="0"
            value={passengerNumber}
            onChange={(e: any) => setPassengerNumber(e.target.value)}
          />
        </InputWrapper>
      </Group>
      <Group
        className={style.saunaEntryModalFooter}
        noWrap
        direction="row"
        position="right"
        style={{ bottom: 0 }}
      >
        <ButtonCustom width={"sm"} appearance="ghost" onClick={onClose}>
          Отмена
        </ButtonCustom>
        <ButtonCustom
          width={"md"}
          appearance="secondary"
          onClick={handleAddSaunaOrder}
          disabled={!fullName || !passengerNumber}
        >
          Добавить
        </ButtonCustom>
      </Group>
    </ModalCustom>
  );
};

export default AddSauna;
