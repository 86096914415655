import React from 'react';
import ReactDOM from 'react-dom/client';
import {BrowserRouter as Router} from "react-router-dom";
import './index.css';
import App from './App';
import {MantineProvider} from '@mantine/core';
import {Provider} from "react-redux";
import {store} from "./redux/store";
import {NotificationsProvider} from "@mantine/notifications";


const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);

root.render(
    <Provider store={store}>
        <Router>
            <MantineProvider
                theme={{
                    fontFamily: 'Open Sans, sans-serif',
                    headings: {fontFamily: 'Open Sans, sans-serif'},
                }}
            >
                <NotificationsProvider>
                    <App/>
                </NotificationsProvider>
            </MantineProvider>
        </Router>
    </Provider>
);
