import { Navbar, Text } from "@mantine/core";
import { Link } from "react-router-dom";
import React, { useEffect } from "react";
import { ILinks } from "../../types/IRoute";
import style from "./Navbar.module.css";
import { NavbarProps } from "./Navbar.props";
import {
  useGetNewRequestCountQuery,
  useGetUnreadMessageCountQuery,
} from "../../redux/apiSlice/_requestSlice";
import { useGetNewExcursionCountQuery } from "../../redux/apiSlice/_excursionSlice";
import {useGetNewSaunaCountQuery} from "../../redux/apiSlice/_saunaSlice";

const NavbarContent = ({ links, ...props }: NavbarProps): JSX.Element => {
  const CRUISE_ID = localStorage.getItem("cruise");

  const {
    data: newRequestCount = { newRequestCount: 0 },
    refetch: requestFefetch,
  } = useGetNewRequestCountQuery(CRUISE_ID ? CRUISE_ID : "");

  const {
    data: newMessageCount = { newMessageCount: 0 },
    refetch: messageRefetch,
  } = useGetUnreadMessageCountQuery(CRUISE_ID ? CRUISE_ID : "");

  const {
    data: newSaunaCount = { newRequestCount: 0 },
    refetch: saunaRefetch,
  } = useGetNewSaunaCountQuery(CRUISE_ID ? CRUISE_ID : "");

  const {
    data: newExcursionCount = { newRequestCount: 0 },
    refetch: excursionRefetch,
  } = useGetNewExcursionCountQuery(CRUISE_ID ? CRUISE_ID : "");

  const currentLinks = links.map((l: ILinks) => {
    switch (l.title) {
      case 'Заявки':
        return {
          ...l,
          notification: newRequestCount.newRequestCount
            ? newRequestCount.newRequestCount
            : 0,
        };
      case 'Запись в сауну':
        return {
          ...l,
          notification: newSaunaCount.newRequestCount
              ? newSaunaCount.newRequestCount
              : 0,
        };
      case 'Чат':
        return {
          ...l,
          notification: newMessageCount.newMessageCount
            ? newMessageCount.newMessageCount
            : 0,
        };
        case 'Экскурсии':
          return {
            ...l,
            notification: newExcursionCount.newRequestCount
              ? newExcursionCount.newRequestCount
              : 0,
          };
        default: 
        return l;
      }
    }
  );

  useEffect(() => {
    const interval = setInterval(() => {
      requestFefetch();
      saunaRefetch();
      excursionRefetch();
      messageRefetch();
    }, 60000);
    return () => clearInterval(interval);
  }, []);

  return (
    <nav {...props}>
      <Navbar.Section className={style.navbarContent}>
        {currentLinks.map((route: ILinks) => (
          <Text
            component={Link}
            to={route.path}
            className={style.navbarItem}
            key={route.path}
          >
            {route.icon}
            <div className={style.navbarItemText}>{route.title}</div>
            {!!route.notification && (
              <span className={style.navbarItemNotification}>
                {route.notification}
              </span>
            )}
          </Text>
        ))}
      </Navbar.Section>
    </nav>
  );
};

export default NavbarContent;
