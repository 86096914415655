import {configureStore} from "@reduxjs/toolkit";
import {requestSlice} from "./apiSlice/_requestSlice";
import {registrationSlice} from "./apiSlice/_registrationSlice";
import registrationReducer from './reducers/registrationSlice';
import {restaurantSlice} from "./apiSlice/_restaurantSlice";
import {routScheduleSlice} from "./apiSlice/_routScheduleSlice";
import {dayScheduleSlice} from "./apiSlice/_dayScheduleSlice";
import {notificationsSlice} from "./apiSlice/_notificationsSlice";
import {saunaSlice} from "./apiSlice/_saunaSlice";
import {authSlice} from "./apiSlice/_authSlice";
import {chatSlice} from "./apiSlice/_chatSlice";
import {reviewSlice} from "./apiSlice/_reviewSlice";
import {excursionSlice} from "./apiSlice/_excursionSlice";

export const store = configureStore({
    reducer: {
        [requestSlice.reducerPath]: requestSlice.reducer,
        [registrationSlice.reducerPath]: registrationSlice.reducer,
        [restaurantSlice.reducerPath]: restaurantSlice.reducer,
        [routScheduleSlice.reducerPath]: routScheduleSlice.reducer,
        [dayScheduleSlice.reducerPath]: dayScheduleSlice.reducer,
        [notificationsSlice.reducerPath]: notificationsSlice.reducer,
        [authSlice.reducerPath]: authSlice.reducer,
        [saunaSlice.reducerPath]: saunaSlice.reducer,
        [chatSlice.reducerPath]: chatSlice.reducer,
        [reviewSlice.reducerPath]: reviewSlice.reducer,
        [excursionSlice.reducerPath]: excursionSlice.reducer,
        registrationPage: registrationReducer,
    },
    middleware: (getDefaultMiddleware) => {
        return getDefaultMiddleware()
            .concat(
                requestSlice.middleware,
                restaurantSlice.middleware,
                routScheduleSlice.middleware,
                dayScheduleSlice.middleware,
                notificationsSlice.middleware,
                authSlice.middleware,
                saunaSlice.middleware,
                registrationSlice.middleware,
                chatSlice.middleware,
                reviewSlice.middleware,
                excursionSlice.middleware,
            );
    }
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
