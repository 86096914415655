import React, { useEffect, useState } from "react";
import { IAddGuestModalProps } from "./AddGuestModal.props";
import { ModalCustom } from "../../../../common";
import { Group, Input, InputWrapper, Loader, Select } from "@mantine/core";
import { ReactComponent as ArrowIcon } from "../../../../assets/svg/arrow.svg";
import style from "./AddGuestModal.module.css";
import {
  useAddPassengerMutation,
  useGetPassengerFullNamesQuery,
  useGetPassengerReservationsQuery,
  useGetSeatSchemaQuery,
} from "../../../../redux/apiSlice/_registrationSlice";
import { ITables } from "../../../../types/IRegistration";
import { ButtonCustom } from "../../../../components";
import AddPassengerSchemes from "../../Schemes/AddPassenger/AddPassengerSchemes";
import { getGuestRect } from "../../Schemes/helpers/getGuestRect";
import { showNotification } from "@mantine/notifications";
import { CircleCheck, FaceIdError } from "tabler-icons-react";

const AddGuestModal = ({
  restaurantData,
  opened,
  onClose,
  ...props
}: IAddGuestModalProps) => {
  const [query, setQuery] = useState("");
  const [filterQuery, setFilterQuery] = useState<string | null>(null);

  const restaraunts = restaurantData.map((r: any) => ({
    value: String(r.id),
    label: r.name,
  }));
  const [restaurantGuid, setRestaurantGuid] = useState<string | null>("");

  const [tableNumberData, setTableNumbetData] = useState<string[]>([]);
  const [fio, setFio] = useState<string | null>("");
  const CRUISE_ID = localStorage.getItem("cruise");
  const [tableNumber, setTableNumber] = useState<string | null>("");
  const [prevTableNumber, setPrevTableNumber] = useState<string | null>("");

  const { data, isLoading } = useGetSeatSchemaQuery({
    restaurantGuid,
    cruiseId: CRUISE_ID,
  });

  const { data: passengerFullNames = [] } = useGetPassengerFullNamesQuery({
    cruiseId: Number(CRUISE_ID),
    searchPattern: filterQuery,
  });

  const passengerData = passengerFullNames.map((p: any) => ({
    value: String(p.passengerId),
    label: p.fullName,
  }));

  const { data: cabinNumber = [] } = useGetPassengerReservationsQuery({
    cruiseId: CRUISE_ID,
    fullName: String(passengerData?.find((n: any) => n.value === fio)?.label),
  });

  const [addPassenger, { isLoading: isAddPassenger, isError, error }] =
    useAddPassengerMutation();

  const handleSetRestaurantGuid = (value: string | null) => {
    setRestaurantGuid(value);
    const tables = restaurantData.filter((r: any) => String(r.id) === value);
    setTableNumbetData(tables[0]?.tableNumbers.map((t: number) => String(t)).sort((a:string,b:string)=> parseInt(a) - parseInt(b)));
  };

  const handleAddPassenger = async () => {
    await addPassenger({
      restaurantGuid: restaurantGuid,
      tableNumber: Number(tableNumber),
      passengerId: Number(fio),
    });
  };

  useEffect(() => {
    setPrevTableNumber(tableNumber);
    const table = data?.tables.find(
      (el: ITables) => el.number === Number(tableNumber)
    );
    if (table) {
      getGuestRect(table, 1, setTableNumber);
    }
  }, [tableNumber]);

  useEffect(() => {
    const table = data?.tables.find(
      (el: ITables) => el.number === Number(prevTableNumber)
    );
    if (table) {
      getGuestRect(table, 0, setTableNumber);
    }
  }, [tableNumber]);

  useEffect(() => {
    setFilterQuery(query);
  }, [query]);

  useEffect(() => {
    if (!isAddPassenger && restaurantGuid) {
      const errorText = "data" in error! ? error.data : "";
      const errorStatus = "status" in error! ? error.status : "";
      if (errorStatus == 500) {
        showNotification({
          title: "Ошибка",
          message:
            "Данный пассажир уже зарегистриван в ресторане. Воспользуйтесь фильтром по ФИО в списке регистраций",
          color: "red",
          icon: <FaceIdError />,
          autoClose: 10000,
        });
        return;
      }
      isError && errorText != "Забронировано"
        ? showNotification({
            title: "Ошибка",
            message: "Произошла ошибка при добавлении",
            color: "red",
            icon: <FaceIdError />,
          })
        : showNotification({
            title: "",
            color: "green",
            message: "Гость добавлен",
            icon: <CircleCheck />,
          });

      onClose();
    }
  }, [isAddPassenger]);

  return (
    <ModalCustom
      opened={opened}
      onClose={onClose}
      size={586}
      title="Добавить гостя"
      {...props}
    >
      <Group spacing="sm" direction="column" mb="xl">
        <Select
          rightSection={<ArrowIcon />}
          styles={{
            rightSection: { pointerEvents: "none" },
            root: { width: "100%" },
          }}
          value={restaurantGuid}
          onChange={handleSetRestaurantGuid}
          placeholder="Выберите ресторан"
          variant="filled"
          label="Ресторан"
          data={restaraunts}
        />
        <Group
          spacing="sm"
          direction="row"
          mb="md"
          mt="md"
          style={{ width: "100%" }}
        >
          <Select
            rightSection={<ArrowIcon />}
            styles={{
              rightSection: { pointerEvents: "none" },
              root: { width: "45%" },
            }}
            value={fio}
            onChange={(e) => setFio(e)}
            searchable
            onSearchChange={setQuery}
            onKeyDown={(e) => {
              if (e.key == "Enter") {
                setFilterQuery(query);
              }
            }}
            onDropdownOpen={() => {
              if (!fio && filterQuery) {
                setFilterQuery(null);
              }
            }}
            placeholder="Выберите ФИО пассажира"
            variant="filled"
            label="ФИО"
            data={filterQuery ? passengerData : []}
          />

          <InputWrapper label="Номер каюты" style={{ width: "37%" }}>
            <Input
              placeholder="_ _ _ _"
              variant="filled"
              disabled
              value={cabinNumber[0]?.cabinNumber}
            />
          </InputWrapper>
        </Group>
        <Select
          rightSection={<ArrowIcon />}
          styles={{
            rightSection: { pointerEvents: "none" },
            root: { width: "40%" },
          }}
          value={tableNumber}
          placeholder="№"
          variant="filled"
          label="Номер стола"
          onChange={(e) => setTableNumber(e)}
          data={tableNumberData}
        />
      </Group>
      <div className={style.scheme}>
        {isLoading ? (
          <Loader size="xl" variant="dots" />
        ) : (
          <AddPassengerSchemes
            tables={data}
            svg={data?.schemaSvg}
            setTableNumber={setTableNumber}
          />
        )}
      </div>
      <Group noWrap direction="row" position="right" style={{ marginTop: 20 }}>
        <ButtonCustom width={"sm"} appearance="ghost" onClick={onClose}>
          Отмена
        </ButtonCustom>
        <ButtonCustom
          width={"md"}
          appearance="secondary"
          onClick={handleAddPassenger}
          disabled={!fio || !restaurantGuid || !tableNumber}
        >
          Добавить
        </ButtonCustom>
      </Group>
    </ModalCustom>
  );
};

export default AddGuestModal;
