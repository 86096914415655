import React, { ChangeEvent, useState } from "react";
import { ModalCustom } from "../../../../common";
import { Autocomplete, Group, Input, InputWrapper } from "@mantine/core";
import { EditToMenuProps } from "./EditToMenu.props";
import style from "./EditToMenu.module.css";
import {
  useGetAllDishesQuery,
  useUpdateDishMutation,
} from "../../../../redux/apiSlice/_restaurantSlice";
import { ButtonCustom } from "../../../../components";

const EditToMenu = ({ dishes, opened, onClose, ...props }: EditToMenuProps) => {
  const [name, setName] = useState<string | undefined>(dishes?.name);

  const [updateDishes, { isLoading }] = useUpdateDishMutation();

  // const {data: allDishes = []} = useGetAllDishesQuery(null);
  // const filteredDishes = allDishes.filter((d: {name: string, id: string}) => d.name);
  // const catalogDishes = filteredDishes.map( (e: any) => ({value: String(e.name), label: e.name}));

  const handleUpdateDishes = async () => {
    onClose();
    await updateDishes({
      name,
      dishId: dishes?.id,
    }).unwrap();
  };

  const reset = () => {
    onClose();
    setName(dishes?.name);
  };

  return (
    <ModalCustom
      className={style.editMenuBarModal}
      opened={opened}
      onClose={reset}
      size={600}
      title="Редактировать"
      {...props}
    >
      <Group
        spacing="md"
        direction="column"
        className={style.editMenuBarModalGroup}
      >
        <Group direction="row" style={{ width: "100%" }}>
          {/* <Autocomplete
                            label="Описание"
                            className={style.addMenuBarModalName}
                            data={catalogDishes}
                            variant="filled"
                            placeholder="Салат «Цезарь»"
                            style={{width: "100%"}}
                            limit={1000}
                            value={name}
                            onChange={(value: string) => setName(value)}
                            maxDropdownHeight={200}
                        /> */}
          <InputWrapper
            className={style.addMenuBarModalName}
            style={{ width: "100%" }}
            label="Описание"
          >
            <Input
              variant="filled"
              placeholder="Салат «Цезарь"
              value={name}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                setName(e.currentTarget.value)
              }
            />
          </InputWrapper>
        </Group>
        <Group
          className={style.editMenuBarModalFooter}
          direction="row"
          position="right"
          style={{ bottom: 0 }}
        >
          <ButtonCustom width={"sm"} appearance="ghost" onClick={reset}>
            Отмена
          </ButtonCustom>

          <ButtonCustom
            onClick={handleUpdateDishes}
            width={"md"}
            appearance="secondary"
            disabled={!name}
          >
            Изменить
          </ButtonCustom>
        </Group>
      </Group>
    </ModalCustom>
  );
};

export default EditToMenu;
