import React from 'react';
import style from "./ContentHeader.module.css";
import {Group, Text} from "@mantine/core";
import {ContentHeaderProps} from "./ContentHeader.props";

const ContentHeader = ({headerTitle, children, ...props}: ContentHeaderProps) => {
    return (
        <header className={style.header} { ...props}>
            <Text weight={500} className={style.headerTitle}>
                {headerTitle}
            </Text>
            <Group noWrap direction="row" position="right">
                {children}
            </Group>
        </header>
    );
};

export default ContentHeader;