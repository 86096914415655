import React from 'react';
import { LoadingOverlay, Table} from "@mantine/core";
import {ExcursionListProps} from "./ExcursionList.props";
import style from "./ExcursionList.module.css";
import ExcursionBody from "./ExcursionBody";
import {
    useAddExcursionCommentMutation,
    useDeclineExcursionRequestMutation,
    useUpdateExcursionStatusMutation
} from "../../../redux/apiSlice/_excursionSlice";


const ExcursionList = ({excursions, ...props}: ExcursionListProps) => {

    const [declineExcursion, {isLoading: isDeclineLoading}] = useDeclineExcursionRequestMutation();
    const [updateExcursion, {isLoading: isUpdateLoading}] = useUpdateExcursionStatusMutation();
    const [addComment, {isLoading: isAddLoading}] = useAddExcursionCommentMutation();

    return (
        <React.Fragment>
            {isDeclineLoading || isUpdateLoading || isAddLoading
                ? <LoadingOverlay visible={isDeclineLoading || isUpdateLoading || isAddLoading}/>
                : <Table
                    className={style.applicationList}
                    {...props}
                    horizontalSpacing="md"
                    verticalSpacing="sm"
                    highlightOnHover
                    fontSize="xs"
                    sx={() => ({
                        backgroundColor: "white",
                    })}
                >
                    <thead>
                    <tr className={style.applicationListTHead}>
                        <th>Номер каюты</th>
                        <th>ФИО</th>
                        <th>Номер телефона</th>
                        <th>Город экскурсии</th>
                        <th>Наименование экскурсии</th>
                        <th>Количество человек, сумма</th>
                        <th>Размер скидки</th>
                        <th>Дата экскурсии</th>
                        <th>Статус</th>
                        <th/>
                    </tr>
                    </thead>
                    <tbody className={style.applicationListTbody}>
                    {excursions?.map((excursion) => (
                        <ExcursionBody excursion={excursion}
                                     key={excursion.passengerReuqestId}
                                     declineRequest={declineExcursion}
                                     updateRequest={updateExcursion}
                                     addComment={addComment}
                        />
                    ))}
                    </tbody>
                </Table>
            }
        </React.Fragment>
    );
};

export default ExcursionList;
