import React, {useState} from 'react';
import style from "./Notification.module.css";
import {Group, Table} from "@mantine/core";
import {ButtonCustom} from "../../../components";
import {ReactComponent as AddIcon} from "../../../assets/svg/add.svg";
import {useGetNotificationsQuery} from "../../../redux/apiSlice/_notificationsSlice";
import NotificationList  from "./NotificationList";
import {ListStub} from "../../../common";
import AddOrEditNotification from "./AddNotification/AddNotification";


const Notification = () => {
    const CRUISE_ID = localStorage.getItem("cruise");

    const [openNotificationModal, setOpenNotificationModal] = useState<boolean>(false);
    const {data = [], isLoading} = useGetNotificationsQuery(CRUISE_ID);

    return (
        <div className={style.cruiseControlNotification}>
            <AddOrEditNotification opened={openNotificationModal} onClose={() => setOpenNotificationModal(false)}/>
            <div className={style.notificationTableWrap}>
                {data.length === 0 || isLoading
                    ? <ListStub isLoading={isLoading}>Нет уведомлений </ListStub>
                    : <Table horizontalSpacing="md" verticalSpacing="md" className={style.notification}>
                        <thead className={style.notificationTHead}>
                        <tr className={style.notificationTHeadRow}>
                            <th>Время</th>
                            <th>Повтор</th>
                            <th>Описание</th>
                            <th>Активность</th>
                            <th/>
                        </tr>
                        </thead>
                        <tbody className={style.notificationTbody}>
                        {data.map(notification => (
                            <NotificationList notification={notification} key={notification.id}/>
                        ))}
                        </tbody>
                    </Table>
                }
                <Group
                    position="right"
                    onClick={() => setOpenNotificationModal(true)}
                    style={{borderTop: "1px solid var(--areaWhite)", padding: 10}}
                >
                    <ButtonCustom width="lg"
                                  appearance="secondary"
                                  leftIcon={<AddIcon fill={"var(--white)"}/>}
                    >
                        Добавить уведомление
                    </ButtonCustom>
                </Group>
            </div>
        </div>
    );
};

export default Notification;
