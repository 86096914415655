import React, {useState} from 'react';
import cn from "classnames";
import {Checkbox, Group, Input, InputWrapper, Select, Switch, Textarea} from "@mantine/core";
import {useForm} from "@mantine/form";
import {ModalCustom} from "../../../../common";
import {AddRestaurantProps} from "./AddRestaurant.props";
import style from './AddRestaurant.module.css';
import {ReactComponent as ArrowIcon} from "../../../../assets/svg/arrow.svg";
import {ButtonCustom} from "../../../../components";
import {
    useAddBarOrRestaurantMutation,
    useGetDecksByAdminQuery
} from "../../../../redux/apiSlice/_restaurantSlice";
export interface IAddForm {
    name: string,
    barTimeLab: string,
    schema: string
}
const AddRestaurant = ({onClick, opened, onClose, ...props}: AddRestaurantProps) => {
    const [isBar, setIsBar] = useState<boolean>(false);
    const [deckId, setDeckId] = useState<string | null>("");
    const [isAgreed, setIsAgreed] = useState(false);
    const SHIP_ID = Number(localStorage.getItem("ship"));
    const [addBarOrRestaurant] = useAddBarOrRestaurantMutation();
    const {data = []} = useGetDecksByAdminQuery(SHIP_ID);

    const decksData = data.map((deck) => ({value: String(deck.deckId), label: deck.name}));

    const form = useForm<IAddForm>({
        initialValues: {
            name: "",
            barTimeLab: "",
            schema: ""
        }
    });

    const handleAddRestaurant = async (value: IAddForm) => {
        onClose();
        setDeckId("");
        await addBarOrRestaurant({
            isBar,
            deckId,
            ...value
        }).unwrap();
        form.reset();
    };


    return (
        <ModalCustom
            className={style.addRestaurantModal}
            opened={opened}
            onClose={() => {
                onClose();
                setDeckId("");
            }}
            title="Добавить ресторан/бар"
            {...props}
        >
            <form onSubmit={form.onSubmit((handleAddRestaurant))}>
           <Switch
                className={style.addRestaurantModalSwitch}
                checked={isBar}
                onChange={(event) => setIsBar(event.currentTarget.checked)}
                label="Является баром"
            />
            <Group spacing="md" direction="column" grow className={cn(style.addRestaurantModalContent, {
                [style.removeMB]: isBar
            })}>
                <InputWrapper label="Наименование" className={style.addRestaurantModalInput}>
                    <Input placeholder="Введите название"
                           {...form.getInputProps('name')}
                    />
                </InputWrapper>
                <Select
                    rightSection={<ArrowIcon/>}
                    styles={{
                        rightSection: {pointerEvents: 'none'},
                    }}
                    className={style.addRestaurantModalSelect}
                    placeholder='Выберите палубу'
                    label="Палуба"
                    value={deckId}
                    onChange={(e) => setDeckId(e)}
                    data={decksData}
                />
                {isBar && (
                    <Textarea
                        className={style.addRestaurantModalTextArea}
                        placeholder="Введите время работы бара"
                        label="Время работы бара"
                        minRows={5}
                        maxRows={5}
                    />
                )}
                <div className={style.checkboxAgreed}>
                    <Checkbox checked={isAgreed} onChange={(event) => setIsAgreed(event.currentTarget.checked)} />
                    <label className={style.checkboxLabel}>{
                            "Ознакомлен, что при создании ресторана/бара его удаление возможно только через техническую поддержку"
                        }
                        <span className={style.checkboxAsterisk}>&nbsp;*</span>
                    </label>
                </div>

            </Group>
            <Group className={style.addRestaurantModalFooter} noWrap direction="row" position="right" style={{bottom: 0}}>
                <ButtonCustom width={"sm"} appearance="ghost" onClick={() => {
                    onClose();
                    setDeckId("");
                }}>
                    Отмена
                </ButtonCustom>
                <ButtonCustom width={"md"} appearance="secondary" type="submit" disabled={!isAgreed}>
                    Добавить
                </ButtonCustom>
            </Group>
            </form>
        </ModalCustom>
    );
};

export default AddRestaurant;
