import {BaseQueryFn, createApi, FetchArgs, fetchBaseQuery, FetchBaseQueryError} from '@reduxjs/toolkit/query/react';
import {BASE_API_URL} from "../../configs/api.config";
import { IDayFile } from '../../types/ICruiseControl';
import {RegistrationRestaurantType} from "../../types/IRegistration";

const baseQuery = fetchBaseQuery({
    baseUrl: BASE_API_URL,
    prepareHeaders: (headers ) => {
        const token = localStorage.getItem("token");

        if (token) {
            headers.set('X-SOZVEZDIE-AUTH-TOKEN', `${token}`);
        }

        return headers;
    },
});

const baseQueryWithReauth: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  const result = await baseQuery(args, api, extraOptions);
  if (result.error && result.error.status === 401) {
    localStorage.clear();
    window.location.href = "/";
    }
  return result;
};

export const restaurantSlice = createApi({
    reducerPath: "restaurant",
    baseQuery: baseQueryWithReauth,
    tagTypes: ['Tab', 'Restaurant', 'Images', 'Dishes', 'File'],
    endpoints(builder) {
        return {
            getRestaurantsByAdmin:  builder.query<RegistrationRestaurantType, number>({
                query: (shipId) => `/api/Restaurant/GetRestaurantsShortInfo/${shipId}`,
                providesTags: (result) =>
                    result
                        ?
                        [
                            ...result.map(({ id }) => ({ type: 'Restaurant', id } as const)),
                            { type: 'Restaurant', id: 'LIST' },
                        ]
                        :
                        [{ type: 'Restaurant', id: 'LIST' }],
            }),
            addBarOrRestaurant: builder.mutation({
                query: (body) => ({
                    url: `/api/Restaurant/AddBarOrRestaurant`,
                    method: 'POST',
                    body,
                }),
                invalidatesTags: ['Restaurant']
            }),
            getMenuTabs:  builder.query({
                query: () => {
                   return `/api/RestaurantMenu/GetRestaurantTabs`
                },
                providesTags: (result) =>
                    result
                        ?
                        [
                            ...result.map(({ id }: {id: string}) => ({ type: 'Tab', id } as const)),
                            { type: 'Tab', id: 'LIST' },
                        ]
                        :
                        [{ type: 'Tab', id: 'LIST' }],
            }),

            getRestaurantImages:  builder.query<{images: {id: string, uri: string}[], mainImage: {id: string, uri: string}}, string | null>({
                query: (restaurantGuid ) => {
                    return `/api/Restaurant/GetRestaurantImages/${restaurantGuid}`;
                },
                providesTags: (result) =>
                    result
                        ?
                        [
                            ...result.images.map((img) => ({ type: 'Images', img } as const)),
                            { type: 'Images', img: 'LIST' },
                        ]
                        :
                        [{ type: 'Images', img: 'LIST' }],
            }),

            getDecksByAdmin:  builder.query<{ name: string, deckId: number }[], number | null>({
                query: (shipId ) => `/api/Decks/GetDecks/${shipId}`
            }),

            addMenuTab: builder.mutation({
                query: (body) => ({
                    url: `/api/RestaurantMenu/AddRestaurantTab`,
                    method: 'POST',
                    body,
                }),
                invalidatesTags: ['Tab']
            }),

            addDishes: builder.mutation({
                query: (body) => ({
                    url: `/api/RestaurantMenu/AddDishes`,
                    method: 'POST',
                    body,
                }),
                invalidatesTags: ['Tab', 'Dishes']
            }),

            updateDish: builder.mutation({
                query: (body) => ({
                    url: `/api/RestaurantMenu/UpdateDish`,
                    method: 'PUT',
                    body,
                }),
                invalidatesTags: ['Tab', 'Dishes']
            }),
            deleteDish: builder.mutation({
                query: (body) => ({
                    url: `/api/RestaurantMenu/DeleteDish/${body.dishId}/${body.tabId}`,
                    method: 'DELETE',
                }),
                invalidatesTags: ['Tab', 'Dishes']
            }),

            getAllDishes: builder.query<{name: string, id: string}[], null>({
                query: () => `/api/DishesCatalog/GetDishesBySearchPattern`,
                providesTags: (result) =>
                    result
                        ?
                        [
                            ...result.map((dish) => ({ type: 'Dishes', dish } as const)),
                            { type: 'Dishes', dish: 'LIST' },
                        ]
                        :
                        [{ type: 'Dishes', dish: 'LIST' }],
            }),

            AddMainImageToRestaurant: builder.mutation({
                query: ({body, restaurantId}) => ({
                    url: `/api/Images/AddMainImageToRestaurant/${restaurantId}`,
                    method: 'POST',
                    body
                }),
                invalidatesTags: ['Images']
            }),

            AddImageToRestaurant: builder.mutation({
                query: ({body, restaurantId}) => ({
                    url: `/api/Images/AddImagesToRestaurant/${restaurantId}`,
                    method: 'POST',
                    body
                }),
                invalidatesTags: ['Images']
            }),
            DeleteImageToRestaurant: builder.mutation({
                query: ({body}) => ({
                    url: `/api/Images/DeleteRestaurantImage/${body.restaurantId}/${body.imageId}`,
                    method: 'DELETE',
                }),
                invalidatesTags: ['Images']
            }),

            getMenuByDate: builder.query<IDayFile, {date:Date | string,  cruiseId: string | null}>({
                query: ({date, cruiseId}) =>  ({
                    url: `/api/RestaurantMenu/GetRestaurantMenuFileByDate?date=${date}`,
                }),
                providesTags: (result) =>
                    result
                        ?
                        [
                            ...[result].map(({ id }) => ({ type: 'File', id } as const)),
                            { type: 'File', id: 'LIST' },
                        ]
                        :
                        [{ type: 'File', id: 'LIST' }],
            }),
            addMenuFile: builder.mutation({
                query: ({body, date, cruiseId}) => ({
                    url: `/api/RestaurantMenu/AddRestaurantMenuFile?date=${date}`,
                    method: 'POST',
                    body
                }),
                invalidatesTags: ['File']
            }),
            DeleteMenu: builder.mutation({
                query: ({date}) => ({
                    url: `/api/RestaurantMenu/DeleteRestaurantMenuFileByDate?date=${date}`,
                }),
                invalidatesTags: ['File']
            }),
        };
    }
});

export const {
    useGetDecksByAdminQuery,
    useGetRestaurantsByAdminQuery,
    useGetMenuTabsQuery,
    useAddMenuTabMutation,
    useGetRestaurantImagesQuery,
    useAddDishesMutation,
    useUpdateDishMutation,
    useDeleteDishMutation,
    useAddBarOrRestaurantMutation,
    useAddMainImageToRestaurantMutation,
    useAddImageToRestaurantMutation,
    useGetAllDishesQuery,
    useDeleteImageToRestaurantMutation,
    useGetMenuByDateQuery,
    useAddMenuFileMutation,
    useDeleteMenuMutation
} = restaurantSlice;