import React from 'react';
import {Dialog} from '@mantine/core';
import {FilterDialogProps} from "./_FilterDialog.props";

export const FilterDialog = ({onClose, opened, children, ...props}: FilterDialogProps) => (
    <Dialog
        opened={opened}
        onClose={onClose}
        position={{top: 150, right: 30}}
        radius="sm"
        {...props}
    >
        {children}
    </Dialog>
);

