import React, {useState} from 'react';
import {RegistrationListProps} from "./RegistrationList.props";
import style from "./RegistrationList.module.css";
import {Group, Menu, Pagination, Table, Text} from "@mantine/core";
import {Archive, ArrowsRandom} from "tabler-icons-react";
import {RegistrationType} from "../../../types/IRegistration";
import {useDeletePassengerMutation, useFetchRegistrationQuery} from "../../../redux/apiSlice/_registrationSlice";
import {ListStub, ModalCustom} from "../../../common";
import RegistrationBody from "./RegistrationBody";

const RegistrationList = ({data, isLoading, setPage, activePage, pageSize, restaurantData}: any) => {

    return (
        data.models.length === 0 || isLoading
            ? <ListStub isLoading={isLoading}><Text component="label">Нет гостей!</Text></ListStub>
            : <>
                <Table
                    className={style.registrationList}
                    horizontalSpacing="md"
                    verticalSpacing="sm"
                    highlightOnHover
                    fontSize="xs"
                    sx={() => ({
                        backgroundColor: "white",
                    })}>
                    <thead>
                    <tr className={style.registrationListTHead}>
                        <th> Ресторан</th>
                        <th>ФИО</th>
                        <th>Номер каюты</th>
                        <th>Номер столика</th>
                        <th>Кол-во человек</th>
                        <th/>
                    </tr>
                    </thead>
                    <tbody className={style.registrationListTbody}>
                    {data.models.map((item: RegistrationType, index: number) => (
                        <RegistrationBody
                            item={item}
                            index={index}
                            key={item.reservationPassengerId}
                            restaurant={restaurantData.filter((r: any) => r.name === item.restaurant.name)}
                        />
                    ))}
                    </tbody>
                </Table>
                {data.total > pageSize && (
                    <div className={style.paginationWrap}>
                        <Pagination position="right"
                                    page={activePage}
                                    onChange={setPage}
                                    total={Math.ceil(data.total / pageSize)}
                                    color="dark"
                                    size="xs"
                        />
                    </div>

                )}
            </>
    );
};

export default RegistrationList;