import React from 'react';
import {Route, Routes } from "react-router-dom";
import {routes} from "./routes/routes";
import {withLayout} from "./layout/Layout";
import {Chat} from "./pages";

function App() {

    return (
        <Routes>
            {routes.map(route => (
                <Route path={route.path} element={route.component} key={route.path}/>
            ))}
            <Route path="chat" element={<Chat />}>
                <Route path=":passengerId"  element={<Chat />}/>
            </Route>
        </Routes>
    );
}

export default withLayout(App);
