import React, {useState} from 'react';
import {RequestDetailProps} from "./RequestDetail.props";
import style from './RequestDetail.module.css';
import {Text, Group, Drawer, useMantineTheme, Badge, Select} from '@mantine/core';
import {
    useGetAvailableStatusesQuery,
    useGetRequestByIdQuery
} from "../../../redux/apiSlice/_requestSlice";
import {ButtonCustom} from "../../../components";
import {ListStub} from "../../../common";
import {STATUS} from "../../../constants/CONSTANT";
import {ReactComponent as ArrowIcon} from "../../../assets/svg/arrow.svg";
import {IRequestServices} from "../../../types/IRequest";


const RequestDetail = ({openDeclineModal, guid, onClose, confirmRequest, ...props}: RequestDetailProps) => {
    const theme = useMantineTheme();

    const {data: request = [], isLoading: isRequest, isError} = useGetRequestByIdQuery(guid);
    const {data: allStatuses = []} = useGetAvailableStatusesQuery({
        requestTypeId: request?.requestType?.id,
        currentStatusId: request?.requestStatus?.id
    });

    const [selectStatus, setSelectStatus] = useState<string>("");

    const statuses = allStatuses.map((type: { id: string, title: string }) => ({
        value: String(type.id),
        label: type.title
    }));


    const handleUpdateRequest = () => {
        onClose();
        selectStatus === STATUS.Rejected ? openDeclineModal() : confirmRequest();
    };

    return (
        <Drawer
            className={style.drawerModal}
            overlayColor={theme.colorScheme === 'dark' ? theme.colors.dark[9] : theme.colors.gray[2]}
            overlayOpacity={0.55}
            overlayBlur={3}
            position="right"
            styles={{root: {position: "relative"}}}
            onClose={onClose}
            title="Заявка"
            size={450}
            {...props}
        >{!request || isRequest || isError
            ? <ListStub isLoading={isRequest} isError={isError}>Нет информации</ListStub>
            : <>
                <Group direction="column">
                    {request?.breakfastRequestInfo && request?.breakfastRequestInfo?.comment &&(
                        <Group direction="column" p="md" style={{
                            background: "#FFE4E4",
                            width: "100%",
                            borderRadius: 10
                        }}>
                            {request?.breakfastRequestInfo.comment && (
                                <Group className={style.drawerGroup} direction='column'>
                                    <Text className={style.drawerLabel}>Комментарий пассажира</Text>
                                    <Text weight={700}>{request?.breakfastRequestInfo.comment}</Text>
                                </Group>
                            )}
                        </Group>
                    )}
                    {request?.cleaningRequestInfo && request?.cleaningRequestInfo?.comment &&(
                        <Group direction="column" p="md" style={{
                            background: "#FFE4E4",
                            width: "100%",
                            borderRadius: 10
                        }}>
                            <Group className={style.drawerGroup} direction='column'>
                                <Text className={style.drawerLabel}>Комментарий пассажира</Text>
                                <Text weight={700}>{request?.cleaningRequestInfo?.comment}</Text>
                            </Group>
                        </Group>
                    )}
                    {request?.laundryRequestInfo && request?.laundryRequestInfo?.comment &&(
                        <Group direction="column" p="md" style={{
                            background: "#FFE4E4",
                            width: "100%",
                            borderRadius: 10
                        }}>
                            {request?.laundryRequestInfo.comment && (
                                <Group className={style.drawerGroup} direction='column'>
                                    <Text className={style.drawerLabel}>Комментарий пассажира</Text>
                                    <Text weight={700}>{request?.laundryRequestInfo.comment}</Text>
                                </Group>
                            )}
                        </Group>
                    )}
                    {request?.congratulationRequestInfo && request?.congratulationRequestInfo?.comment &&(
                        <Group direction="column" p="md" style={{
                            background: "#FFE4E4",
                            width: "100%",
                            borderRadius: 10
                        }}>
                            {request?.congratulationRequestInfo.comment && (
                                <Group className={style.drawerGroup} direction='column'>
                                    <Text className={style.drawerLabel}>Комментарий пассажира</Text>
                                    <Text weight={700}>{request?.congratulationRequestInfo.comment}</Text>
                                </Group>
                            )}
                        </Group>
                    )}
                    {request?.barDishRequestInfo && request?.barDishRequestInfo?.comment &&(
                        <Group direction="column" p="md" style={{
                            background: "#FFE4E4",
                            width: "100%",
                            borderRadius: 10
                        }}>
                            {request?.barDishRequestInfo.comment && (
                                <Group className={style.drawerGroup} direction='column'>
                                    <Text className={style.drawerLabel}>Комментарий пассажира</Text>
                                    <Text weight={700}>{request?.barDishRequestInfo.comment}</Text>
                                </Group>
                            )}
                        </Group>
                    )}
                    {request?.comment && (
                        <Group className={style.drawerGroup} direction='column' p="md" style={{
                            background: "#EBEBF0",
                            width: "100%",
                            borderRadius: 10
                        }} >
                            <Text className={style.drawerLabel}>Комментарий администратора</Text>
                            <Text weight={700}>{request?.comment}</Text>
                        </Group>
                    )}
                    <Select
                        className={style.drawerInputStatus}
                        rightSection={<ArrowIcon/>}
                        styles={{rightSection: {pointerEvents: 'none'}}}
                        variant="filled"
                        onChange={(e) => setSelectStatus(e!)}
                        value={selectStatus}
                        placeholder={request?.requestStatus?.title}
                        label="Статус"
                        data={statuses}
                    />
                    <Group className={style.drawerGroup} direction='column'>
                        <Text className={style.drawerLabel}>ФИО</Text>
                        <Text weight={700}>{request?.passengerFullName}</Text>
                    </Group>
                    <Group className={style.drawerGroup} direction='column'>
                        <Text className={style.drawerLabel}>Каюта</Text>
                        <Text weight={700}>{request?.cabin}</Text>
                    </Group>
                    <Group className={style.drawerGroup} direction='column'>
                        <Text className={style.drawerLabel}>Телефон</Text>
                        <Text weight={700}>{request?.phone}</Text>
                    </Group>
                    <Group className={style.drawerGroup} direction='column'>
                        <Text className={style.drawerLabel}>Тип обращения</Text>
                        <Text weight={700}>{request?.requestType?.title}</Text>
                    </Group>
                    {request?.saunaRequestInfo && (
                        <Group direction="column">
                            <Group className={style.drawerGroup} direction='column'>
                                <Text className={style.drawerLabel}>Дата брони </Text>
                                <Text weight={700}>{request?.saunaRequestInfo?.date}</Text>
                            </Group>
                            <Group className={style.drawerGroup} direction='column'>
                                <Text className={style.drawerLabel}>Время брони</Text>
                                <Text weight={700}>{request?.saunaRequestInfo?.period}</Text>
                            </Group>
                        </Group>
                    )}
                    {request?.breakfastRequestInfo && (
                        <Group direction="column">
                            <Group className={style.drawerGroup} direction='column'>
                                {request?.breakfastRequestInfo.dishes?.map((s: { name: string, count: number }) => (
                                    <Group direction='column' p="md"
                                           style={{
                                               background: "rgba(226,242,252,0.64)",
                                               width: "100%",
                                               borderRadius: 10
                                           }}>
                                        <Group className={style.drawerGroup} direction='column'>
                                            <Text className={style.drawerLabel}>Название</Text>
                                            <Text weight={700}>{s?.name}</Text>
                                        </Group>
                                        <Group className={style.drawerGroup} direction='column'>
                                            <Text className={style.drawerLabel}>Кол-во</Text>
                                            <Text weight={700}>{s?.count}</Text>
                                        </Group>
                                    </Group>
                                ))
                                }
                            </Group>
                            <Group className={style.drawerGroup} direction='column'>
                                <Text className={style.drawerLabel}>Интервал доставки</Text>
                                <Text weight={700}>{request?.breakfastRequestInfo.interval}</Text>
                            </Group>
                            <Group className={style.drawerGroup} direction='column'>
                                <Text className={style.drawerLabel}>Кол-во приборов</Text>
                                <Text weight={700}>{request?.breakfastRequestInfo.flatware}</Text>
                            </Group>
                        </Group>
                    )}
                    {request?.excursionRequestInfo && (
                        <Group direction="column">
                            <Group className={style.drawerGroup} direction='column'>
                                <Text className={style.drawerLabel}>Название</Text>
                                <Text weight={700}>{request?.excursionRequestInfo?.name}</Text>
                            </Group>
                            <Group className={style.drawerGroup} direction='column'>
                                <Text className={style.drawerLabel}>Дата</Text>
                                <Text weight={700}>{request?.excursionRequestInfo?.date}</Text>
                            </Group>
                            <Group className={style.drawerGroup} direction='column'>
                                <Text className={style.drawerLabel}>Кол-во взрослых</Text>
                                <Text weight={700}>{request?.excursionRequestInfo?.adultNumber}</Text>
                            </Group>
                            <Group className={style.drawerGroup} direction='column'>
                                <Text className={style.drawerLabel}>Кол-во детей</Text>
                                <Text weight={700}>{request?.excursionRequestInfo?.childNumber}</Text>
                            </Group>
                            <Group className={style.drawerGroup} direction='column'>
                                <Text className={style.drawerLabel}>Цена</Text>
                                <Badge color="teal" size="xl">
                                    {request?.excursionRequestInfo.totalPrice} ₽
                                </Badge>
                            </Group>
                        </Group>
                    )}
                    <Group className={style.drawerGroup} direction='column'>
                        <Text className={style.drawerLabel}>Программа лояльности</Text>
                        <Text weight={700}>{request?.loyalityLevel?.name ?? '-'}</Text>
                    </Group>
                    <Group className={style.drawerGroup} direction='column'>
                        <Text className={style.drawerLabel}>Скидка</Text>
                        <Text weight={700}>{request?.discountPercentage ? request?.discountPercentage + '%' : '-'}</Text>
                    </Group>
                    {request?.laundryRequestInfo && (
                        <Group direction="column">
                            <Group className={style.drawerGroup} direction='column'>
                                <Text className={style.drawerLabel}>Тип услуги</Text>
                                <Text weight={700}>{request?.laundryRequestInfo.laundryType}</Text>
                            </Group>
                            <Group className={style.drawerGroup} direction='column'>
                                <Text className={style.drawerLabel}>Тип одежды</Text>
                                <Text weight={700}>{request?.laundryRequestInfo.clothesType}</Text>
                            </Group>
                            <Group className={style.drawerGroup} direction='column'>
                                <Text className={style.drawerLabel}>Количество</Text>
                                <Text weight={700}>{request?.laundryRequestInfo.count}</Text>
                            </Group>
                            <Text className={style.drawerLabel}>Общая сумма: </Text>
                            <Badge color="teal" size="xl">
                                {request?.laundryRequestInfo.totalPrice} ₽
                            </Badge>
                        </Group>
                    )}
                    {request?.additionalRequestInfo && (
                        <Group direction="column">
                            <Group className={style.drawerGroup} direction='column'>
                                {request?.additionalRequestInfo.services?.map((s: { price: string, title: string }) => (
                                    <Group p="md"
                                           style={{
                                               background: "rgba(226,242,252,0.64)",
                                               width: "100%",
                                               borderRadius: 10
                                           }}
                                           direction='column'>
                                        <Group className={style.drawerGroup} direction='column'>
                                            <Text className={style.drawerLabel}>Название</Text>
                                            <Text weight={700}>{s?.title}</Text>
                                        </Group>
                                        <Group className={style.drawerGroup} direction='column'>
                                            <Text className={style.drawerLabel}>Цена</Text>
                                            <Text weight={700}>{s?.price}</Text>
                                        </Group>
                                    </Group>
                                ))
                                }
                            </Group>
                            <Text className={style.drawerLabel}>Общая сумма: </Text>
                            <Badge color="teal" size="xl">
                                {request?.additionalRequestInfo.totalPrice} ₽
                            </Badge>
                        </Group>
                    )}
                    {request?.congratulationRequestInfo && (
                        <Group direction="column">
                            <Group className={style.drawerGroup} direction='column'>
                                <Text className={style.drawerLabel}>Время поздравления</Text>
                                <Text weight={700}>{request?.congratulationRequestInfo.time.name}</Text>
                            </Group>
                            {request?.congratulationRequestInfo.fullName && <Group className={style.drawerGroup} direction='column'>
                                <Text className={style.drawerLabel}>ФИО именинника</Text>
                                <Text weight={700}>{request?.congratulationRequestInfo.fullName}</Text>
                            </Group>}
                            {request?.congratulationRequestInfo.otherVariant && <Group className={style.drawerGroup} direction='column'>
                                <Text className={style.drawerLabel}>Другое событие</Text>
                                <Text weight={700}>{request?.congratulationRequestInfo.otherVariant}</Text>
                            </Group>}
                            <Text className={style.drawerLabel}>Услуги</Text>
                            <Group className={style.drawerGroup} direction='column'>
                                {request.congratulationRequestInfo.includedServices.length !== 0 && (
                                    <Text weight={700} size="xl" mb="md">В пакет входит:</Text>
                                )}
                                {request.congratulationRequestInfo.includedServices.map((s: IRequestServices) => (
                                    <Group direction='column'
                                           p="md"
                                           style={{
                                               background: "rgba(226,242,252,0.64)",
                                               width: "100%",
                                               borderRadius: 10
                                           }}>
                                        <Group className={style.drawerGroup} direction='column'>
                                            <Text className={style.drawerLabel}>Услуга</Text>
                                            <Text weight={700}>{s?.title}</Text>
                                        </Group>
                                        {s.services.map((service: IRequestServices) => (
                                            <Group direction='column' ml="lg">
                                                <Text weight={700}>- {service.title}</Text>
                                            </Group>
                                        ))}
                                        {s?.price && (
                                            <Group className={style.drawerGroup} direction='column'>
                                                <Text className={style.drawerLabel}>Цена</Text>
                                                <Text weight={700}>{s?.price} ₽</Text>
                                            </Group>
                                        )}
                                    </Group>
                                ))}
                                {request.congratulationRequestInfo.notIncludedServices.length !== 0 && (
                                    <Text weight={700} size="xl" mb="md">В пакет не входит:</Text>
                                )}
                                {request.congratulationRequestInfo.notIncludedServices.map((s: IRequestServices) => (
                                    <Group direction='column'
                                           p="md"
                                           style={{
                                               background: "rgba(226,242,252,0.64)",
                                               width: "100%",
                                               borderRadius: 10
                                           }}>
                                        <Group className={style.drawerGroup} direction='column'>
                                            <Text className={style.drawerLabel}>Услуга</Text>
                                            <Text weight={700}>{s?.title}</Text>
                                        </Group>
                                        {s.services.map((service: IRequestServices) => (
                                            <Group direction='column' ml="lg">
                                                <Text weight={700}>- {service.title}</Text>
                                            </Group>
                                        ))}
                                        {s?.price && (
                                            <Group className={style.drawerGroup} direction='column'>
                                                <Text className={style.drawerLabel}>Цена</Text>
                                                <Text weight={700}>{s?.price} ₽</Text>
                                            </Group>
                                        )}
                                    </Group>
                                ))}
                            </Group>
                            <Group className={style.drawerGroup} direction='column'>
                                <Text className={style.drawerLabel}>Итоговая стоимость</Text>
                                <Badge color="teal" size="xl">
                                    {request?.congratulationRequestInfo.totalPrice} ₽
                                </Badge>
                            </Group>
                        </Group>
                    )}
                    {request?.barDishRequestInfo && (
                        <Group direction="column">
                            {request?.barDishRequestInfo.dishes?.map((dish: { name: string, price: number, count: number }) => (
                                <Group direction="column" p="md"
                                       style={{
                                           background: "rgba(226,242,252,0.64)",
                                           width: "100%",
                                           borderRadius: 10
                                       }}>
                                    <Group className={style.drawerGroup} direction='column'>
                                        <Text className={style.drawerLabel}>Название</Text>
                                        <Text weight={700}>{dish?.name}</Text>
                                    </Group>
                                    <Group className={style.drawerGroup} direction='column'>
                                        <Text className={style.drawerLabel}>Цена</Text>
                                        <Text weight={700}>{dish?.price}</Text>
                                    </Group>
                                    <Group className={style.drawerGroup} direction='column'>
                                        <Text className={style.drawerLabel}>Количество</Text>
                                        <Text weight={700}>{dish?.count}</Text>
                                    </Group>
                                </Group>
                            ))}
                            <Group className={style.drawerGroup} direction='column'>
                                <Text className={style.drawerLabel}>Общая сумма: </Text>
                                <Badge color="teal" size="xl">
                                    {request?.barDishRequestInfo.totalPrice} ₽
                                </Badge>
                            </Group>
                        </Group>
                    )}
                </Group>
                <Group className={style.drawerFooter} noWrap style={{marginTop: 100}}>
                    <ButtonCustom appearance="ghost" onClick={onClose}>
                        Отмена
                    </ButtonCustom>
                    <ButtonCustom appearance="secondary" onClick={handleUpdateRequest}>
                        Сохранить
                    </ButtonCustom>
                </Group>
            </>
        }
        </Drawer>
    );
};

export default RequestDetail;
