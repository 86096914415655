import React, {useState} from 'react';
import {ModalCustom} from "../../../common";
import style from "./ExcursionList.module.css";
import {Group, Textarea} from "@mantine/core";
import {ButtonCustom} from "../../../components";
import {useAddCommentMutation} from "../../../redux/apiSlice/_requestSlice";

export interface IAddComment {
    opened: boolean
    requestId: string
    onClose: () => void
    addComment: (model: { requestId: string, comment: string }) => void
}

const AddComment = ({requestId, opened, onClose, addComment}: IAddComment) => {
    const [comment, setComment] = useState<string>("");

    const handleAddComment = async () => {
        onClose()
        setComment("")
        await addComment({
            requestId,
            comment
        })
    }

    return (
        <ModalCustom
            className={style.modalCustomTitle}
            centered
            padding={16}
            radius={"md"}
            size={492}
            opened={opened}
            onClose={onClose}
            title="Добавить комментарий"
        >
            <Textarea
                className={style.modalCustomTextarea}
                placeholder="Комментарий"
                minRows={6}
                radius={8}
                label="Комментарий"
                value={comment}
                onChange={(e) => setComment(e.currentTarget.value)}
            />
            <Group noWrap direction="row" position="right" style={{bottom: 0, marginTop: 20}}>
                <ButtonCustom width={"sm"} appearance="ghost" onClick={onClose}>
                    Отмена
                </ButtonCustom>
                <ButtonCustom width={"md"} appearance="secondary" onClick={handleAddComment}>
                    Добавить
                </ButtonCustom>
            </Group>
        </ModalCustom>
    );
};

export default AddComment;