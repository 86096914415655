import React, {useState} from 'react';
import style from "./Notification.module.css";
import {Menu, Switch} from "@mantine/core";
import {Edit, Trash} from "tabler-icons-react";
import {
    useDeleteNotificationMutation,
    useUpdateNotificationMutation
} from "../../../redux/apiSlice/_notificationsSlice";
import AddOrEditNotification from "./AddNotification/AddNotification";
import {DAYS_WEEK, NOTIFICATION_REPLY} from "../../../constants/CONSTANT";

export interface INotificationList {
    buttonType: number,
    date: string,
    description: string,
    id: string,
    isActive: boolean,
    isSingle: boolean,
    reply: null | boolean[]
    time: string
    title: string
}

const NotificationList = ({notification}: any) => {

    const [openNotificationModal, setOpenNotificationModal] = useState<boolean>(false);

    const [deleteNotification] = useDeleteNotificationMutation();
    const [updateNotification] = useUpdateNotificationMutation();

    const handleDeleteNotification = async (id: string) => {
        await deleteNotification(id);
    };

    const isDaily = notification?.reply?.filter((r: boolean) => r).length === 7;

    let daysReply = "";
    notification?.reply?.filter((isChecked: boolean, index: number) => {
        if (isChecked) {
            daysReply += ` ${DAYS_WEEK[index]}`;
        }
    });

    const CRUISE_ID = localStorage.getItem("cruise");

    const handleSetIsActiveNotification = async (isActive: boolean) => {
        const model = {
            ...notification,
            cruiseId: CRUISE_ID,
            isActive: isActive,
        };
        await updateNotification(model);
    };

    return (
        <>
            {openNotificationModal && (
                <AddOrEditNotification notification={notification}
                                       opened={openNotificationModal}
                                       onClose={() => setOpenNotificationModal(false)}
                />
            )}
            <tr className={style.notificationTbodyRow}>
                <td>{notification.time}</td>
                <td>{
                    notification.isSingle
                        ? NOTIFICATION_REPLY.ONCE
                        : isDaily
                            ? NOTIFICATION_REPLY.DAILY
                            : daysReply
                }</td>
                <td>{notification.description}</td>
                <td>
                    <Switch
                        className={style.notificationSwitch}
                        checked={notification.isActive}
                        onChange={(event) => handleSetIsActiveNotification(event.currentTarget.checked)}
                        size="sm"
                    />
                </td>
                <td>
                    <Menu position={'left'} trigger="hover" className={style.notificationTbodyImg}>
                        <Menu.Item icon={<Edit size={24}/>}
                                   onClick={() => setOpenNotificationModal(true)}
                        >
                            Редактировать
                        </Menu.Item>
                        <Menu.Item icon={<Trash size={24} color="red"/>}
                                   onClick={() => handleDeleteNotification(notification.id)}
                        >
                            Удалить
                        </Menu.Item>
                    </Menu>
                </td>
            </tr>
        </>
    );
};

export default NotificationList;