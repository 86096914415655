import "../Schemes.css";
import {IActiveTable, ITables} from "../../../../types/IRegistration";
import {clearNewRect} from "../../../../helpers/helpers";

interface IDrawSeats {
    table: any,
    guests: number,
    setActiveTable: (active: IActiveTable) => void,
    setSeats: (seats: string) => void,
    setType: (type: string) => void,
    isActive?: boolean
}

export const drawSeats = ({
                              table,
                              setActiveTable,
                              setSeats,
                              setType,
                              guests,
                              isActive
                          }: IDrawSeats) => {

    const tableElement = document.getElementById(`Table_${table.number}`);
    const path = tableElement?.getElementsByTagName("rect")[0];
    const rect = tableElement?.getElementsByTagName("rect");
    const point = tableElement?.getElementsByClassName('Point')[0];

    let x = Number(point?.getAttribute("x"));
    const y = Number(point?.getAttribute("y"));

    if (path) {
        path.style.fill = isActive ? "#00B9E7" : !table.guid ? "#efefef" : "#052039";
    }

    if (rect) {

        rect[1].style.fill = "white";

        if (Number(table.numberSeats) === 0) {
            rect[1].style.fill = "none";
        }

        if (Number(table.numberOccupied) === 0 && guests !== 0) {
            rect[1].style.fill = "#EE7605";
        }
    }

    if (tableElement) {

        tableElement.style.cursor = "pointer";


        tableElement.onclick = () => {
            setActiveTable({numberOfTable: String(table.number), isWideTable: path && path.getBoundingClientRect().width > 72});
            setType(table.isCloseRegistration ? "Закрытая" : "Открытая");
            setSeats(String(table.numberSeats));
        };
    }

    const getFill = (index: number, rect: any) => {

        if (index < Number(table.numberSeats)) {
            rect.style.fill = "white";
        }

        if (guests) {
            if (index == (Number(table.numberOccupied) + (guests - 1))) {
                rect.style.fill = "#EE7605";
            }
        }
    };

    const rects = document.getElementById(`Table_${table.number}`)?.getElementsByClassName("rectNew")
    if(rects){
        clearNewRect(rects, 10)
    }

    for (let i = 1; i < Number(table.numberSeats); i++) {
        const rect = document.createElementNS("http://www.w3.org/2000/svg", 'rect');
        rect.setAttribute("x", `${x ? x += 8 : 25}`);
        rect.setAttribute("y", `${y}`);
        rect.setAttribute("rx", "2.5");
        rect.classList.add("rectNew");
        getFill(i, rect);

        tableElement?.appendChild(rect);
    }
};
