import {BaseQueryFn, createApi, FetchArgs, fetchBaseQuery, FetchBaseQueryError} from '@reduxjs/toolkit/query/react';
import {ReviewsData, ReviewsFilterType, ReviewsModel} from "../../types/IReviews";
import {BASE_API_URL} from "../../configs/api.config";

const baseQuery = fetchBaseQuery({
    baseUrl: BASE_API_URL,
    prepareHeaders: (headers ) => {
        const token = localStorage.getItem("token");

        if (token) {
            headers.set('X-SOZVEZDIE-AUTH-TOKEN', `${token}`);
        }

        return headers;
    },
});

const baseQueryWithReauth: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  const result = await baseQuery(args, api, extraOptions);
  if (result.error && result.error.status === 401) {
    localStorage.clear();
    window.location.href = "/";
    }
  return result;
};

export const reviewSlice = createApi({
    reducerPath: "review",
    baseQuery: baseQueryWithReauth,
    tagTypes: ['Review'],
    endpoints(builder) {
        return {
            fetchReview: builder.query<ReviewsData, ReviewsModel<ReviewsFilterType> | string>({
                query: (body) => ({
                    url: `/api/Review/GetAllReviews`,
                    method: 'POST',
                    body,
                }),
                providesTags: (result) =>
                    result
                        ?
                        [
                            ...result.models.map(({ id }) => ({ type: 'Review', id } as const)),
                            { type: 'Review', id: 'LIST' },
                        ]
                        :
                        [{ type: 'Review', id: 'LIST' }],
            }),
            getAllStatuses: builder.query<{ id: string, title: string }[], null>({
                query: () =>  ({
                    url: `/api/Review/GetAllStatuses`,
                })
            }),
            getReviewById: builder.query({
                query: (reviewGuid) =>  `/api/Review/GetDetailReview/${reviewGuid}`,
                providesTags:["Review"]
            }),
            updateReviewStatus: builder.mutation<string , { reviewGuid: string }>({
                query: (body) => ({
                    url: `/api/Review/SetProcessedStatus?reviewGuid=${body.reviewGuid}`,
                    method: 'POST',
                    body,
                }),
                invalidatesTags: ['Review']
            }),
            getReviewsCsv: builder.query<{reviewsCsv: string} , { cruiseId: string | null, shouldIncludeRelativeCruises: boolean }>({
                query: (body) => ({
                    url: `/api/Review/GetReviewsCsvByCruiseId/${body.cruiseId}?shouldIncludeRelativeCruises=${body.shouldIncludeRelativeCruises}`,
                    method: 'POST',
                    body,
                }),
            }),
        };
    }
});

export const {
    useFetchReviewQuery,
    useGetAllStatusesQuery,
    useUpdateReviewStatusMutation,
    useGetReviewByIdQuery,
    useGetReviewsCsvQuery
} = reviewSlice;