import React, {useState} from 'react';
import {LoadingOverlay, Text} from "@mantine/core";
import cn from "classnames";
import style from './Range.module.css';
import {IPeriod} from "../../../types/ISauna";

export interface IWorkingHours {
    periods: IPeriod[]
    isLoading: boolean
    ranges: string[]
    toggleRange: (range: string) => void
}

const Range = ({toggleRange, periods, isLoading, ranges}: IWorkingHours) => {
    return (
        <div className={style.workingHours}>
            <LoadingOverlay visible={isLoading}/>
            <header className={style.workingHoursHeader}>
                <Text className={style.workingHoursTitle}>
                    Время работы сауны
                </Text>
            </header>
            <div className={style.workingHoursWrapItem}>
                {periods?.map(p => (
                    <Text onClick={() => {
                        if(p.isReserved) return;
                        toggleRange(p.period);
                    }}
                          className={cn(style.workingHoursItem, {
                              [style.isActive]: ranges.includes(p.period),
                              [style.isReserved]: p.isReserved,
                          })}
                          key={p.period}
                    >
                        {p.period}
                    </Text>
                ))}
            </div>
        </div>
    );
};

export default Range;
