import React, {useEffect, useState} from 'react';
import {RegistrationDetailProps} from "./RegistrationDetail.props";
import {Text, Group, Select, useMantineTheme, Drawer, Loader, LoadingOverlay} from '@mantine/core';
import style from "./RegistrationDetail.module.css";
import {ReactComponent as ArrowIcon} from "../../../assets/svg/arrow.svg";
import {ButtonCustom} from "../../../components";
import {
    useGetSeatSchemaQuery,
    useUpdatePassengerTablesMutation,
} from "../../../redux/apiSlice/_registrationSlice";
import AddPassengerSchemes from "../Schemes/AddPassenger/AddPassengerSchemes";
import {ITables} from "../../../types/IRegistration";
import {getGuestRect} from "../Schemes/helpers/getGuestRect";

const RegistrationDetail = ({ onClose, detail, restaurant, ...props}: RegistrationDetailProps) => {
    const theme = useMantineTheme();
    const CRUISE_ID = localStorage.getItem("cruise");
    const [tableNumber, setTableNumber] =useState<string | null>(String(detail.tableNumber));
    const {data, isLoading} = useGetSeatSchemaQuery({restaurantGuid:detail.restaurant.id, cruiseId: CRUISE_ID});
    const [updatePassengerTables, {isLoading: isUpdatePassengerTablesLoading}] = useUpdatePassengerTablesMutation();
    const [prevTableNumber, setPrevTableNumber] = useState<string | null>("");

    const handleUpdateTable = async () => {
        await updatePassengerTables({
            passengerId: Number(detail.passengerId),
            restaurantGuid: detail.restaurant.id,
            tableNumber: Number(tableNumber)
        }).unwrap();
        onClose();
    };


    useEffect(() => {
        setPrevTableNumber(tableNumber);
        const table = data?.tables.find((el: ITables) => el.number === Number(tableNumber));
        if (table) {
            getGuestRect(table, 1, setTableNumber, detail.tableNumber);
        }

    }, [tableNumber]);


    useEffect(() => {
        const table = data?.tables.find((el: ITables) => el.number === Number(prevTableNumber));
        if (table) {
            getGuestRect(table, 0,  setTableNumber, detail.tableNumber);
        }
    }, [tableNumber]);

    return (
        <Drawer
            className={style.drawerRegistration}
            overlayColor={theme.colorScheme === 'dark' ? theme.colors.dark[9] : theme.colors.gray[2]}
            overlayOpacity={0.55}
            overlayBlur={3}
            position="right"
            onClose={onClose}
            title={detail.restaurant.name}
            size={424}
            {...props}
        >
            <LoadingOverlay visible={isUpdatePassengerTablesLoading}/>
            <Group direction='column' spacing={30} mb={42}>
                <Group direction='column' spacing={"md"}>
                    <Group direction='column' spacing={4}>
                        <Text size={"md"} weight={400} color={"var(--shadowGray)"}>ФИО</Text>
                        <Text size={"md"} weight={600} color={"var(--darkBlue)"}>{detail.passengerFullName}</Text>
                    </Group>
                    <Group direction='column' spacing={4}>
                        <Text size={"md"} weight={400} color={"var(--shadowGray)"}>Забронированный стол</Text>
                        <Text size={"md"} weight={600} color={"var(--darkBlue)"}>{detail.tableNumber}</Text>
                    </Group>
                </Group>
                <Select
                    rightSection={<ArrowIcon/>}
                    styles={{rightSection: {pointerEvents: 'none'}}}
                    className={style.drawerRegistrationEditTable}
                    value={tableNumber}
                    onChange={(e)=> setTableNumber(e)}
                    label="Изменить стол"
                    data={restaurant[0]?.tableNumbers.map((t: number) => String(t)).sort((a:string,b:string)=> parseInt(a) - parseInt(b))}
                />
            </Group>
            <div className={style.scheme}>
                {isLoading
                    ? <Loader size="sm" variant="dots" style={{width: 50}}/>
                    : <AddPassengerSchemes tables={data || []}
                                           svg={data?.schemaSvg}
                                           setTableNumber={setTableNumber}
                                           currentNumber={detail.tableNumber}
                    />
                }
            </div>
            <Group className={style.drawerRegistrationFooter} noWrap grow direction="row" position="right">
                <ButtonCustom appearance="ghost" onClick={onClose}>
                    Отмена
                </ButtonCustom>
                <ButtonCustom appearance="secondary" onClick={handleUpdateTable}>
                    Изменить
                </ButtonCustom>
            </Group>
        </Drawer>
    );
};

export default RegistrationDetail;
