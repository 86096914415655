import React from 'react';
import {ModalCustom} from "../../../../../common";
import {Group, Input, InputWrapper} from "@mantine/core";
import {AddTabMenuProps} from "./AddTabMenu.props";
import {ButtonCustom} from "../../../../../components";

const AddTabRestaurant = ({setTabName, handleAddTab, opened, onClose, ...props}: AddTabMenuProps) =>  (
        <ModalCustom
            opened={opened}
            onClose={onClose}
            styles={{
                modal: {width: 590, height: "auto"},
            }}
            title="Добавить вкладку"
            {...props}
        >
            <InputWrapper label="Название" style={{width: "100%"}}>
                <Input  variant="filled" placeholder="Ужин" onChange={(e: any) => setTabName(e.target.value)}/>
            </InputWrapper>
            <Group position="right" mt="md">
                <ButtonCustom width={"sm"} appearance="ghost" onClick={onClose}>
                    Отмена
                </ButtonCustom>
                <ButtonCustom  width={"md"} appearance="secondary" onClick={ () => {
                    onClose()
                    handleAddTab()
                }}>
                    Сохранить
                </ButtonCustom>
            </Group>
        </ModalCustom>
    );

export default AddTabRestaurant;