import React, {useEffect, useState} from 'react';
import style from "./RequestList.module.css";
import {CloseButton, Group, Menu, Textarea} from "@mantine/core";
import {BrandHipchat, Check, Eye} from "tabler-icons-react";
import {ModalCustom} from "../../../common";
import {RequestType} from "../../../types/IRequest";
import RequestDetail from "../RequestDetail/RequestDetail";
import {ButtonCustom} from "../../../components";
import AddComment from "./AddComment";
import {STATUS} from "../../../constants/CONSTANT";

export interface IRequestBody {
    request: RequestType
    declineRequest: (model: { requestId: string, reason: string }) => void
    updateRequest: (model: { requestId: string, statusId: string }) => void
    addComment: (model: { requestId: string, comment: string }) => void
    refetch: () => void;
}

const RequestBody = ({request, updateRequest, declineRequest, addComment, refetch}: IRequestBody) => {
    const [opened, setOpened] = useState(false);
    const [openedAddModal, setOpenedAddModal] = useState(false);
    const [openDeclineModal, setOpenDeclineModal] = useState<boolean>(false);
    const [reason, setReason] = useState<string>(request.comment ? request.comment : "");

    const handleDeclineRequest = () => {
        setOpenDeclineModal(false);
        declineRequest({
            requestId: request.guid,
            reason
        });
    };

    const saunaEntry = "a2eed10c-4ff7-4bc8-b038-9e5b1d531b5c";
    const handleConfirmRequest = () => {
        updateRequest({
            requestId: request.guid,
            statusId:  request.requestType.id === saunaEntry ? STATUS.Confirmed :  STATUS.Processed
        });
    };

    useEffect(() => {
        setReason(request.comment ? request.comment : "");
    }, [request.comment]);

    return (
        <tr className={style.applicationListTbodyRow} style={request.requestStatus.id == STATUS.Overdue ? {backgroundColor: "#FFE4E4"} : {}}>
            {opened && (
                <RequestDetail guid={request.guid}
                               onClose={() => {setOpened(false); refetch()}}
                               opened={opened}
                               openDeclineModal={() => setOpenDeclineModal(true)}
                               confirmRequest={handleConfirmRequest}
                />
            )}
            <ModalCustom
                className={style.modalCustomTitle}
                centered
                padding={16}
                radius={"md"}
                size={492}
                opened={openDeclineModal}
                onClose={() => setOpenDeclineModal(false)}
                title="Причина отклонения"
            >
                <Textarea
                    className={style.modalCustomTextarea}
                    placeholder="Комментарий"
                    minRows={6}
                    radius={8}
                    label="Комментарий"
                    value={reason}
                    onChange={(e) => setReason(e.currentTarget.value)}
                />
                <Group noWrap direction="row" position="right" style={{marginTop: 60}}>
                    <ButtonCustom width={"sm"} appearance="ghost" onClick={() => setOpenDeclineModal(false)}>
                        Отмена
                    </ButtonCustom>
                    <ButtonCustom width={"md"} appearance="secondary" onClick={handleDeclineRequest}>
                        Отклонить
                    </ButtonCustom>
                </Group>
            </ModalCustom>
            <AddComment onClose={() => setOpenedAddModal(false)}
                        opened={openedAddModal}
                        requestId={request.guid}
                        addComment={addComment}
            />
            <td>{request.stateroom}</td>
            <td>{request.passengerFullName}</td>
            <td>{request.phone}</td>
            <td>{request.requestType.title}</td>
            <td>{request.request}</td>
            <td>{request?.discountPercentage ? request?.discountPercentage + '%' : '-'}</td>
            <td>{new Date(request.date).toLocaleString("ru", {
                year: 'numeric',
                month: 'numeric',
                day: 'numeric',
                hour: 'numeric',
                minute: 'numeric'
            })}</td>
            <td>{request.requestStatus.title}</td>
            <td>
                <Menu position={'left'} trigger="hover" className={style.applicationListTbodyImg}>
                    <Menu.Item icon={<Eye size={16}/>} onClick={() => setOpened(true)}>Посмотреть
                        заявку</Menu.Item>
                    <Menu.Item icon={<BrandHipchat size={16}/>}
                               onClick={() => setOpenedAddModal(true)}
                    >
                        Добавить комментарий
                    </Menu.Item>
                    {(request.requestStatus.title === "Новая" || request.requestStatus.title === "Просрочена") && (
                        <>
                            <Menu.Item icon={<Check size={16}/>}
                                       onClick={handleConfirmRequest}
                            >
                                Подтвердить
                            </Menu.Item>
                            <Menu.Item color='red'
                                       icon={<CloseButton size={16} color='red'/>}
                                       onClick={() => setOpenDeclineModal(true)}
                            >
                                Отклонить
                            </Menu.Item>
                        </>
                    )}
                </Menu>
            </td>
        </tr>
    );
};

export default RequestBody;