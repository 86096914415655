import React from 'react';
import style from './Notification.module.css';
import {Text} from "@mantine/core";
import {NotificationsProps} from "./Notifications.props";

const Notifications = ({notificationsTitle, notificationsCount, ...props}: NotificationsProps): JSX.Element => (
        <div className={style.notification} {...props}>
            <Text className={style.notificationText} component="span" weight={400} size="xs">
                {notificationsTitle}
            </Text>
            <Text component="span" weight={400} size="xs" className={style.notificationCount}>
                {notificationsCount}
            </Text>
        </div>
    );

export default Notifications;
