import {BaseQueryFn, createApi, FetchArgs, fetchBaseQuery, FetchBaseQueryError} from '@reduxjs/toolkit/query/react';
import {BASE_API_URL} from "../../configs/api.config";
import {IChatPassenger} from "../../types/IChat";

const baseQuery = fetchBaseQuery({
    baseUrl: BASE_API_URL,
    prepareHeaders: (headers ) => {
        const token = localStorage.getItem("token");

        if (token) {
            headers.set('X-SOZVEZDIE-AUTH-TOKEN', `${token}`);
        }

        return headers;
    },
});

const baseQueryWithReauth: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  const result = await baseQuery(args, api, extraOptions);
  if (result.error && result.error.status === 401) {
    localStorage.clear();
    window.location.href = "/";
    }
  return result;
};

export const chatSlice = createApi({
    reducerPath: "chat",
    baseQuery: baseQueryWithReauth,
    endpoints(builder) {
        return {
            getChatPassengers: builder.query<IChatPassenger[], string | null>({
                query: (cruiseId) => `api/RocketChat/GetChatPassengers?cruiseId=${cruiseId}`,
            }),
        };
    }
});

export const {
    useGetChatPassengersQuery,
} = chatSlice;