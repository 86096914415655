import React from 'react';
import {useMantineTheme, Modal, Group} from '@mantine/core';
import {ModalCustomProps} from "./_ModalCustomProps.props";
import {ButtonCustom} from "../../components";
import style from "./_Model.module.css";

export const ModalCustom = ({onClick, onClose, groupClassName, isShowButton, buttonTitle, children, ...props}: ModalCustomProps) => {

    const theme = useMantineTheme();

    return(
        <Modal
            className={style.headerModal}
            overlayColor={theme.colorScheme === 'dark' ? theme.colors.dark[9] : theme.colors.gray[2]}
            overlayOpacity={0.55}
            overlayBlur={3}
            onClose={onClose}
            {...props}
        >
            {children}
            {isShowButton && (
                <Group className={groupClassName} noWrap direction="row" position="right" style={{bottom: 0}}>
                    <ButtonCustom width={"sm"} appearance="ghost" onClick={onClose}>
                        Отмена
                    </ButtonCustom>
                    <ButtonCustom width={"md"} appearance="secondary">
                        {buttonTitle}
                    </ButtonCustom>
                </Group>
            )}
        </Modal>
    );
};
