import React from 'react';
import {Group, Skeleton} from "@mantine/core";
import style from "../_Chat.module.css";

const ChatSkeletonPassenger = () => {
    return (
        <Group direction="column" spacing={4} className={style.sidebarChatWrapItem}>
            <Group direction="row" spacing={0} >
                <Skeleton height={15} width={100} mr={100}/>
                <Skeleton height={15} width={100}/>
            </Group>
            <Skeleton height={25} width={150} />
            <Group direction="row" className={style.sidebarChatDownRow}>
                <Skeleton height={10} mr={40}/>
                <Skeleton circle height={10} width={10}/>
            </Group>
        </Group>
    );
};

export default ChatSkeletonPassenger;