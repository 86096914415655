import React from 'react';
import cn from 'classnames';
import {ButtonProps} from "./_Button.props";
import style from "./_Button.module.css";
import {Button} from "@mantine/core";

export const ButtonCustom = ({appearance, onClick, width, children, className, disabled = false, ...props}: ButtonProps): JSX.Element => {
    return (
        <Button
            className={cn(className, style.button, {
                [style.primary]: appearance === "primary",
                [style.ghost]: appearance === "ghost",
                [style.secondary]: appearance === "secondary",
                [style.orange]: appearance === "orange",
                [style.sm]: width === "sm",
                [style.md]: width === "md",
                [style.lg]: width === "lg",
            })}
            onClick={onClick}
            {...props}
            disabled={disabled}
        >
            {children}
        </Button>
    );
};

