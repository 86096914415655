import React, {useState} from 'react';
import {FilterDialog} from "../../../common";
import {RegistrationFiltersProps} from "./RegistrationFilters.props";
import {Group, Select} from "@mantine/core";
import {ButtonCustom} from "../../../components";
import style from "./RegistrationFilters.module.css";
import {ReactComponent as ArrowIcon} from "../../../assets/svg/arrow.svg";

const RegistrationFilters = ({setPage, setFilters, restaurant, onClose, ...props}: RegistrationFiltersProps) => {

    const [tableNumberData, setTableNumbetData] = useState<string[]>([]);
    const restarauntData = restaurant.filter(r => !r.isBar).map(r => ({value: String(r.id), label: r.name}));
    const [tableNumber, setTableNumber] = useState<string | null>(null);
    const [restarauntGuid, setRestarauntId] = useState<string | null>(null);

    const CRUISE_ID = localStorage.getItem("cruise");

    const handleSubmit = () => {
        if (onClose) {
            onClose();
        }

        setPage(1);
        const filter: { [key: string] : string | null} = {
            cruiseExternalId: CRUISE_ID,
            restarauntGuid,
            tableNumber,
        };

        Object.keys(filter).forEach(key => {
            if (filter[key] === null) {
                delete filter[key];
            }
        });

        setFilters(filter);
    };

    const reset = () => {
        setRestarauntId(null);
        setTableNumber(null);
    };

    const handleSetRestarauntId = (value: string | null) => {
        setRestarauntId(value);
        const tables = restaurant.filter(r => String(r.id) === value);
        setTableNumbetData(tables[0]?.tableNumbers.map(t => String(t)).sort((a:string,b:string)=> parseInt(a) - parseInt(b)));
    };

    return (
        <FilterDialog {...props} onClose={onClose} p={24} size={1120} className={style.registrationFilter}>
            <Group grow className={style.registrationFilterGroup}>
                <Select
                    className={style.registrationFilterSelectRest}
                    rightSection={<ArrowIcon/>}
                    styles={{rightSection: {pointerEvents: 'none'}}}
                    placeholder='Выберите ресторан'
                    label="Ресторан"
                    data={restarauntData}
                    value={restarauntGuid}
                    onChange={handleSetRestarauntId}
                />
                <Select
                    rightSection={<ArrowIcon/>}
                    styles={{rightSection: {pointerEvents: 'none'}}}
                    className={style.registrationFilterSelectTable}
                    disabled={tableNumberData.length === 0 || !restarauntGuid}
                    placeholder='Выберите номер столика'
                    label="Номер столика"
                    value={tableNumber}
                    data={tableNumberData}
                    onChange={setTableNumber}
                />
            </Group>
            <Group noWrap direction="row" position="right">
                <ButtonCustom width="md" appearance="ghost" onClick={reset}>
                    Очистить
                </ButtonCustom>
                <ButtonCustom width="md" appearance="primary" onClick={handleSubmit}>
                    Применить
                </ButtonCustom>
            </Group>
        </FilterDialog>
    );
};

export default RegistrationFilters;
