import {BaseQueryFn, createApi, FetchArgs, fetchBaseQuery, FetchBaseQueryError} from '@reduxjs/toolkit/query/react';
import {BASE_API_URL} from "../../configs/api.config";
import {ISauna, ISaunaPeriod} from "../../types/ISauna";

const baseQuery = fetchBaseQuery({
    baseUrl: BASE_API_URL,
    prepareHeaders: (headers ) => {
        const token = localStorage.getItem("token");

        if (token) {
            headers.set('X-SOZVEZDIE-AUTH-TOKEN', `${token}`);
        }

        return headers;
    },
});

const baseQueryWithReauth: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  const result = await baseQuery(args, api, extraOptions);
  if (result.error && result.error.status === 401) {
    localStorage.clear();
    window.location.href = "/";
    }
  return result;
};

export const saunaSlice = createApi({
    reducerPath: "sauna",
    baseQuery: baseQueryWithReauth,
    tagTypes: ['Sauna'],
    endpoints(builder) {
        return {
            getSaunaOrders: builder.query<ISauna,any>({
                query: (body) =>  ({
                    url: `api/Sauna/GetOrders`,
                    method: 'POST',
                    body
                }),
                providesTags: (result) =>
                    result
                        ?
                        [
                            ...result.models.map(({ passengerReuqestId }) => ({ type: 'Sauna', passengerReuqestId } as const)),
                            { type: 'Sauna', passengerReuqestId: 'LIST' },
                        ]
                        :
                        [{ type: 'Sauna', passengerReuqestId: 'LIST' }],
            }),
            addSaunaOrders: builder.mutation({
                query: (body) => ({
                    url: `/api/Sauna/AddOrder`,
                    method: 'POST',
                    body
                }),
                invalidatesTags: ['Sauna']
            }),
            getPeriodsByCruiseId: builder.query<ISaunaPeriod, { cruiseId: string | null,  date: string | null} >({
                query: ({cruiseId, date}) =>  `/api/Sauna/GetPeriodsByCruiseId?cruiseId=${cruiseId}&date=${date}`,
            }),

            declineSaunaRequest: builder.mutation<string , { requestId: string, reason: string }>({
                query: (body) => ({
                    url: `/api/PassengerRequest/DeclineRequest`,
                    method: 'POST',
                    body,
                }),
                invalidatesTags: ['Sauna']
            }),
            updateSaunaStatus: builder.mutation<string , { requestId: string, statusId: string | null }>({
                query: (body) => ({
                    url: `/api/PassengerRequest/UpdateRequestStatus`,
                    method: 'POST',
                    body,
                }),
                invalidatesTags: ['Sauna']
            }),
            getNewSaunaCount: builder.query<{ newRequestCount: number }, string>({
                query: (cruiseId) => ({
                    url: `/api/PassengerRequest/GetNewRequestSaunaCount/${cruiseId}`,
                    method: "GET",
                }),
            }),
        };
    }
});

export const {
    useGetSaunaOrdersQuery,
    useAddSaunaOrdersMutation,
    useUpdateSaunaStatusMutation,
    useDeclineSaunaRequestMutation,
    useGetPeriodsByCruiseIdQuery,
    useGetNewSaunaCountQuery
} = saunaSlice;