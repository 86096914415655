import React, { useEffect, useState } from "react";
import { Select, Group } from "@mantine/core";
import { RequestFilterProps } from "./RequestFilter.props";
import { FilterDialog } from "../../../common";
import { ButtonCustom } from "../../../components";
import { DatePicker } from "@mantine/dates";
import style from "./RequestFilter.module.css";
import { CalendarEvent } from "tabler-icons-react";
import { ReactComponent as ArrowIcon } from "../../../assets/svg/arrow.svg";
import {
  useGetAllStatusesQuery,
  useGetAllTypesQuery,
} from "../../../redux/apiSlice/_requestSlice";
import dayjs from "dayjs";
import { REQUEST_DATE_TEMPLATE } from "../../../constants/CONSTANT";
import { useGetPassengerFullNamesQuery } from "../../../redux/apiSlice/_registrationSlice";

const RequestFilter = ({
  setPage,
  setSearch,
  onClose,
  form,
  ...props
}: RequestFilterProps) => {
  const CRUISE_ID = localStorage.getItem("cruise");
  const [query, setQuery] = useState("");
  const [filterQuery, setFilterQuery] = useState<string | null>(null);

  const { data: allTypes = [] } = useGetAllTypesQuery(null);
  const { data: allStatuses = [] } = useGetAllStatusesQuery(null);
  const { data: passengerFullNames = [] } = useGetPassengerFullNamesQuery({
    cruiseId: Number(CRUISE_ID),
    searchPattern: filterQuery,
  });

  const types = allTypes.map((type: { id: string; title: string }) => ({
    value: String(type.id),
    label: type.title,
  }));
  const statuses = allStatuses.map((status: { id: string; title: string }) => ({
    value: String(status.id),
    label: status.title,
  }));
  const passengerData = passengerFullNames.map((p: any) => ({
    value: String(p.fullName),
    label: p.fullName,
  }));
  const filteredPassengerData = passengerData.filter(
    (d: { value: string; label: string }, index: number) => {
      for (let i = 0; i < index; i++) {
        if (passengerData[i].value == d.value) return false;
      }
      return true;
    }
  );

  useEffect(() => {
    setFilterQuery(query);
  }, [query]);

  return (
    <FilterDialog {...props} size={1300} className={style.applicationFilter}>
      <form
        onKeyDown={(e) => {
          if (e.key == "Enter") {
            e.preventDefault();
          }
        }}
        onSubmit={form.onSubmit((values) => {
          setPage(1);
          setSearch({
            ...values,
            startDate: values.startDate
              ? dayjs(values.startDate).format(REQUEST_DATE_TEMPLATE)
              : null,
            endDate: values.endDate
              ? dayjs(values.endDate).format(REQUEST_DATE_TEMPLATE)
              : null,
          });
        })}
      >
        <Group grow className={style.applicationFilterGroup}>
          <Group
            className={style.applicationFilterDatePickerWrap}
            grow
            noWrap
            align={"flex-end"}
            direction="row"
            spacing={32}
          >
            <DatePicker
              variant="filled"
              rightSection={
                <CalendarEvent
                  size={24}
                  strokeWidth={2}
                  color={"var(--darkBlue)"}
                />
              }
              allowFreeInput
              inputFormat="DD.MM.YYYY"
              styles={{ rightSection: { pointerEvents: "none" } }}
              placeholder={dayjs(new Date()).format("DD.MM.YYYY")}
              locale="ru"
              label="Дата"
              {...form.getInputProps("startDate")}
            />
            <DatePicker
              variant="filled"
              rightSection={
                <CalendarEvent
                  size={24}
                  strokeWidth={2}
                  color={"var(--darkBlue)"}
                />
              }
              allowFreeInput
              inputFormat="DD.MM.YYYY"
              styles={{ rightSection: { pointerEvents: "none" } }}
              placeholder={dayjs(new Date()).format("DD.MM.YYYY")}
              locale="ru"
              {...form.getInputProps("endDate")}
            />
          </Group>
          <Select
            rightSection={<ArrowIcon />}
            variant="filled"
            styles={{ rightSection: { pointerEvents: "none" } }}
            placeholder="Выберите тип обращения"
            label="Тип обращения"
            data={types}
            {...form.getInputProps("typeId")}
          />
          <Select
            rightSection={<ArrowIcon />}
            variant="filled"
            styles={{ rightSection: { pointerEvents: "none" } }}
            placeholder="Выберите статус"
            label="Статус"
            data={statuses}
            {...form.getInputProps("statusId")}
          />
          <Select
            rightSection={<ArrowIcon />}
            styles={{ rightSection: { pointerEvents: "none" } }}
            searchable
            onSearchChange={setQuery}
            onKeyDown={(e) => {
              if (e.key == "Enter") {
                setFilterQuery(query);
              }
            }}
            onDropdownOpen={() => {
              if (!form.getInputProps("passengerFullName").value && filterQuery) {
                setFilterQuery(null);
              }
            }}
            maxDropdownHeight={400}
            variant="filled"
            label="ФИО"
            placeholder="Выберите ФИО"
            data={filterQuery ? filteredPassengerData : []}
            {...form.getInputProps("passengerFullName")}
          />
        </Group>
        <Group
          className={style.applicationFilterBtnWrap}
          noWrap
          direction="row"
          position="right"
        >
          <ButtonCustom width="md" appearance="ghost" onClick={form.reset}>
            Очистить
          </ButtonCustom>
          <ButtonCustom
            width="md"
            appearance="primary"
            type="submit"
            onClick={onClose}
          >
            Применить
          </ButtonCustom>
        </Group>
      </form>
    </FilterDialog>
  );
};

export default RequestFilter;
