import React, {useState} from 'react';
import style from "./_Sauna.module.css";
import {CloseButton, Group, Loader, Menu, Textarea} from "@mantine/core";
import {Check} from "tabler-icons-react";
import { ISaunaModels} from "../../types/ISauna";
import {ModalCustom} from "../../common";
import {ButtonCustom} from "../../components";
import {useDeclineSaunaRequestMutation, useUpdateSaunaStatusMutation} from "../../redux/apiSlice/_saunaSlice";

export interface ISaunaList {
    passenger: ISaunaModels
}

const SaunaList = ({passenger}: ISaunaList) => {

    const [openDeclineModal, setOpenDeclineModal] = useState<boolean>(false);
    const [reason, setReason] = useState<string>("");

    const [declineRequest, {isLoading: isDeclineLoading}] = useDeclineSaunaRequestMutation();
    const [updateRequest, {isLoading: isUpdateLoading}] = useUpdateSaunaStatusMutation();

    const handleDeclineRequest = async () => {
        setOpenDeclineModal(false);
        await declineRequest({
            requestId: passenger.passengerReuqestId,
            reason
        });
    };

    const handleUpdateRequest = async () => {
        await updateRequest({
            requestId: passenger.passengerReuqestId,
            statusId: "790ecbdf-aefe-4c4d-a2eb-5123530b5734"
        });
    };

    return (
        <tr className={style.saunaTableTbodyRow}>
            {openDeclineModal && (
                <ModalCustom
                    className={style.modalCustomTitle}
                    centered
                    padding={16}
                    radius={"md"}
                    size={492}
                    opened={openDeclineModal}
                    onClose={() => setOpenDeclineModal(false)}
                    title="Причина отклонения"
                >
                    <Textarea
                        className={style.modalCustomTextarea}
                        placeholder="Комментарий"
                        minRows={6}
                        radius={8}
                        label="Комментарий"
                        value={reason}
                        onChange={(e) => setReason(e.currentTarget.value)}
                    />
                    <Group noWrap direction="row" position="right" style={{marginTop: 20}}>
                        <ButtonCustom width={"sm"} appearance="ghost" onClick={() => setOpenDeclineModal(false)}>
                            Отмена
                        </ButtonCustom>
                        <ButtonCustom width={"md"} appearance="secondary" onClick={handleDeclineRequest}>
                            Отклонить
                        </ButtonCustom>
                    </Group>
                </ModalCustom>
            )}
            <td>{passenger.passengerFullName}</td>
            <td>{passenger.passengerCabinNumber}</td>
            <td>{passenger.passengerNumber}</td>
            <td>{passenger?.discountPercentage ? passenger?.discountPercentage + '%' : '-'}</td>
            <td>{passenger.period}</td>
            <td>
                {isUpdateLoading || isDeclineLoading
                    ? <Loader size="sm" variant="dots"/>
                    : passenger.status
                }
            </td>
            {(passenger.status === "Новая" || passenger.status === "Просрочена") ?
                <td>
                    <Menu position={'left'} className={style.saunaTableTbodyImg}>
                        <Menu.Item icon={<Check size={24}/>}
                                   onClick={handleUpdateRequest}
                        >
                            Подтвердить
                        </Menu.Item>
                        <Menu.Item icon={<CloseButton size={24} color='red'/>}
                                   color='red'
                                   onClick={() => setOpenDeclineModal(true)}
                        >
                            Отклонить
                        </Menu.Item>
                    </Menu>
                </td> : <td></td>
            }
        </tr>
    );
};

export default SaunaList;