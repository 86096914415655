import {BaseQueryFn, createApi, FetchArgs, fetchBaseQuery, FetchBaseQueryError} from '@reduxjs/toolkit/query/react';
import {ExcursionData, ExcursionFilterType, ExcursionModel} from "../../types/IExcursion";
import {BASE_API_URL} from "../../configs/api.config";

const baseQuery = fetchBaseQuery({
    baseUrl: BASE_API_URL,
    prepareHeaders: (headers ) => {
        const token = localStorage.getItem("token");

        if (token) {
            headers.set('X-SOZVEZDIE-AUTH-TOKEN', `${token}`);
        }

        return headers;
    },
});

const baseQueryWithReauth: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  const result = await baseQuery(args, api, extraOptions);
  if (result.error && result.error.status === 401) {
    localStorage.clear();
    window.location.href = "/";
    }
  return result;
};

export const excursionSlice = createApi({
    reducerPath: "excursion",
    baseQuery: baseQueryWithReauth,
    tagTypes: ['Excursion'],
    endpoints(builder) {
        return {
            fetchExcursion: builder.query<ExcursionData, ExcursionModel<ExcursionFilterType> | string>({
                query: (body) => ({
                    url: `/api/Excursion/GetOrders`,
                    method: 'POST',
                    body,
                }),
                providesTags: (result) =>
                    result
                        ?
                        [
                            ...result.models.map(({ passengerReuqestId }) => ({ type: 'Excursion', passengerReuqestId } as const)),
                            { type: 'Excursion', passengerReuqestId: 'LIST' },
                        ]
                        :
                        [{ type: 'Excursion', passengerReuqestId: 'LIST' }],
            }),
            getAllExcursions: builder.query({
                query: (cityId) => ({
                    url: `/api/Excursion/GetExcursionsByCityIdAndSearchPattern/${cityId}`,
                }),
            }),
            getNewExcursionCount: builder.query<{ newRequestCount: number }, string>({
                query: (cruiseId) => ({
                  url: `/api/PassengerRequest/GetNewRequestExcursionsCount/${cruiseId}`,
                }),
            }),
            declineExcursionRequest: builder.mutation<
                string,
                { requestId: string; reason: string }
            >({
                query: (body) => ({
                    url: `/api/PassengerRequest/DeclineRequest`,
                    method: "POST",
                    body,
                }),
                invalidatesTags: ["Excursion"],
            }),
            updateExcursionStatus: builder.mutation<
                string,
                { requestId: string; statusId: string | null }
            >({
                query: (body) => ({
                    url: `/api/PassengerRequest/UpdateRequestStatus`,
                    method: "POST",
                    body,
                }),
                invalidatesTags: ["Excursion"],
            }),
            addExcursionComment: builder.mutation<
                string,
                { requestId: string; comment: string | null }
            >({
                query: (body) => ({
                    url: `/api/PassengerRequest/AddCommentToRequest`,
                    method: "POST",
                    body,
                }),
                invalidatesTags: ["Excursion"],
            }),
        };
    }
});

export const {
    useFetchExcursionQuery,
    useGetAllExcursionsQuery,
    useGetNewExcursionCountQuery,
    useUpdateExcursionStatusMutation,
    useAddExcursionCommentMutation,
    useDeclineExcursionRequestMutation
} = excursionSlice;