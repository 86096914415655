import {BaseQueryFn, createApi, FetchArgs, fetchBaseQuery, FetchBaseQueryError} from '@reduxjs/toolkit/query/react';
import {
    IAddPassenger,
    ISchemes,
    RegistrationData,
    RegistrationFilterType,
    RegistrationRestaurantType
} from "../../types/IRegistration";
import {RequestModel} from "../../types/IRequest";
import {BASE_API_URL} from "../../configs/api.config";

const baseQuery = fetchBaseQuery({
    baseUrl: BASE_API_URL,
    prepareHeaders: (headers ) => {
        const token = localStorage.getItem("token");

        if (token) {
            headers.set('X-SOZVEZDIE-AUTH-TOKEN', `${token}`);
        }

        return headers;
    },
});

const baseQueryWithReauth: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  const result = await baseQuery(args, api, extraOptions);
  if (result.error && result.error.status === 401) {
    localStorage.clear();
    window.location.href = "/";
    }
  return result;
};

export const registrationSlice = createApi({
    reducerPath: "registration",
    baseQuery: baseQueryWithReauth,
    tagTypes: ['Table', 'Passenger', 'Restaurant'],
    endpoints(builder) {
        return {
            fetchRegistration: builder.query<RegistrationData, RequestModel<RegistrationFilterType> | string>({
                query: (body) => ({
                    url: `/api/RegistrationRestaurant/GetAllRegistration`,
                    method: 'POST',
                    body,
                }),
                providesTags: (result) =>
                    result
                        ?
                        [
                            ...result.models.map(({ reservationPassengerId }) => ({ type: 'Passenger', reservationPassengerId } as const)),
                            { type: 'Passenger', guid: 'LIST' },
                        ]
                        :
                        [{ type: 'Passenger', guid: 'LIST' }],
            }),

            getRegistrationRestaurant:  builder.query<RegistrationRestaurantType, number>({
                query: (shipId) => `/api/RegistrationRestaurant/GetRestaurants/${shipId}`,
                providesTags: (result) =>
                    result
                        ?
                        [
                            ...result.map(({ id }) => ({ type: 'Restaurant', id } as const)),
                            { type: 'Restaurant', guid: 'LIST' },
                        ]
                        :
                        [{ type: 'Restaurant', guid: 'LIST' }],
            }),

            getSeatSchema:  builder.query<ISchemes, any>({
                query: ({restaurantGuid, cruiseId}) => {
                    return `/api/Restaurant/GetSeatSchema/${restaurantGuid}/${cruiseId}`
                },
                providesTags: (result) =>
                    result
                        ?
                        [
                            ...result.tables.map(({ guid }) => ({ type: 'Table', guid } as const)),
                            { type: 'Table', guid: 'LIST' },
                        ]
                        :
                        [{ type: 'Table', guid: 'LIST' }],
            }),

            addReservationPassenger: builder.mutation({
                query: (body) => ({
                    url: `/api/RegistrationRestaurant/Add`,
                    method: 'POST',
                    body,
                }),
                invalidatesTags: ['Passenger']
            }),

            addTable: builder.mutation({
                query: (body) => ({
                    url: `/api/RegistrationRestaurant/AddTables`,
                    method: 'POST',
                    body,
                }),
                invalidatesTags: ['Table']
            }),
            updateTables: builder.mutation({
                query: (body) => ({
                    url: `/api/RegistrationRestaurant/UpdateTables`,
                    method: 'POST',
                    body,
                }),
                invalidatesTags: ['Table']
            }),
            updatePassengerTables: builder.mutation({
                query: (body) => ({
                    url: `/api/RegistrationRestaurant/Update`,
                    method: 'POST',
                    body,
                }),
                invalidatesTags: ['Passenger', 'Table']
            }),
            getPassengerFullNames: builder.query({
                query: (body) => ({
                    url: `/api/RegistrationRestaurant/GetPassengersInfoByCruiseId?cruiseId=${body.cruiseId}&searchPattern=${body.searchPattern}`,
                    method: 'POST',
                    body,
                }),
            }),
            getPassengerReservations: builder.query({
                query: ({cruiseId, fullName}) => ({
                    url: `/api/RegistrationRestaurant/GetPassengerReservationsByCruiseIdAndFullName?cruiseId=${cruiseId}&fullname=${fullName}`,
                    method: 'POST',
                }),
            }),
            deletePassenger: builder.mutation({
                query: ({tableGuid, reservationPassengerGuid}) => ({
                    url: `/api/RegistrationRestaurant/Delete/${tableGuid}/${reservationPassengerGuid}`,
                    method: 'DELETE',
                }),
                invalidatesTags: ['Table', 'Passenger']
            }),

            addPassenger: builder.mutation<any, IAddPassenger>({
                query: (body) => ({
                    url: `/api/RegistrationRestaurant/Add`,
                    method: 'POST',
                    body
                }),
                invalidatesTags: ['Table', 'Passenger']
            }),
        };
    }
});

export const {
    useFetchRegistrationQuery,
    useGetRegistrationRestaurantQuery,
    useGetSeatSchemaQuery,
    useGetPassengerFullNamesQuery,
    useAddTableMutation,
    useUpdateTablesMutation,
    useDeletePassengerMutation,
    useUpdatePassengerTablesMutation,
    useGetPassengerReservationsQuery,
    useAddPassengerMutation,
} = registrationSlice;