import React, { useState } from "react";
import cn from "classnames";
import { ModalCustom } from "../../../../common";
import {
  ActionIcon,
  Autocomplete,
  Group,
  Input,
  InputWrapper,
} from "@mantine/core";
import { AddToMenuProps } from "./AddToMenu.props";
import { Trash } from "tabler-icons-react";
import { ButtonCustom } from "../../../../components";
import style from "./AddMenu.module.css";
import { formList, useForm } from "@mantine/form";
import { ReactComponent as AddIcon } from "../../../../assets/svg/add.svg";
import {
  useAddDishesMutation,
  useGetAllDishesQuery,
} from "../../../../redux/apiSlice/_restaurantSlice";

export interface IDishes {
  dishes: {
    name: string;
    id?: string | null;
  }[];
}

const AddToMenuBar = ({
  tabName,
  tabId,
  opened,
  onClose,
  ...props
}: AddToMenuProps) => {
  const [menu, setMenu] = useState(1);

  const dishes = {
    name: "",
    id: "",
  };

  const form = useForm({
    initialValues: {
      dishes: formList([dishes]),
    },
  });

  const [addDishes] = useAddDishesMutation();

  // const {data: allDishes = []} = useGetAllDishesQuery(null);
  // const filteredDishes = allDishes.filter((d: {name: string, id: string}) => !form.getInputProps("dishes").value.some((dish: {name: string, id: string}) => dish.id == d.id) && d.name);
  // const sortDishes = filteredDishes.filter((d: {name: string, id: string}, index) => {
  //     for (let i = 0; i < index; i++) {
  //         if (filteredDishes[i].name == d.name) return false
  //     }
  //     return true
  // })
  // const catalogDishes = sortDishes.map( (e: any) => ({value: String(e.name), label: e.name}));

  const handleAddDishes = async (madel: IDishes) => {
    onClose();
    form.reset();
    setMenu(1);
    madel.dishes = madel.dishes.map((d) => {
      return { id: d.id === "" ? null : d.id, name: d.name };
    });
    await addDishes({
      dishes: madel.dishes,
      restaurantTabId: tabId,
    }).unwrap();
    form.reset();
  };

  const AddDishesToMenu = () => {
    form.addListItem("dishes", dishes);
    setMenu((prev) => (prev += 1));
  };

  return (
    <ModalCustom
      opened={opened}
      onClose={() => {
        form.reset();
        setMenu(1);
        onClose();
      }}
      className={style.addMenuBarModal}
      size={820}
      title={`Добавить в меню "${tabName}"`}
      overflow="inside"
      {...props}
    >
      <form onSubmit={form.onSubmit(handleAddDishes)}>
        {[...new Array(menu)].map((item, index) => (
          <Group
            noWrap
            spacing="md"
            direction="row"
            mb="md"
            key={index}
            className={style.addMenuBarModalGroup}
          >
            {/* <Autocomplete
                            label="Описание"
                            className={style.addMenuBarModalName}
                            data={catalogDishes}
                            placeholder="Салат «Цезарь»"
                            style={{width: "100%"}}
                            onItemSubmit={(item) => {
                                form.setListItem('dishes', index, {
                                    name: item.value,
                                    id: sortDishes.find((d: {name: string, id: string}) => item.value == d.name)!.id,
                                });
                            }}
                            limit={1000}
                            maxDropdownHeight={200}
                            {...form.getListInputProps('dishes', index, 'name')}
                        /> */}
            <InputWrapper
              className={style.addMenuBarModalName}
              style={{ width: "100%" }}
              label="Описание"
            >
              <Input
                variant="filled"
                placeholder="Салат «Цезарь"
                {...form.getListInputProps("dishes", index, "name")}
              />
            </InputWrapper>
            <ActionIcon
              onClick={() => {
                form.removeListItem("dishes", index);
                setMenu((prev) => (prev -= 1));
              }}
            >
              <Trash size={24} strokeWidth={1} color="var(--weekDayRed)" />
            </ActionIcon>
          </Group>
        ))}
        <Group position="right" className={style.addMenuBarModalBtnAdd}>
          <ButtonCustom
            width="sm"
            appearance="ghost"
            leftIcon={<AddIcon />}
            onClick={AddDishesToMenu}
          >
            Добавить
          </ButtonCustom>
        </Group>
        <Group
          className={style.addMenuBarModalFooter}
          noWrap
          direction="row"
          position="right"
          style={{ bottom: 0 }}
        >
          <ButtonCustom
            width={"sm"}
            appearance="ghost"
            onClick={() => {
              onClose();
              form.reset();
              setMenu(1);
            }}
          >
            Отмена
          </ButtonCustom>

          <ButtonCustom
            type="submit"
            width={"md"}
            appearance="secondary"
            disabled={form
              .getInputProps("dishes")
              .value.some((v: any) => !v.name)}
          >
            Добавить
          </ButtonCustom>
        </Group>
      </form>
    </ModalCustom>
  );
};

export default AddToMenuBar;
