export const CRUISE_GUID = "5870c47a-75d0-4616-9b17-c8d30f43bffa";
export const REQUEST_DATE_TEMPLATE = "YYYY-MM-DDTHH:mm:ss.sss[Z]";


export enum STATUS {
    New = "c603f56e-3b2d-4db2-b1de-5b9daddf2ca3",
    In_work = "f3798a8d-5d01-44cd-9480-7f5627579011",
    Processed = "64ea54d7-2cf4-4f80-abc4-e1e357451412",
    Confirmed = "790ecbdf-aefe-4c4d-a2eb-5123530b5734",
    Rejected = "14c765a6-2310-45cb-b6de-1b50ff234cb5",
    Overdue = "e3438616-3c8a-4f87-80f2-2cd1b7fc5a1b",
}


export const STATUS_TITLE: { [key: string]: string } = {
    [STATUS.New]: "Новая",
    [STATUS.In_work]: "В работе",
    [STATUS.Processed]: "Обработана",
    [STATUS.Rejected]: "Отклонена",
    [STATUS.Confirmed]: "Подтвержена",
    [STATUS.Overdue]: "Просрочена"
};

export const DAYS_WEEK = ["Пн", "Вт", "Ср", "Чт", "Пт", "Сб", "Вс"];

export const REPLY_LIST = [
    {day: "Пн", isChecked: false},
    {day: "Вт", isChecked: false},
    {day: "Ср", isChecked: false},
    {day: "Чт", isChecked: false},
    {day: "Пт", isChecked: false},
    {day: "Сб", isChecked: false},
    {day: "Вс", isChecked: false},
];

export enum NOTIFICATION_REPLY {
    DAILY = "Ежедневно",
    ONCE = "Однократно",
    SELECT_DAY = "Выбрать дни"
}
