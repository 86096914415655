import React, {useEffect} from 'react';
import "./Schemes.css";
import parse from 'html-react-parser';
import {IActiveTable, ITables} from "../../../types/IRegistration";
import {drawSeats} from "./helpers/drawSeats";
import {clearNewRect, resetNotActivePaths} from "../../../helpers/helpers";

export interface ISchemes{
    tables: ITables[];
    svg?: string | null;
    setActiveTable: (activeTable: IActiveTable) => void
    setSeats: (seats: string ) => void
    setType: (type: string) => void
    tableNumber?: string
}


const schemeRendering = ({
                             tables,
                             setActiveTable,
                             setSeats,
                             setType,
                         }: ISchemes) => {

    const seats = document.getElementsByClassName("Seats");

    const rects = document.getElementsByClassName("rectNew")
    clearNewRect(rects, rects.length)

    resetNotActivePaths()

    for (let i = 1; i < seats.length + 1; i++) {

        const table = tables.find(t => t.number === i);

        if(table) {
            drawSeats({
                table,
                setActiveTable,
                setSeats,
                setType,
                guests:0,
            });
        } else {
            drawSeats({
                table: {
                    number: i,
                    numberOccupied: 0,
                    numberSeats: 0,
                    isCloseRegistration: ""
                },
                setActiveTable,
                setSeats,
                setType,
                guests:0,
            });
        }

    }
};

const Schemes = ({setType, svg, tables, setActiveTable, setSeats}: ISchemes) => {

    useEffect(()=> {
        schemeRendering({
            tables,
            setActiveTable,
            setSeats,
            setType
        });
    }, [tables]);

    return <>{svg ? parse(svg) : "Нет схемы"}</> ;
} ;



export default Schemes;