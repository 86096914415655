import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {ITables} from "../../types/IRegistration";



export interface IInitialState {
    tables: ITables[]
}

const initialState: IInitialState = {
    tables: [
        {number: 3, numberOccupied: 3, isCloseRegistration: "",  numberSeats: 8, isFull: false},
        {number: 34, numberOccupied: 2, isCloseRegistration: "", numberSeats: 8, isFull: false},
    ]
};

const registrationSlice = createSlice({
    name: 'registration',
    initialState,
    reducers: {
        setTables: (state, action: PayloadAction<ITables[]>) => {
            state.tables = action.payload;
        },
    },
});


export const {setTables} = registrationSlice.actions;
export default registrationSlice.reducer;
