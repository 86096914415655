import React, {ChangeEvent, useEffect, useState} from 'react';
import {useNavigate, useParams} from "react-router-dom";
import {ActionIcon, Group, Input, InputWrapper, Text} from "@mantine/core";
import cn from "classnames";
import {Search} from "tabler-icons-react";
import {ChatProps} from "./_Chat.props";
import style from './_Chat.module.css';
import ChatSkeletonPassenger from "./ChatSkeleton/ChatSkeletonPassenger";
import {ReactComponent as RefreshIcon} from "../../assets/svg/refreshIcon.svg";
import {ListStub} from "../../common";
import {
    useGetChatPassengersQuery
} from "../../redux/apiSlice/_chatSlice";
import { useGetUnreadMessageCountQuery } from '../../redux/apiSlice/_requestSlice';
import {IChatPassenger} from "../../types/IChat";


export const Chat = ({...props}: ChatProps): JSX.Element => {
    const CRUISE_ID = localStorage.getItem("cruise");
    const {
        refetch: messageRefetch,
      } = useGetUnreadMessageCountQuery(CRUISE_ID ? CRUISE_ID : "");

    const navigate = useNavigate();
    const {passengerId} = useParams();

    const [value, setValue] = useState("");
    const [directMessageUrl, setDirectMessageUrl] = useState("");

    const {data: passenger = [], isLoading: isPassengerLoading, refetch} = useGetChatPassengersQuery(CRUISE_ID);
    
    const handleRefreshCounters = () => {
        messageRefetch();
        refetch();
    };
    
    return (
        <div {...props}>
            <header className={style.header}>
                <Group>
                    <Text weight={500} size="xl" className={style.headerTitle}>
                        Чат
                    </Text>
                    <ActionIcon size={25} onClick={handleRefreshCounters}>
                        <RefreshIcon/>
                    </ActionIcon>
                </Group>
            </header>
            <Group noWrap spacing={16}>
                <Group direction="column" noWrap spacing={24} className={style.sidebarChat}>
                    <InputWrapper styles={{
                        root: {width: "100%"}
                    }}>
                        <Input
                            style={{margin: 16}}
                            icon={<Search/>}
                            placeholder="Поиск"
                            value={value}
                            onChange={(e: ChangeEvent<HTMLInputElement>) => setValue(e.currentTarget.value)}
                        />
                    </InputWrapper>
                    <Group className={style.sidebarChatWrapMessage}>
                        {isPassengerLoading
                            ? <>
                                <ChatSkeletonPassenger/>
                                <ChatSkeletonPassenger/>
                                <ChatSkeletonPassenger/>
                                <ChatSkeletonPassenger/>
                                <ChatSkeletonPassenger/>
                                <ChatSkeletonPassenger/>
                                <ChatSkeletonPassenger/>
                                <ChatSkeletonPassenger/>
                                <ChatSkeletonPassenger/>
                            </>
                            : passenger.filter((p: IChatPassenger) =>
                                p.passengerFullName.toLowerCase().includes(value.toLowerCase().trim()) ||
                                p.cabin.toLowerCase().includes(value.toLowerCase().trim())
                            ).map((passenger: IChatPassenger, index: number) => (
                                <Group direction="column"
                                       spacing={4}
                                       className={cn(style.sidebarChatWrapItem, {
                                           [style.active]: passengerId == passenger.paseengerId,
                                        })}
                                       key={index}
                                       onClick={() => {
                                           setDirectMessageUrl(passenger.directMessageUrl);
                                           navigate(`${passenger.paseengerId}`);
                                           setTimeout(handleRefreshCounters, 5000);
                                       }}
                                >
                                    <Group direction="row" spacing={0} className={style.sidebarChatTopRow}>
                                        <Text className={style.sidebarChatCamarote}>каюта №{passenger.cabin}</Text>
                                        <Text className={style.sidebarChatTiempo}>{passenger.updateAt}</Text>
                                    </Group>
                                    <Text
                                        className={cn(style.sidebarChatUserName, {
                                            [style.active]: passengerId == passenger.paseengerId,
                                        })}
                                    >
                                        {passenger.passengerFullName}
                                    </Text>
                                    <Group direction="row" className={style.sidebarChatDownRow}>
                                        <Text className={style.sidebarChatMessage}>
                                            {passenger.lastMessage}
                                        </Text>
                                        {passenger.unreadMessageNumber !== 0 && (
                                            <Text
                                                className={style.sidebarChatNotification}>{passenger.unreadMessageNumber}</Text>
                                        )}
                                    </Group>
                                </Group>
                            ))}
                    </Group>
                </Group>
                <Group className={style.sectionRightWrap}>
                    {!directMessageUrl && (
                        <ListStub isLoading={isPassengerLoading}>
                            Выберите пользователя
                        </ListStub>
                    )}
                    <iframe
                        key={passengerId}
                        id="iFrame"
                        width="100%"
                        height="100%"
                        src={directMessageUrl}
                    ></iframe>
                </Group>
            </Group>
        </div>
    );
};
