import React from 'react';
import {Group, Text, useMantineTheme, MantineTheme, createStyles} from '@mantine/core';
import {Upload} from 'tabler-icons-react';
import {Dropzone, DropzoneStatus, IMAGE_MIME_TYPE} from '@mantine/dropzone';

const useStyles = createStyles((theme) => ({
    root:{
        display: "grid",
        placeItems: "center",
        width: 199,
        height:  175,
        padding: 0
    }
}));

export const dropzoneChildren = (status: DropzoneStatus, theme: MantineTheme) => (
    <Group position="center" spacing="sm" direction="column" style={{pointerEvents: 'none'}}>
        <Text>Загрузить фото</Text>
        <Upload
            size={19}
            strokeWidth={2}
            color={'#00B9E7'}
        />
    </Group>
);



export const UploadDropzone = ({setImg}: any) => {
    const theme = useMantineTheme();
    const { classes } = useStyles();

    return (
        <Dropzone
            onDrop={(files: File[]) => setImg(files)}
            onReject={(files: any) => console.log('rejected files', files)}
            accept={IMAGE_MIME_TYPE}
            className={classes.root}
        >
            {(status: DropzoneStatus) => dropzoneChildren(status, theme)}
        </Dropzone>
    );
};




