import {BaseQueryFn, createApi, FetchArgs, fetchBaseQuery, FetchBaseQueryError} from '@reduxjs/toolkit/query/react';
import {BASE_API_URL} from "../../configs/api.config";
import { RouteDyId} from "../../types/ISchedule";

const baseQuery = fetchBaseQuery({
    baseUrl: BASE_API_URL,
    prepareHeaders: (headers ) => {
        const token = localStorage.getItem("token");

        if (token) {
            headers.set('X-SOZVEZDIE-AUTH-TOKEN', `${token}`);
        }

        return headers;
    },
});

const baseQueryWithReauth: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  const result = await baseQuery(args, api, extraOptions);
  if (result.error && result.error.status === 401) {
    localStorage.clear();
    window.location.href = "/";
    }
  return result;
};

export const routScheduleSlice = createApi({
    reducerPath: "routSchedule",
    baseQuery: baseQueryWithReauth,
    tagTypes: ['Route', 'RouteDyId'],
    endpoints(builder) {
        return {
            getRouteByCruiseId: builder.query({
                query: (cruiseId) =>  `/api/Route/GetRouteByCruiseId?cruiseId=${cruiseId}`,
                providesTags: (result) =>
                    result
                        ?
                        [
                            ...result.routeDays.map(({ date }: {date: string}) => ({ type: 'Route', date } as const)),
                            { type: 'Route', date: 'LIST' },
                        ]
                        :
                        [{ type: 'Route', date: 'LIST' }],
            }),
            getCities: builder.query({
                query: () =>  `/api/Cities/GetCities`,
            }),
            deleteRoute: builder.mutation({
                query: ({id, cruiseExternalId}) => ({
                    url: `/api/Route/Delete/${cruiseExternalId}/${id}`,
                    method: 'DELETE',
                }),
                invalidatesTags: ['Route']
            }),
            addRoute: builder.mutation({
                query: (body) => ({
                    url: `/api/Route/Add`,
                    method: 'POST',
                    body
                }),
                invalidatesTags: ['Route']
            }),
            getRouteById: builder.query<RouteDyId , any>({
                query: (params) => `/api/Route/Get/${params.cruiseId}/${params.id}`,
                providesTags: (result) =>
                    result
                        ?
                        [
                            ...result.city.excursions.map(({ id }: {id: string}) => ({ type: 'RouteDyId', id } as const)),
                            { type: 'RouteDyId', id: 'LIST' },
                        ]
                        :
                        [{ type: 'RouteDyId', id: 'LIST' }],
            }),
            updateRoute: builder.mutation({
                query: (body) => ({
                    url: `/api/Route/Update`,
                    method: 'PUT',
                    body
                }),
                invalidatesTags: ['RouteDyId', 'Route']
            }),
        };
    }
});

export const {
    useGetRouteByCruiseIdQuery,
    useDeleteRouteMutation,
    useAddRouteMutation,
    useUpdateRouteMutation,
    useGetCitiesQuery,
    useGetRouteByIdQuery
} = routScheduleSlice;