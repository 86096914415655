import React from 'react';
import { LoadingOverlay, Table} from "@mantine/core";
import {RequestListProps} from "./RequestList.props";
import style from "./RequestList.module.css";
import RequestBody from "./RequestBody";
import {useAddCommentMutation, useDeclineRequestMutation, useUpdateRequestStatusMutation} from "../../../redux/apiSlice/_requestSlice";


const RequestList = ({requests, refetch, ...props}: RequestListProps) => {

    const [declineRequest, {isLoading: isDeclineLoading}] = useDeclineRequestMutation();
    const [updateRequest, {isLoading: isUpdateLoading}] = useUpdateRequestStatusMutation();
    const [addComment, {isLoading: isAddLoading}] = useAddCommentMutation();

    return (
        <React.Fragment>
            {isDeclineLoading || isUpdateLoading || isAddLoading
                ? <LoadingOverlay visible={isDeclineLoading || isUpdateLoading || isAddLoading}/>
                : <Table
                    className={style.applicationList}
                    {...props}
                    horizontalSpacing="md"
                    verticalSpacing="sm"
                    highlightOnHover
                    fontSize="xs"
                    sx={() => ({
                        backgroundColor: "white",
                    })}
                >
                    <thead>
                    <tr className={style.applicationListTHead}>
                        <th>Номер каюты</th>
                        <th>ФИО</th>
                        <th>Телефон</th>
                        <th>Тип обращения</th>
                        <th>Заявка</th>
                        <th>Скидка</th>
                        <th>Дата и время заявки</th>
                        <th>Статус</th>
                        <th/>
                    </tr>
                    </thead>
                    <tbody className={style.applicationListTbody}>
                    {requests?.map((request) => (
                        <RequestBody request={request}
                                     key={request.guid}
                                     declineRequest={declineRequest}
                                     updateRequest={updateRequest}
                                     addComment={addComment}
                                     refetch={refetch}
                        />
                    ))}
                    </tbody>
                </Table>
            }
        </React.Fragment>
    );
};

export default RequestList;
