import React, { useEffect, useState } from "react";
import { Select, Group } from "@mantine/core";
import { SaunaFilterProps } from "./SaunaFilter.props";
import { FilterDialog } from "../../../common";
import { ButtonCustom } from "../../../components";
import style from "./SaunaFilter.module.css";
import { ReactComponent as ArrowIcon } from "../../../assets/svg/arrow.svg";
import { useGetPassengerFullNamesQuery } from "../../../redux/apiSlice/_registrationSlice";

const SaunaFilter = ({
  setPage,
  setSearch,
  onClose,
  form,
  ...props
}: SaunaFilterProps) => {
  const CRUISE_ID = localStorage.getItem("cruise");

  const [query, setQuery] = useState("");
  const [filterQuery, setFilterQuery] = useState<string | null>(null);

  const { data: passengerFullNames = [] } = useGetPassengerFullNamesQuery({
    cruiseId: Number(CRUISE_ID),
    searchPattern: filterQuery,
  });

  const passengerData = passengerFullNames.map((p: any) => ({
    value: String(p.fullName),
    label: p.fullName,
  }));

  const filteredPassengerData = passengerData.filter(
    (d: { value: string; label: string }, index: number) => {
      for (let i = 0; i < index; i++) {
        if (passengerData[i].value == d.value) return false;
      }
      return true;
    }
  );

  useEffect(() => {
    setFilterQuery(query);
  }, [query]);

  return (
    <FilterDialog {...props} size={600} className={style.applicationFilter}>
      <form
        onKeyDown={(e) => {
          if (e.key == "Enter") {
            e.preventDefault();
          }
        }}
        onSubmit={form.onSubmit((values) => {
          setPage(1);
          setSearch({
            ...values,
          });
        })}
      >
        <Group grow className={style.applicationFilterGroup}>
          <Select
            rightSection={<ArrowIcon />}
            styles={{ rightSection: { pointerEvents: "none" } }}
            searchable
            onSearchChange={setQuery}
            onKeyDown={(e) => {
              if (e.key == "Enter") {
                setFilterQuery(query);
              }
            }}
            onDropdownOpen={() => {
              if (!form.getInputProps("passengerFullName").value && filterQuery) {
                setFilterQuery(null);
              }
            }}
            maxDropdownHeight={400}
            variant="filled"
            label="ФИО"
            placeholder="Выберите ФИО"
            data={filterQuery ? filteredPassengerData : []}
            {...form.getInputProps("passengerFullName")}
          />
        </Group>
        <Group
          className={style.applicationFilterBtnWrap}
          noWrap
          direction="row"
          position="right"
        >
          <ButtonCustom
            width="md"
            appearance="ghost"
            onClick={() => {
              form.reset();
              setFilterQuery(null);
            }}
          >
            Очистить
          </ButtonCustom>
          <ButtonCustom
            width="md"
            appearance="primary"
            type="submit"
            onClick={onClose}
          >
            Применить
          </ButtonCustom>
        </Group>
      </form>
    </FilterDialog>
  );
};

export default SaunaFilter;
