import React, {useState} from 'react';
import style from "./MenuList.module.css";
import EditToMenu from "../EditToMenu/EditToMenu";
import {Button, Group, Menu, Text} from "@mantine/core";
import {Edit, Trash} from "tabler-icons-react";
import {ModalCustom} from "../../../../common";
import {useDeleteDishMutation} from "../../../../redux/apiSlice/_restaurantSlice";

export interface IMenuListBody {
    item: any,
    tabId: string,
}
const MenuListBody = ({item, tabId}: IMenuListBody) => {

    const [openEditToMenuModal, setOpenEditToMenuModal] = useState<boolean>(false);
    const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);

    const [deleteDishes] = useDeleteDishMutation();


    const deleteUpdateDishes = async () => {
        setOpenDeleteModal(false);
        await deleteDishes({dishId: item.id, tabId: tabId}).unwrap();
    };

    return (
        <tr className={style.cruiseRestaurantTbodyRow}>
            <EditToMenu opened={openEditToMenuModal}
                           onClose={() => setOpenEditToMenuModal(false)}
                           dishes={item}
            />
            <ModalCustom opened={openDeleteModal}
                         onClose={()=> setOpenDeleteModal(false)}
                         title="Удалить"

            >
                <Group>
                    <Text color="gray">Название:</Text>
                    <Text >{item.name}</Text>
                </Group>
                <Group position="right" mt="md">
                    <Button color="red" onClick={deleteUpdateDishes}>
                        Удалить
                    </Button>
                </Group>
            </ModalCustom>
            <td>{item.name}</td>
            <td>
                <Menu position={'left'} trigger="hover" className={style.cruiseRestaurantTbodyImg}>
                    <Menu.Item icon={<Edit size={16}/>}
                               onClick={() => setOpenEditToMenuModal(true)}
                    >
                        Редактировать
                    </Menu.Item>
                    <Menu.Item icon={<Trash size={24} color="red"/>}
                    onClick={() => setOpenDeleteModal(true)}
                    >
                        Удалить
                    </Menu.Item>
                </Menu>
            </td>
        </tr>
    );
};

export default MenuListBody;