import React, {useState} from 'react';
import {ExcursionDetailProps} from "./ExcursionDetail.props";
import style from './ExcursionDetail.module.css';
import {Text, Group, Drawer, useMantineTheme, Badge, Select} from '@mantine/core';
import {
    useGetAvailableStatusesQuery,
    useGetRequestByIdQuery
} from "../../../redux/apiSlice/_requestSlice";
import {ButtonCustom} from "../../../components";
import {ListStub} from "../../../common";
import {STATUS} from "../../../constants/CONSTANT";
import {ReactComponent as ArrowIcon} from "../../../assets/svg/arrow.svg";


const ExcursionDetail = ({setOpenDeclineModal, guid, onClose, updateRequest, ...props}: ExcursionDetailProps) => {
    const theme = useMantineTheme();

    const {data: request = [], isLoading: isRequest, isError} = useGetRequestByIdQuery(guid);
    const {data: allStatuses = []} = useGetAvailableStatusesQuery({
        requestTypeId: request?.requestType?.id,
        currentStatusId: request?.requestStatus?.id
    });

    const [selectStatus, setSelectStatus] = useState<string>("");

    const statuses = allStatuses.map((type: { id: string, title: string }) => ({
        value: String(type.id),
        label: type.title
    }));


    const handleUpdateRequest = async () => {
        onClose();
        selectStatus === STATUS.Rejected
            ? setOpenDeclineModal()
            : await updateRequest({
                requestId: request.guid,
                statusId: selectStatus
            });
    };

    return (
        <Drawer
            className={style.drawerModal}
            overlayColor={theme.colorScheme === 'dark' ? theme.colors.dark[9] : theme.colors.gray[2]}
            overlayOpacity={0.55}
            overlayBlur={3}
            position="right"
            styles={{root: {position: "relative"}}}
            onClose={onClose}
            title="Экскурсия"
            size={450}
            {...props}
        >{!request || isRequest || isError
            ? <ListStub isLoading={isRequest} isError={isError}>Нет информации</ListStub>
            : <>
                <Group direction="column">
                    {request?.comment && (
                        <Group className={style.drawerGroup} direction='column' p="md" style={{
                            background: "#EBEBF0",
                            width: "100%",
                            borderRadius: 10
                        }} >
                            <Text className={style.drawerLabel}>Комментарий администратора</Text>
                            <Text weight={700}>{request?.comment}</Text>
                        </Group>
                    )}
                    <Select
                        className={style.drawerInputStatus}
                        rightSection={<ArrowIcon/>}
                        styles={{rightSection: {pointerEvents: 'none'}}}
                        variant="filled"
                        onChange={(e) => setSelectStatus(e!)}
                        value={selectStatus}
                        placeholder={request?.requestStatus?.title}
                        label="Статус"
                        data={statuses}
                    />
                    <Group className={style.drawerGroup} direction='column'>
                        <Text className={style.drawerLabel}>ФИО</Text>
                        <Text weight={700}>{request?.passengerFullName}</Text>
                    </Group>
                    <Group className={style.drawerGroup} direction='column'>
                        <Text className={style.drawerLabel}>Каюта</Text>
                        <Text weight={700}>{request?.cabin}</Text>
                    </Group>
                    <Group className={style.drawerGroup} direction='column'>
                        <Text className={style.drawerLabel}>Телефон</Text>
                        <Text weight={700}>{request?.phone}</Text>
                    </Group>
                    <Group className={style.drawerGroup} direction='column'>
                        <Text className={style.drawerLabel}>Тип обращения</Text>
                        <Text weight={700}>{request?.requestType?.title}</Text>
                    </Group>
                    {request?.excursionRequestInfo && (
                        <Group direction="column">
                            <Group className={style.drawerGroup} direction='column'>
                                <Text className={style.drawerLabel}>Название</Text>
                                <Text weight={700}>{request?.excursionRequestInfo?.name}</Text>
                            </Group>
                            <Group className={style.drawerGroup} direction='column'>
                                <Text className={style.drawerLabel}>Дата</Text>
                                <Text weight={700}>{request?.excursionRequestInfo?.date}</Text>
                            </Group>
                            <Group className={style.drawerGroup} direction='column'>
                                <Text className={style.drawerLabel}>Кол-во взрослых</Text>
                                <Text weight={700}>{request?.excursionRequestInfo?.adultNumber}</Text>
                            </Group>
                            <Group className={style.drawerGroup} direction='column'>
                                <Text className={style.drawerLabel}>Кол-во детей</Text>
                                <Text weight={700}>{request?.excursionRequestInfo?.childNumber}</Text>
                            </Group>
                            <Group className={style.drawerGroup} direction='column'>
                                <Text className={style.drawerLabel}>Итого без скидки</Text>
                                <Text weight={700}>
                                    {request?.excursionRequestInfo.totalPrice} ₽
                                </Text>
                            </Group>
                            <Group className={style.drawerGroup} direction='column'>
                                <Text className={style.drawerLabel}>Размер скидки (%)</Text>
                                <Text weight={700}>
                                    {request?.discountPercentage ? request.discountPercentage + '%' : '-'}
                                </Text>
                            </Group>
                            <Group className={style.drawerGroup} direction='column'>
                                <Text className={style.drawerLabel}>Сумма скидки (руб)</Text>
                                <Text weight={700}>
                                    {request?.excursionRequestInfo?.discountPrice ? request.excursionRequestInfo.discountPrice + '₽' : '-'}
                                </Text>
                            </Group>
                            <Group className={style.drawerGroup} direction='column'>
                                <Text className={style.drawerLabel}>Итого со скидкой</Text>
                                <Badge color="teal" size="xl">
                                    {request?.excursionRequestInfo?.totalPriceDiscount ? request.excursionRequestInfo.totalPriceDiscount + '₽' : '-'}
                                </Badge>
                            </Group>
                        </Group>
                    )}
                </Group>
                <Group className={style.drawerFooter} noWrap style={{marginTop: 100}}>
                    <ButtonCustom appearance="ghost" onClick={onClose}>
                        Отмена
                    </ButtonCustom>
                    <ButtonCustom appearance="secondary" onClick={handleUpdateRequest}>
                        Сохранить
                    </ButtonCustom>
                </Group>
            </>
        }
        </Drawer>
    );
};

export default ExcursionDetail;
