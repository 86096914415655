import React, {useEffect} from 'react';
import {ProgramDayProps} from "./ProgramDay.props";
import style from "./ProgramDay.module.css";
import {ActionIcon, Group, Loader, Text} from "@mantine/core";
import {ReactComponent as DownloadIcon} from "../../../assets/svg/downloadIcon.svg";
import {ReactComponent as CloseIcon} from "../../../assets/svg/closeIcon.svg";
import {useState} from 'react';
import {Calendar} from '@mantine/dates';
import 'dayjs/locale/ru';
import {Link} from "react-router-dom";
import {Dropzone, DropzoneStatus} from "@mantine/dropzone";
import {
    useAddDayScheduleFileMutation,
    useDeleteDayScheduleMutation,
    useGetDayScheduleByDateQuery
} from "../../../redux/apiSlice/_dayScheduleSlice";
import dayjs from 'dayjs';
import {IDayFile} from "../../../types/ICruiseControl";
import {showNotification} from "@mantine/notifications";
import {CircleCheck, FaceIdError} from "tabler-icons-react";


const ProgramDay = ({...props}: ProgramDayProps) => {

    const CRUISE_ID = localStorage.getItem("cruise");

    const [value, setValue] = useState<Date | null>(new Date());
    const {data = [], isLoading} = useGetDayScheduleByDateQuery({
        date: dayjs(value).format("YYYY-MM-DD"),
        cruiseId: CRUISE_ID
    });

    const [deleteDaySchedule, {isLoading: isDeleteDaySchedule, isError}] = useDeleteDayScheduleMutation();
    const [addDaySchedule, {isLoading: isAddDaySchedule}] = useAddDayScheduleFileMutation();


    const dropzoneChildren = (status: DropzoneStatus) => (
        <ActionIcon size={40}>
            <DownloadIcon/>
        </ActionIcon>
    );

    const handleDeleteDaySchedule = async (id: string) => {
        await deleteDaySchedule({dayScheduleFileId: id});
        isError
            ? showNotification({
                title: "Ошибка",
                message: "Произошла ошибка при удалении",
                color: 'red',
                icon: <FaceIdError />,
            })
            :  showNotification({
                title: "",
                message: "Файл удален",
                color:'green',
                icon: <CircleCheck />,
            });
    };

    const handleAddDaySchedule = async (file: any) => {

        const formData = new FormData();
        formData.append('file', file[0]);
        await addDaySchedule({
            body: formData,
            dateTime: dayjs(value).format("YYYY-MM-DD"),
            cruiseId: CRUISE_ID
        });

        isAddDaySchedule
            ? showNotification({
                title: "Ошибка",
                message: "Не удалось загрузить файл",
                color: 'red',
                icon: <FaceIdError />,
            })
            :  showNotification({
                title: "",
                message: "Файл загружен",
                color:'green',
                icon: <CircleCheck />,
            });
    };


    return (
        <div className={style.programDay} {...props}>
            <Group className={style.programDayContent}>
                <Group direction="column" spacing={30} position="left">
                    <Group direction={"row"}>
                        <Dropzone
                            maxSize={30000000000}
                            accept={['.pdf']}
                            loading={isAddDaySchedule}
                            onDrop={(files) => handleAddDaySchedule(files)}
                            styles={{
                                root: {padding: 0, border: "none"}
                            }}
                        >
                            {(status) => dropzoneChildren(status)}
                        </Dropzone>
                        <Text size="md" className={style.programDayTextDownload}>
                            Загрузить PDF файл
                        </Text>
                    </Group>
                    <Group direction="column" spacing="md">
                        {isLoading
                            ? <Loader size="sm" variant="dots"/>
                            : data.map((item: IDayFile) => (
                                <Group direction="row" spacing={32} key={item.id}>
                                    <Text component={"a"}  target="_blank" download href={item.url} className={style.programDayTextLink}>
                                        {item.path}
                                    </Text>
                                    <ActionIcon onClick={() => handleDeleteDaySchedule(item.id)}>
                                        <CloseIcon/>
                                    </ActionIcon>
                                </Group>
                            ))}
                    </Group>
                </Group>
                <Calendar
                    size={"sm"}
                    className={style.programDayCalendar}
                    value={value}
                    onChange={setValue}
                    locale="ru"
                />
            </Group>
        </div>
    );
};

export default ProgramDay;
