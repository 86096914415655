import React, {useEffect, useState} from 'react';
import style from './RestaurantsAndBar.module.css';
import {Select} from "@mantine/core";
import {ReactComponent as AddIcon} from '../../../assets/svg/add.svg';
import {RestaurantsAndBarProps} from "./RestaurantsAndBar.props";
import {ButtonCustom} from "../../../components";
import AddRestaurant from "../RestaurantModals/AddRestaurant/AddRestaurant";
import {ReactComponent as ArrowIcon} from "../../../assets/svg/arrow.svg";
import Restaurant from "./Restaurant/Restaurant";
import {
 useGetRestaurantsByAdminQuery,
} from "../../../redux/apiSlice/_restaurantSlice";
import {ListStub} from "../../../common";

const RestaurantsAndBar = ({...props}: RestaurantsAndBarProps) => {

    const [openedAdd, setOpenedAdd] = useState<boolean>(false);

    const SHIP_ID = Number(localStorage.getItem("ship"));
    const { data: restaurantData = [], isLoading } = useGetRestaurantsByAdminQuery(SHIP_ID);

    const restaurant = restaurantData.map((r: any) => ({value: String(r.id), label: r.name}));

    const [selectValue, setSelectValue] = useState<string | null>("");

    return (
        <div className={style.restaurants} {...props}>
            <AddRestaurant opened={openedAdd}
                           onClose={() => setOpenedAdd(false)}
            />
            <header className={style.header}>
                <Select
                    placeholder="Выбрать ресторан"
                    rightSection={<ArrowIcon fill="#00B9E7"/>}
                    className={style.headerSelectRestAndBars}
                    styles={{rightSection: {pointerEvents: 'none'}}}
                    value={selectValue}
                    onChange={(e) => {
                        setSelectValue(e);
                    }}
                    data={restaurant}/>
                <ButtonCustom appearance="secondary"
                              leftIcon={<AddIcon fill="white"/>}
                              width="lg"
                              onClick={() => setOpenedAdd(true)}
                >
                    Добавить ресторан/бар
                </ButtonCustom>
            </header>
            {!selectValue
                ? <ListStub isLoading={isLoading}>Выберите ресторан или бар</ListStub>
                : <Restaurant 
                              currentRestaurant={selectValue}
                />
            }
        </div>
    );
};

export default RestaurantsAndBar;
