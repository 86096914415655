import React, {useState} from 'react';
import style from "./ExcursionList.module.css";
import {CloseButton, Group, Menu, Textarea} from "@mantine/core";
import {BrandHipchat, Check, Eye} from "tabler-icons-react";
import {ModalCustom} from "../../../common";
import {ExcursionType} from "../../../types/IExcursion";
import ExcursionDetail from "../ExcursionDetail/ExcursionDetail";
import {ButtonCustom} from "../../../components";
import AddComment from "./AddComment";
import {STATUS} from "../../../constants/CONSTANT";


export interface IExcursionBody {
    excursion: ExcursionType
    declineRequest: (model: { requestId: string, reason: string }) => void
    updateRequest: (model: { requestId: string, statusId: string }) => void
    addComment: (model: { requestId: string, comment: string }) => void
}

const ExcursionBody = ({excursion, updateRequest, declineRequest, addComment}: IExcursionBody) => {
    const [opened, setOpened] = useState(false);
    const [openedAddModal, setOpenedAddModal] = useState(false);
    const [openDeclineModal, setOpenDeclineModal] = useState<boolean>(false);
    const [reason, setReason] = useState<string>("");

    const handleDeclineRequest = async () => {
        setOpenDeclineModal(false);
        await declineRequest({
            requestId: excursion.passengerReuqestId,
            reason
        });
    };

    const handleUpdateRequest = async () => {
        await updateRequest({
            requestId: excursion.passengerReuqestId,
            statusId:  STATUS.Processed
        });
    };

    return (
        <tr className={style.applicationListTbodyRow}>
            {opened && (
                <ExcursionDetail guid={excursion.passengerReuqestId}
                               onClose={() => setOpened(false)}
                               opened={opened}
                               setOpenDeclineModal={() => setOpenDeclineModal(true)}
                               updateRequest={updateRequest}
                />
            )}
            <ModalCustom
                className={style.modalCustomTitle}
                centered
                padding={16}
                radius={"md"}
                size={492}
                opened={openDeclineModal}
                onClose={() => setOpenDeclineModal(false)}
                title="Причина отклонения"
            >
                <Textarea
                    className={style.modalCustomTextarea}
                    placeholder="Комментарий"
                    minRows={6}
                    radius={8}
                    label="Комментарий"
                    value={reason}
                    onChange={(e) => setReason(e.currentTarget.value)}
                />
                <Group noWrap direction="row" position="right" style={{marginTop: 20}}>
                    <ButtonCustom width={"sm"} appearance="ghost" onClick={() => setOpenDeclineModal(false)}>
                        Отмена
                    </ButtonCustom>
                    <ButtonCustom width={"md"} appearance="secondary" onClick={handleDeclineRequest}>
                        Отклонить
                    </ButtonCustom>
                </Group>
            </ModalCustom>
            <AddComment onClose={() => setOpenedAddModal(false)}
                        opened={openedAddModal}
                        requestId={excursion.passengerReuqestId}
                        addComment={addComment}
            />
            <td>{excursion.passengerCabinNumber}</td>
            <td>{excursion.passengerFullName}</td>
            <td>{excursion.phone}</td>
            <td>{excursion.city}</td>
            <td>{excursion.excursionName}</td>
            <td>Взр: {excursion.adultNumber}; Дети: {excursion?.childNumber}<br />{excursion?.totalPriceDiscount}руб</td>
            <td>{excursion?.discountPercentage ? excursion?.discountPercentage + '%' : '-'}</td>
            <td>{new Date(excursion.date).toLocaleString("ru", {
                year: 'numeric',
                month: 'numeric',
                day: 'numeric',
                hour: 'numeric',
                minute: 'numeric'
            })}</td>
            <td>{excursion.status}</td>
            <td>
                <Menu position={'left'} trigger="hover" className={style.applicationListTbodyImg}>
                    <Menu.Item icon={<Eye size={16}/>} onClick={() => setOpened(true)}>Посмотреть
                        экскурсию</Menu.Item>
                    <Menu.Item icon={<BrandHipchat size={16}/>}
                               onClick={() => setOpenedAddModal(true)}
                    >
                        Добавить комментарий
                    </Menu.Item>
                    {excursion.status === "Новая" && (
                        <>
                            <Menu.Item icon={<Check size={16}/>}
                                       onClick={handleUpdateRequest}
                            >
                                Подтвердить
                            </Menu.Item>
                            <Menu.Item color='red'
                                       icon={<CloseButton size={16} color='red'/>}
                                       onClick={() => setOpenDeclineModal(true)}
                            >
                                Отклонить
                            </Menu.Item>
                        </>
                    )}
                </Menu>
            </td>
        </tr>
    );
};

export default ExcursionBody;