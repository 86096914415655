import React from 'react';
import {Drawer, useMantineTheme, Image, Text, Group, Menu} from "@mantine/core";
import {Check, CurrencyRubel, Edit, Trash} from "tabler-icons-react";
import {FlightScheduleDetailProps} from "./FlightScheduleDetail.props";
import style from "./FlightScheduleDetail.module.css";
import {Excursion} from "../../../../types/ISchedule";
import parse from "html-react-parser";
import {ListStub} from "../../../../common";

const FlightScheduleDetail = ({handleDelete, data, isLoading, setOpenedAddRoute, onClose, ...props}: FlightScheduleDetailProps) => {
    const theme = useMantineTheme();

    const mainExcursions = data?.city?.excursions.filter((route: Excursion) => route.isIncluded);
    const additionalExcursions = data?.city?.excursions.filter((route: Excursion) => !route.isIncluded);

    return (
        <Drawer
            className={style.drawerModal}
            overlayColor={theme.colorScheme === 'dark' ? theme.colors.dark[9] : theme.colors.gray[2]}
            overlayOpacity={0.55}
            overlayBlur={3}
            position="right"
            onClose={onClose}
            title={`Экскурсия`}
            size={420}
            {...props}
        >{isLoading
            ? <ListStub isLoading={isLoading}>Нет рейсов </ListStub>
            : <>
                <Menu position={'left'} trigger="hover" className={style.drawerModalHeaderMenu}>
                    <Menu.Item icon={<Edit size={24}/>}
                               onClick={() => {
                                   onClose();
                                   setOpenedAddRoute();
                               }}
                    >
                        Редактировать
                    </Menu.Item>
                    <Menu.Item icon={<Trash size={24} color="red"/>}  onClick={() => {
                        onClose();
                        handleDelete(data?.id);
                    }}>
                        Удалить
                    </Menu.Item>
                </Menu>
                <div className={style.drawerModalImageWrapper}>
                    <Image
                        radius="md"
                        src={data?.city?.imageLink}
                        alt=""
                        withPlaceholder
                    />
                </div>
                <Text className={style.drawerModalTextCity}>{data?.city.name}</Text>
                <Group direction="column" className={style.drawerModalMainExcursionsWrap}>
                    <Text className={style.drawerModalMainExcursions}>
                        Основные экскурсии, включенные в стоимость, на выбор:
                    </Text>
                    {mainExcursions?.map((ex: Excursion) => (
                        <Group noWrap spacing={24} direction={"row"}
                               align={"flex-start"}
                               key={ex.id}
                        >
                            <Text className={style.drawerModalMainExcursionsTimeLift}>
                                {ex.timeTable}
                            </Text>
                            <Group direction={"column"} spacing={10} className={style.drawerModalExcursionsText}>
                                <Text className={style.drawerModalExcursionsTitle}>
                                    {parse(ex.name!)}
                                </Text>
                                <Group spacing={8} align={"center"} className={style.drawerModalNotification}>
                                    <Check
                                        size={16}
                                        strokeWidth={2}
                                        color="var(--green)"
                                    />
                                    Включена в стоимость
                                </Group>
                            </Group>
                        </Group>
                    ))}
                </Group>
                <Group direction="column" className={style.drawerModalAddExcursionsWrap}>
                    <Text className={style.drawerModalAddExcursions}>
                        Дополнительные экскурсии:
                    </Text>
                    {additionalExcursions?.map((ex: Excursion) => (
                        <Group noWrap spacing={24} direction={"row"}
                               align={"flex-start"}
                        >
                            <Text className={style.drawerModalAddExcursionsTimeLift}>
                                {ex.timeTable}
                            </Text>
                            <Group direction={"column"} spacing={10} className={style.drawerModalExcursionsText}>
                                <Text className={style.drawerModalExcursionsTitle}>
                                    {parse(ex.name!)}
                                </Text>
                                <Group spacing={8} align={"center"} className={style.drawerModalAddNotification}>
                                    <CurrencyRubel
                                        size={16}
                                        strokeWidth={2}
                                        color="var(--orange)"
                                    />
                                    взрослые - {ex.priceAdult} ₽, дети - {ex.priceChild} ₽
                                </Group>
                            </Group>
                        </Group>
                    ))}
                </Group>
            </>
        }
        </Drawer>
    );
};

export default FlightScheduleDetail;
