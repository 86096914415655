import React, { useState } from "react";
import InputMask from "react-input-mask";
import {
  Checkbox,
  Group,
  Input,
  InputWrapper,
  ModalProps,
  Select,
  Textarea,
} from "@mantine/core";
import { ModalCustom } from "../../../../common";
import { ButtonCustom } from "../../../../components";
import { ReactComponent as ArrowIcon } from "../../../../assets/svg/arrow.svg";
import {
  DAYS_WEEK,
  NOTIFICATION_REPLY,
  REPLY_LIST,
} from "../../../../constants/CONSTANT";
import { formList, useForm } from "@mantine/form";
import { IAddNotifications } from "../../../../types/INotifications";
import {
  useAddNotificationMutation,
  useUpdateNotificationMutation,
} from "../../../../redux/apiSlice/_notificationsSlice";
import { INotificationList } from "../NotificationList";

export interface IAddOrEditNotification extends ModalProps {
  notification?: INotificationList;
}

const AddOrEditNotification = ({
  notification,
  onClose,
  ...props
}: IAddOrEditNotification) => {
  const initialReply = notification
    ? notification.reply
      ? notification.reply.filter((r) => r).length === 7
        ? NOTIFICATION_REPLY.DAILY
        : NOTIFICATION_REPLY.SELECT_DAY
      : NOTIFICATION_REPLY.ONCE
    : "";

  const [reply, setReply] = useState<string | null>(initialReply);

  const [add] = useAddNotificationMutation();
  const [update] = useUpdateNotificationMutation();

  const CRUISE_ID = localStorage.getItem("cruise");

  const editREPLY = REPLY_LIST.map((reply, index) => {
    if (notification?.reply)
      if (notification?.reply[index]) {
        return { day: reply.day, isChecked: notification?.reply[index] };
      }
    return reply;
  });

  const { onSubmit, getInputProps, getListInputProps, reset } = useForm({
    initialValues: {
      time: notification?.time || "",
      date: notification?.date || "",
      title: notification?.title || "",
      description: notification?.description || "",
      buttonType: notification?.buttonType || 0,
      reply: formList(notification ? editREPLY : REPLY_LIST),
    },
  });

  const setNotification = async (model: IAddNotifications) => {
    notification
      ? await update({
          ...model,
          id: notification.id,
          isActive: notification.isActive,
          cruiseId: CRUISE_ID,
          isSingle: reply === NOTIFICATION_REPLY.ONCE,
          reply:
            reply === NOTIFICATION_REPLY.DAILY
              ? new Array(7).fill(true)
              : model.reply.map((r) => r.isChecked),
          date: reply === NOTIFICATION_REPLY.DAILY ? "" : model.date,
        })
      : await add({
          ...model,
          cruiseId: CRUISE_ID,
          isSingle: reply === NOTIFICATION_REPLY.ONCE,
          reply:
            reply === NOTIFICATION_REPLY.DAILY
              ? new Array(7).fill(true)
              : model.reply.map((r) => r.isChecked),
          date: reply === NOTIFICATION_REPLY.DAILY ? "" : model.date,
        });
    onClose();
    reset();
    setReply("");
  };

  return (
    <ModalCustom
      overflow="inside"
      size={492}
      title={notification ? "Изменить уведомление" : "Добавить уведомление"}
      onClose={onClose}
      {...props}
    >
      <form onSubmit={onSubmit((model) => setNotification(model))}>
        <Group spacing="sm">
          <Select
            label="Повтор"
            placeholder="Выберите периодичность"
            variant="filled"
            rightSection={<ArrowIcon />}
            data={[
              NOTIFICATION_REPLY.ONCE,
              NOTIFICATION_REPLY.DAILY,
              NOTIFICATION_REPLY.SELECT_DAY,
            ]}
            value={reply}
            onChange={(reply) => setReply(reply)}
            styles={{
              root: { width: "100%" },
              rightSection: { pointerEvents: "none" },
            }}
          />
          {reply === NOTIFICATION_REPLY.SELECT_DAY &&
            [...new Array(7)].map((day, index: number) => (
              <Checkbox
                label={DAYS_WEEK[index]}
                key={index}
                {...getListInputProps("reply", index, "isChecked", {
                  type: "checkbox",
                })}
              />
            ))}
          <Group>
            {reply === NOTIFICATION_REPLY.ONCE && (
              <InputWrapper label="Дата">
                <Input
                  placeholder="Введите дату"
                  variant="filled"
                  component={InputMask}
                  mask="99.99.9999"
                  {...getInputProps("date")}
                />
              </InputWrapper>
            )}
            <InputWrapper label="Время">
              <Input
                placeholder="Введите время"
                variant="filled"
                component={InputMask}
                mask="99:99"
                {...getInputProps("time")}
              />
            </InputWrapper>
          </Group>
          <Group
            direction="column"
            styles={{
              root: { width: "100%" },
            }}
          >
            <Textarea
              label="Заголовок"
              placeholder="Введите заголовок"
              variant="filled"
              minRows={3}
              maxRows={3}
              styles={{
                root: { width: "100%" },
              }}
              {...getInputProps("title")}
            />
            <Textarea
              label="Описание"
              placeholder="Введите описание"
              variant="filled"
              minRows={5}
              maxRows={5}
              styles={{
                root: { width: "100%" },
              }}
              {...getInputProps("description")}
            />
          </Group>
          <Group
            noWrap
            direction="row"
            position="right"
            style={{ bottom: 0, width: "100%" }}
          >
            <ButtonCustom width={"sm"} appearance="ghost" onClick={onClose}>
              Отмена
            </ButtonCustom>
            <ButtonCustom
              width={"md"}
              appearance="secondary"
              type="submit"
              disabled={
                !reply ||
                !getInputProps("time").value ||
                !getInputProps("title").value ||
                !getInputProps("description").value ||
                (reply === NOTIFICATION_REPLY.ONCE &&
                  !getInputProps("date").value) ||
                (reply === NOTIFICATION_REPLY.SELECT_DAY &&
                  !getInputProps("reply").value.some((v: any) => v.isChecked))
              }
            >
              {notification ? "Изменить" : "Добавить"}
            </ButtonCustom>
          </Group>
        </Group>
      </form>
    </ModalCustom>
  );
};

export default AddOrEditNotification;
