import React, {useState} from 'react';
import {CruiseControlProps} from "./_CruiseControl.props";
import {Tabs} from "@mantine/core";
import ContentHeader from "../../components/ContentHeader/ContentHeader";
import RestaurantsAndBar from "./RestaurantsAndBar/RestaurantsAndBar";
import style from './_CruiseControl.module.css';
import ProgramDay from "./ProgramDay/ProgramDay";
import FlightSchedule from "./FlightSchedule/FlightSchedule";
import Notification from "./Notification/Notification";
import Menu from './Menu/Menu';

const CruiseControlTabs = [
    {label: "Рестораны и бар", tabKey: "1", component: <RestaurantsAndBar/>},
    {label: "Меню и завтраки", tabKey: "2", component: <Menu/>},
    {label: "Программа дня", tabKey: "3", component: <ProgramDay/>},
    {label: "Расписание рейса", tabKey: "4", component: <FlightSchedule/>},
    {label: "Уведомления", tabKey: "5", component: <Notification/>},
];

export const CruiseControl = ({...props}: CruiseControlProps): JSX.Element => {

    const [activeTab, setActiveTab] = useState<number>(0);

    const onChangeTab = (active: number) => {
        setActiveTab(active);
    };

    return (
        <div {...props}>
            <ContentHeader headerTitle="Управление круизом"/>
            <Tabs
                className={style.cruiseControlTabs}
                active={activeTab} onTabChange={onChangeTab}
                  styles={(theme) => ({
                      root: {
                          backgroundColor: theme.white,
                          marginTop: 16,
                      },
                  })}
            >
                {CruiseControlTabs.map((tab, index) => (
                    <Tabs.Tab key={index} label={tab.label} tabKey={tab.tabKey}>
                        {tab.component}
                    </Tabs.Tab>
                ))}
            </Tabs>
        </div>
    );
};
