import React, {useState} from 'react';
import {Group, Table} from "@mantine/core";
import {ReactComponent as AddIcon} from "../../../../assets/svg/add.svg";
import style from "./MenuList.module.css";
import {ButtonCustom} from "../../../../components";
import AddToMenu from "../AddToMenu/AddToMenu";
import MenuListBody from "./MenuListBody";

const MenuList = ({ tabName, dishes, tabId}: { dishes: any, tabId: string, tabName: string}) => {
    const [openAddToMenuModal, setOpenAddToMenuModal] = useState<boolean>(false);

    return (
        <>
            <AddToMenu onClose={()=>setOpenAddToMenuModal(false)}
                          opened={openAddToMenuModal}
                          tabId={tabId}
                          tabName={tabName}
            />
            <Table horizontalSpacing="md" verticalSpacing="sm" className={style.cruiseRestaurant}>
                <thead className={style.cruiseRestaurantTHead}>
                <tr className={style.cruiseRestaurantTHeadRow}>
                    <th>Название</th>
                    <th/>
                </tr>
                </thead>
                <tbody className={style.cruiseRestaurantTbody}>
                {dishes.map((item: any) => (
                    <MenuListBody 
                        item={item}
                        key={item.id}
                        tabId={tabId}
                                    
                    />
                ))}
                </tbody>
            </Table>
            <Group
                className={style.cruiseRestaurantMenuBtnAddWrap}
                position="right">
                <ButtonCustom width="sm"
                              appearance="ghost"
                              leftIcon={<AddIcon/>}
                              onClick={() => setOpenAddToMenuModal(true)}
                >
                    Добавить
                </ButtonCustom>
            </Group>
        </>
    );
};

export default MenuList;
