import React, {useState} from 'react';
import style from "./RegistrationList.module.css";
import {Button, Group, Menu, Text} from "@mantine/core";
import {Archive, ArrowsRandom} from "tabler-icons-react";
import {ModalCustom} from "../../../common";
import {useDeletePassengerMutation} from "../../../redux/apiSlice/_registrationSlice";
import RegistrationDetail from "../RegistrationDetail/RegistrationDetail";

const RegistrationBody = ({item, index, restaurant}: any) => {
    const [openedDeleteModal, setOpenDeleteModal] = useState(false)
    const [deletePassenger, {isLoading: isDeletePassengerLoading}] = useDeletePassengerMutation()
    const [openRegisterDetail, setOpenRegisterDetail] = useState<boolean>(false);

    const handleDelete = async (tableGuid: string | number, reservationPassengerGuid: string | number) => {
        await deletePassenger({tableGuid, reservationPassengerGuid}).unwrap()
    }

    return (
        <tr className={style.registrationListTbodyRow} key={index}>
            <RegistrationDetail onClose={() => setOpenRegisterDetail(false)}
                                opened={openRegisterDetail}
                                detail={item}
                                restaurant={restaurant}
            />
            <ModalCustom opened={openedDeleteModal}
                         title="Удалить гостя"
                         onClose={()=> setOpenDeleteModal(false)}
            >
                    <Group>
                        <Text color="gray">ФИО: </Text>
                        <Text>{item.passengerFullName}</Text>
                    </Group>
                    <Group>
                        <Text color="gray">Ресторан: </Text>
                        <Text>{item.restaurant.name}</Text>
                    </Group>
                    <Group>
                        <Text color="gray">Номер каюты: </Text>
                        <Text>{item.stateroom}</Text>
                    </Group>
                    <Group>
                        <Text color="gray">Номер столика: </Text>
                        <Text>{item.tableNumber}</Text>
                    </Group>
                    <Group>
                        <Text color="gray">Кол-во человек: </Text>
                        <Text>{item.numberPersons}</Text>
                    </Group>

                <Group position="right">
                    <Button color="red" onClick={() => {
                        handleDelete(item.tableId, item.reservationPassengerId)
                        setOpenDeleteModal(false)
                    }}>
                        Удалить
                    </Button>
                </Group>
            </ModalCustom>
            <td>{item.restaurant.name}</td>
            <td>{item.passengerFullName}</td>
            <td>{item.stateroom}</td>
            <td>{item.tableNumber}</td>
            <td>{item.numberPersons}</td>
            <td>
                <Menu position={'left'} trigger="hover" className={style.registrationListTbodyImg}>
                    <Menu.Item icon={<ArrowsRandom size={16}/>} onClick={() => setOpenRegisterDetail(true)}>
                        Изменить стол
                    </Menu.Item>
                    <Menu.Item color='red' icon={<Archive size={12} color={'red'}/>}
                               onClick={() => setOpenDeleteModal(true)}
                    >
                        Удалить гостя
                    </Menu.Item>
                </Menu>
            </td>
        </tr>
    );
};

export default RegistrationBody;