import React, {useState} from 'react';
import {CSVLink} from "react-csv";
import {Pagination, Text, Group, ActionIcon} from '@mantine/core';
import { useForm } from '@mantine/form';
import {RequestProps} from "./_Request.props";
import style from './_Request.module.css';
import Notifications from "./Notifications/Notifications";
import RequestList from "./RequestList/RequestList";
import RequestFilter from "./RequestFilters/RequestFilter";
import {ReactComponent as ArrowIcon} from '../../assets/svg/arrow.svg';
import {ReactComponent as FilterIcon} from '../../assets/svg/filterIcon.svg';
import {ReactComponent as RefreshIcon} from "../../assets/svg/refreshIcon.svg";
import {ListStub} from "../../common";
import {ButtonCustom} from "../../components";
import {useFetchRequestQuery, useGetNewRequestCountQuery} from "../../redux/apiSlice/_requestSlice";
import {RequestFilterType} from '../../types/IRequest';
import {getCsvWithCorrectDate} from "../../helpers/helpers";

const INITIAL_FORM_VALUES = {
    startDate: null,
    endDate: null,
    typeId: null,
    passengerFullName: null,
    statusId: null,
};

export const Request = ({...props}: RequestProps): JSX.Element => {
    const [openedDialog, setOpenedDialog] = useState(false);
    const [activePage, setPage] = useState<number>(1);
    const [pageSize, setSetPageSize] = useState<number>(10);
    const [filter, setFilter] = useState<RequestFilterType>({});

    const CRUISE_ID = localStorage.getItem("cruise");

    const model = {
        page: activePage,
        size: pageSize,
        filter: {
            cruiseId: CRUISE_ID,
            ...filter
        }
    };
    const {data = {models: [], total: 0}, isLoading, refetch} = useFetchRequestQuery(model);
    const {
        data: newRequestCount = { newRequestCount: 0 },
    } = useGetNewRequestCountQuery(CRUISE_ID ? CRUISE_ID : "");

    const form = useForm<RequestFilterType>({
        initialValues: INITIAL_FORM_VALUES,
      });
    const isFormDirty = JSON.stringify(form.values) !== JSON.stringify(INITIAL_FORM_VALUES);
    
    const csvHeader = [
        {label: "Номер каюты", key: "stateroom"},
        {label: "ФИО", key: "passengerFullName"},
        {label: "Телефон", key: "phone"},
        {label: "Тип обращения", key: "requestType.title"},
        {label: "Заявка", key: "request"},
        {label: "Скидка", key: "discountPercentage"},
        {label: "Дата и время заявки", key: "date"},
        {label: "Статус", key: "requestStatus.title"},
    ];

    const crvReport = {
        filename: "Заявки.csv",
        headers: csvHeader,
        data: getCsvWithCorrectDate(data.models)
    };

    return (
        <>
            <div {...props} className={style.application}>
                <header className={style.header}>
                    <Group>
                        <Text weight={500} className={style.headerTitle}>
                            Заявки
                        </Text>
                        <ActionIcon size={25} onClick={() => refetch()}>
                            <RefreshIcon/>
                        </ActionIcon>
                    </Group>
                    <Group noWrap direction="row" position="right">
                        <CSVLink {...crvReport} separator={","}>
                            <ButtonCustom width="sm" rightIcon={<ArrowIcon/>} appearance="ghost">
                                Экспорт
                            </ButtonCustom>
                        </CSVLink>
                        <ButtonCustom width="sm" leftIcon={<FilterIcon className={isFormDirty ? style.buttonIcon : ""} />}
                                      appearance={isFormDirty ? "orange" : "ghost"} onClick={() => setOpenedDialog(prev => !prev)}>
                            Фильтр
                        </ButtonCustom>
                    </Group>
                    <RequestFilter onClose={() => setOpenedDialog(false)}
                                   opened={openedDialog}
                                   setSearch={setFilter}
                                   setPage={setPage}
                                   form={form}
                    />
                </header>
                <div className={style.notificationBtnWrap}>
                    <Notifications notificationsTitle="Новые" notificationsCount={newRequestCount.newRequestCount}/>
                </div>
                {data.models.length === 0 || isLoading
                    ? <ListStub isLoading={isLoading}>
                        <Text component="label">Нет заявок!</Text>
                    </ListStub>
                    : <>
                        <RequestList requests={data.models} refetch={refetch}/>
                        {data.total > pageSize && (
                            <div className={style.paginationWrap}>
                                <Pagination position="right"
                                            page={activePage}
                                            onChange={setPage}
                                            total={Math.ceil(data.total / pageSize)}
                                            color="dark"
                                            size="xs"
                                />
                            </div>
                        )}
                    </>
                }
            </div>
        </>
    );
};
