import React, { useEffect, useState } from "react";
import { Select, Group } from "@mantine/core";
import { ExcursionFilterProps } from "./ExcursionFilter.props";
import { FilterDialog } from "../../../common";
import { ButtonCustom } from "../../../components";
import { DatePicker } from "@mantine/dates";
import style from "./ExcursionFilter.module.css";
import { CalendarEvent } from "tabler-icons-react";
import { ReactComponent as ArrowIcon } from "../../../assets/svg/arrow.svg";
import { useGetAllStatusesQuery } from "../../../redux/apiSlice/_requestSlice";
import dayjs from "dayjs";
import { REQUEST_DATE_TEMPLATE } from "../../../constants/CONSTANT";
import { ICityByID } from "../../../types/ISchedule";
import { useGetCitiesQuery } from "../../../redux/apiSlice/_routScheduleSlice";
import { useGetPassengerFullNamesQuery } from "../../../redux/apiSlice/_registrationSlice";
import { useGetAllExcursionsQuery } from "../../../redux/apiSlice/_excursionSlice";

const ExcursionFilter = ({
  setPage,
  setSearch,
  onClose,
  form,
  ...props
}: ExcursionFilterProps) => {
  const CRUISE_ID = localStorage.getItem("cruise");

  const [query, setQuery] = useState("");
  const [filterQuery, setFilterQuery] = useState<string | null>(null);

  const { data: allStatuses = [] } = useGetAllStatusesQuery(null);
  const { data: passengerFullNames = [] } = useGetPassengerFullNamesQuery({
    cruiseId: CRUISE_ID,
    searchPattern: filterQuery,
  });
  const { data: excursions = [] } = useGetAllExcursionsQuery(
    form.getInputProps("cityId").value
  );

  const excursionData = excursions.map((e: any) => ({
    value: String(e.name),
    label: e.name,
  }));

  const passengerData = passengerFullNames.map((p: any) => ({
    value: String(p.fullName),
    label: p.fullName,
  }));

  const filteredPassengerData = passengerData.filter(
    (d: { value: string; label: string }, index: number) => {
      for (let i = 0; i < index; i++) {
        if (passengerData[i].value == d.value) return false;
      }
      return true;
    }
  );

  const statuses = allStatuses.map((status: { id: string; title: string }) => ({
    value: String(status.id),
    label: status.title,
  }));

  const { data: city = [] } = useGetCitiesQuery(null);

  const cityData = city.map((city: ICityByID) => ({
    value: String(city.cityId),
    label: city.name,
  }));

  useEffect(() => {
    setFilterQuery(query);
  }, [query]);

  return (
    <FilterDialog {...props} size={1300} className={style.applicationFilter}>
      <form
        onKeyDown={(e) => {
          if (e.key == "Enter") {
            e.preventDefault();
          }
        }}
        onSubmit={form.onSubmit((values) => {
          setPage(1);
          setSearch({
            ...values,
            date: values.date
              ? dayjs(values.date).format(REQUEST_DATE_TEMPLATE)
              : null,
            cityId: values.cityId ? values.cityId : null,
          });
        })}
      >
        <Group grow className={style.applicationFilterGroup}>
          <Group
            className={style.applicationFilterDatePickerWrap}
            grow
            noWrap
            align={"flex-end"}
            direction="row"
            spacing={32}
          >
            <DatePicker
              variant="filled"
              rightSection={
                <CalendarEvent
                  size={24}
                  strokeWidth={2}
                  color={"var(--darkBlue)"}
                />
              }
              allowFreeInput
              inputFormat="DD.MM.YYYY"
              styles={{ rightSection: { pointerEvents: "none" } }}
              placeholder={dayjs(new Date()).format("DD.MM.YYYY")}
              locale="ru"
              label="Дата экскурсии"
              {...form.getInputProps("date")}
            />
          </Group>
          <Select
            rightSection={<ArrowIcon />}
            variant="filled"
            styles={{ rightSection: { pointerEvents: "none" } }}
            placeholder="Выберите статус"
            label="Статус"
            data={statuses}
            {...form.getInputProps("status")}
          />
          <Select
            rightSection={<ArrowIcon />}
            styles={{ rightSection: { pointerEvents: "none" } }}
            searchable
            onSearchChange={setQuery}
            onKeyDown={(e) => {
              if (e.key == "Enter") {
                setFilterQuery(query);
              }
            }}
            onDropdownOpen={() => {
              if (!form.getInputProps("passengerFullName").value && filterQuery) {
                setFilterQuery(null);
              }
            }}
            maxDropdownHeight={400}
            variant="filled"
            label="ФИО"
            placeholder="Выберите ФИО"
            data={filterQuery ? filteredPassengerData : []}
            {...form.getInputProps("passengerFullName")}
          />
          <Select
            rightSection={<ArrowIcon />}
            styles={{ rightSection: { pointerEvents: "none" } }}
            searchable
            maxDropdownHeight={400}
            variant="filled"
            label="Город"
            placeholder="Выберите город"
            data={cityData}
            {...form.getInputProps("cityId")}
          />
          <Select
            rightSection={<ArrowIcon />}
            styles={{ rightSection: { pointerEvents: "none" } }}
            searchable
            disabled={!form.getInputProps("cityId").value}
            maxDropdownHeight={400}
            variant="filled"
            label="Наименование экскурсии"
            placeholder="Выберите наименование экскурсии"
            data={excursionData}
            {...form.getInputProps("excursionName")}
          />
        </Group>
        <Group
          className={style.applicationFilterBtnWrap}
          noWrap
          direction="row"
          position="right"
        >
          <ButtonCustom
            width="md"
            appearance="ghost"
            onClick={() => {
              form.reset();
              setFilterQuery(null);
            }}
          >
            Очистить
          </ButtonCustom>
          <ButtonCustom
            width="md"
            appearance="primary"
            type="submit"
            onClick={onClose}
          >
            Применить
          </ButtonCustom>
        </Group>
      </form>
    </FilterDialog>
  );
};

export default ExcursionFilter;
