import {BaseQueryFn, createApi, FetchArgs, fetchBaseQuery, FetchBaseQueryError} from '@reduxjs/toolkit/query/react';
import {BASE_API_URL} from "../../configs/api.config";
import {INotificationList} from "../../pages/CruiseControl/Notification/NotificationList";

const baseQuery = fetchBaseQuery({
    baseUrl: BASE_API_URL,
    prepareHeaders: (headers ) => {
        const token = localStorage.getItem("token");

        if (token) {
            headers.set('X-SOZVEZDIE-AUTH-TOKEN', `${token}`);
        }

        return headers;
    },
});

const baseQueryWithReauth: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  const result = await baseQuery(args, api, extraOptions);
  if (result.error && result.error.status === 401) {
    localStorage.clear();
    window.location.href = "/";
    }
  return result;
};

export const notificationsSlice = createApi({
    reducerPath: "notifications",
    baseQuery: baseQueryWithReauth,
    tagTypes: ['Notifications'],
    endpoints(builder) {
        return {
            getNotifications: builder.query<INotificationList[],  string |null >({
                query: (cruiseId) =>  `/api/Notifications/GetByCruiseId/${cruiseId}`,
                providesTags: (result) =>
                    result
                        ?
                        [
                            ...result.map(({ id }) => ({ type: 'Notifications', id } as const)),
                            { type: 'Notifications', id: 'LIST' },
                        ]
                        :
                        [{ type: 'Notifications', id: 'LIST' }],
            }),
            addNotification: builder.mutation({
                query: (body) => ({
                    url: `/api/Notifications/Add`,
                    method: 'POST',
                    body
                }),
                invalidatesTags: ['Notifications']
            }),

            updateNotification: builder.mutation< string , any>({
                query: (body) => ({
                    url: `/api/Notifications/Update`,
                    method: 'POST',
                    body
                }),
                invalidatesTags: ['Notifications']
            }),

            deleteNotification: builder.mutation< string , string>({
                query: (id) => ({
                    url: `/api/Notifications/Delete/${id}`,
                    method: 'DELETE',
                }),
                invalidatesTags: ['Notifications']
            }),
        };
    }
});

export const {
    useGetNotificationsQuery,
    useAddNotificationMutation,
    useDeleteNotificationMutation,
    useUpdateNotificationMutation,
} = notificationsSlice;