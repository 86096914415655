import { useCallback, useState } from "react";
import {TypedUseSelectorHook, useSelector, useDispatch} from "react-redux";
import {AppDispatch, RootState} from "../redux/store";
import {xor} from 'lodash';

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export function useArray<T>(initial: T[] = []) {
    const [value, setValue] = useState<T[]>(initial);

    return {
        value,
        setValue,
        add: useCallback((element: T) => setValue(elements => [...elements, element]), []),
        toggle: useCallback((element: T) => setValue(elements => xor(elements, [element])), []),
        removeBy: useCallback((condition: (element: T) => boolean) => setValue(arr => arr.filter(v => v && condition(v))), []),
        clear: useCallback(() => setValue(() => []), []),
    };
}
