import React, {useState} from 'react';
import style from "./FlightSchedule.module.css";
import FlightScheduleDetail from "./FlightScheduleDetail/FlightScheduleDetail";
import {Group, Image, Menu, Text} from "@mantine/core";
import {Edit, Trash} from "tabler-icons-react";
import {useDeleteRouteMutation, useGetRouteByIdQuery} from "../../../redux/apiSlice/_routScheduleSlice";
import {City, RouteDay} from "../../../types/ISchedule";
import dayjs from "dayjs";
import AddRouteModal from "../RestaurantModals/FlightScheduleModals/AddRouteModal";

export interface ICityList {
    route: RouteDay,
    city: City,
    indexCities: number
}
const CityList = ({ route, city, indexCities}: ICityList) => {

    const CRUISE_ID = localStorage.getItem("cruise");

    const [openScheduleDetail, setOpenScheduleDetail] = useState<boolean>(false);
    const [isOpenEdit, setIsOpenEdit] = useState<boolean>(false);

    const [deleteRoute] = useDeleteRouteMutation();

    const {data , isLoading} = useGetRouteByIdQuery({id: city.routeGuid, cruiseId: CRUISE_ID});

    const handleDelete = async (id?: string ) => {
        await deleteRoute({id, cruiseExternalId: CRUISE_ID});
    };

    return (
        <>
            {openScheduleDetail && (
                <FlightScheduleDetail opened={openScheduleDetail}
                                      onClose={() => setOpenScheduleDetail(false)}
                                      setOpenedAddRoute={() => setIsOpenEdit(true)}
                                      data={data}
                                      isLoading={isLoading}
                                      handleDelete={handleDelete}
                />
            )}
            {isOpenEdit && (
                <AddRouteModal opened={isOpenEdit}
                               isEdit
                               title="Изменить маршрут"
                               buttonTitle="Сохранить"
                               data={data}
                               excursionsData={data?.city.excursions || []}
                               onClose={() => setIsOpenEdit(false)}
                />
            )}
            <tr className={style.flightScheduleTbodyRow} onClick={() => setOpenScheduleDetail(true)}>
                {indexCities === 0 ? <td>{route?.dayOrder}</td> : <td></td>}
                <td className={style.flightScheduleTbodyState}>
                    <Group direction={"row"} mb="sm" key={city?.cityGuid}>
                        <Image
                            height={32}
                            width={32}
                            radius="sm"
                            src={city?.imageURL}
                            alt=""
                            withPlaceholder
                        />

                        <Text size="xs">
                            {city.name}
                        </Text>
                    </Group>
                </td>

                <td>{dayjs(route?.date).format("DD.MM.YYYY")}</td>
                <td>{city.timetable}</td>
                <td>
                    <Menu position={'left'} className={style.flightScheduleTbodyImg} onClick={e => e.stopPropagation()}>
                        <Menu.Item icon={<Edit size={24}/>}
                                   onClick={(e: any) => {
                                       e.stopPropagation();
                                       setIsOpenEdit(true);
                                   }}
                        >
                            Редактировать
                        </Menu.Item>
                        <Menu.Item icon={<Trash size={24} color="red"/>}
                                   onClick={(e: any) => {
                                       e.stopPropagation();
                                       handleDelete(city.routeGuid);
                                   }}
                        >
                            Удалить
                        </Menu.Item>
                    </Menu>
                </td>

            </tr>
        </>

    );
};

export default CityList;