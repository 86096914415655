import React from 'react';
import {ModalCustom} from "../../../../common";
import {AddTablesModalProps} from "./AddTablesModal.props";
import style from './AddTablesModal.module.css';
import Tables from "../../Schemes/Tables/Tables";

const AddTablesModal = ({restaurantData, onClose, opened, ...props}: AddTablesModalProps) => {

    return (
        <ModalCustom
            className={style.addTablesModal}
            size={586}
            overflow="inside"
            padding={24}
            radius="md"
            opened={opened}
            onClose={onClose}
            title="Добавить стол в ресторане"
            groupClassName={style.addTablesModalFooter}
            {...props}
        >
           <Tables onClose={onClose} restaurantData={restaurantData}/>
        </ModalCustom>);
};


export default AddTablesModal;
