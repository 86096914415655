import React, {useState} from 'react';
import {ExcursionProps} from "./_Excursion.props";
import {Pagination, Text, Group, ActionIcon} from '@mantine/core';
import { useForm } from '@mantine/form';
import {ButtonCustom} from "../../components";
import style from './_Excursion.module.css';
import Notifications from "./Notifications/Notifications";
import {ReactComponent as FilterIcon} from '../../assets/svg/filterIcon.svg';
import {ReactComponent as ArrowIcon} from '../../assets/svg/arrow.svg';
import {ExcursionFilterType} from '../../types/IExcursion';
import {ListStub} from "../../common";
import {useFetchExcursionQuery} from "../../redux/apiSlice/_excursionSlice";
import {CSVLink} from "react-csv";
import {ReactComponent as RefreshIcon} from "../../assets/svg/refreshIcon.svg";
import ExcursionFilter from './ExcursionFilters/ExcursionFilter';
import ExcursionList from './ExcursionList/ExcursionList';
import {getCsvWithCorrectDate} from "../../helpers/helpers";

const INITIAL_FORM_VALUES = {
    date: null,
    status: null,
    passengerFullName: null,
    cityId: null,
    excursionName: null,
}

export const Excursion = ({...props}: ExcursionProps): JSX.Element => {
    const [openedDialog, setOpenedDialog] = useState(false);
    const [activePage, setPage] = useState<number>(1);
    const [pageSize, setSetPageSize] = useState<number>(10);
    const [filter, setFilter] = useState<ExcursionFilterType>({});

    const CRUISE_ID = localStorage.getItem("cruise");

    const model = {
        page: activePage,
        size: pageSize,
        filter: {
            cruiseId: CRUISE_ID,
            ...filter
        }
    };
    const {data = {models: [], total: 0}, isLoading, refetch} = useFetchExcursionQuery(model);

    const form = useForm<ExcursionFilterType>({
        initialValues: INITIAL_FORM_VALUES,
    });
      const isFormDirty = JSON.stringify(form.values) !== JSON.stringify(INITIAL_FORM_VALUES);

    const csvHeader = [
        {label: "Номер каюты", key: "passengerCabinNumber"},
        {label: "ФИО", key: "passengerFullName"},
        {label: "Телефон", key: "phone"},
        {label: "Город экскурсии", key: "city"},
        {label: "Наименование экскурсии", key: "excursionName"},
        {label: "Кол-во взрослых", key: "adultNumber"},
        {label: "Кол-во детей", key: "childNumber"},
        {label: "Стоимость экскурсии", key: "totalPriceDiscount"},
        {label: "Размер скидки", key: "discountPercentage"},
        {label: "Дата экскурсии", key: "date"},
        {label: "Статус", key: "status"},
    ];

    const crvReport = {
        filename: "Экскурсии.csv",
        headers: csvHeader,
        data: getCsvWithCorrectDate(data.models)
    };

    const numberOfNewReq = data?.models?.filter(r => r.status === "Новая").length;
    return (
        <>
            <div {...props} className={style.application}>
                <header className={style.header}>
                    <Group>
                        <Text weight={500} className={style.headerTitle}>
                            Экскурсии
                        </Text>
                        <ActionIcon size={25} onClick={() => refetch()}>
                            <RefreshIcon/>
                        </ActionIcon>
                    </Group>
                    <Group noWrap direction="row" position="right">
                        <CSVLink {...crvReport} separator={","}>
                            <ButtonCustom width="sm" rightIcon={<ArrowIcon/>} appearance="ghost">
                                Экспорт
                            </ButtonCustom>
                        </CSVLink>
                        <ButtonCustom width="sm" leftIcon={<FilterIcon className={isFormDirty ? style.buttonIcon : ""} />}
                                      appearance={isFormDirty ? "orange" : "ghost"} onClick={() => setOpenedDialog(prev => !prev)}>
                            Фильтр
                        </ButtonCustom>
                    </Group>
                    <ExcursionFilter onClose={() => setOpenedDialog(false)}
                                   opened={openedDialog}
                                   setSearch={setFilter}
                                   setPage={setPage}
                                   form={form}
                    />
                </header>
                <div className={style.notificationBtnWrap}>
                    <Notifications notificationsTitle="Новые" notificationsCount={numberOfNewReq}/>
                </div>
                {data.models.length === 0 || isLoading
                    ? <ListStub isLoading={isLoading}>
                        <Text component="label">Нет экскурсий!</Text>
                    </ListStub>
                    : <>
                        <ExcursionList excursions={data.models}/>
                        {data.total > pageSize && (
                            <div className={style.paginationWrap}>
                                <Pagination position="right"
                                            page={activePage}
                                            onChange={setPage}
                                            total={Math.ceil(data.total / pageSize)}
                                            color="dark"
                                            size="xs"
                                />
                            </div>
                        )}
                    </>
                }
            </div>
        </>
    );
};
